<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <a name="important" />
           <div :class="$style.title">Что будет после оплаты:</div>
           <div :class="$style.list">
                <div :class="$style.listItemWrapper" v-for="item in listItems" :key="item.id">
                   <img src="./assets/arrow.png" />
                    <div :class="$style.listItem">
                        {{item.title}}
                    </div>
               </div>
           </div>
        </div>
        <div :class="$style.picture">
            <img v-if="!isMobile" src="./assets/person1.png" />
            <img v-if="isMobile" src="./assets/person5.png" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listItems: [
                {
                    id: '1',
                    title: 'На ваш email придет сообщение со ссылкой на запись эфира'
                },
                {
                    id: '2',
                    title: 'Запись вебинара и практический материал вы получите на тот же email навсегда'
                },
                {
                    id: '4',
                    title: 'Смотреть можно из любой точки мира и с любого устройства, главное, чтобы был интернет'
                },
                {
                    id: '5',
                    title: 'Оплачивать можно с любой карты любого банка любой страны, если у вас не получается — пишите в техническую поддержку'
                }
            ]
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .title {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 84px;
        display: flex;
        align-items: center;
        letter-spacing: 0.278974px;

        color: #FFFFFF;

        .mobile & {
            font-size: 20px;
            line-height: 20px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
    }

    .listItem {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;

        letter-spacing: 0.0976408px;

        color: #FFFFFF;

        .mobile & {
            font-size: 12px;
            line-height: 16px;
        }

    }

    .number {
        width: 100px;

        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 850;
        font-size: 68px;
        line-height: 84px;
        /* or 124% */

        text-align: center;
        letter-spacing: 0.278974px;

        background: linear-gradient(91.12deg, #D75B3F 21.52%, #DBDBDB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .listItemWrapper {
        margin-top: 45px;
        display: flex;
        align-items: center;
        img {
            margin-right: 16px;
            width: 30px;
            height: 30px;
        }

        .mobile & {
            img {
                margin-right: 13px;
                width: 20px;
                height: 20px;
            }
        }
    }

    .picture {
        .mobile & {
            position: absolute;
            bottom: -100px;
            right: -25px;
            img {
                bottom: -120px;
                width: 111px;
                height: 121px;
            }
        }
    }

</style>