import { vFunctions } from "../db";

class ApiService {
    
    constructor(client) {
        this.client = client; 
    }

    async getCategories() {
        return this.client.get('getCategories')
    }
    
	async getCategory(id) {
        return this.client.get('getCategory', {id})
    }

    async getWebinars(catId) {
        return this.client.get('getWebinars', {catId})
    }
    
	async getCourses(catId) {
        return this.client.get('getCourses', {catId})
    }
}

class FirebaseFunctionsClient {

    async get(name, params) {
        try {
            let res = await vFunctions.httpsCallable(name)(params);
            return new ApiServiceResponce(200, res)
        } catch (e) {
			console.log(e)
        }
    }
}

class ApiServiceResponce {
    constructor(status, data) {
        this.status = status
        this.data = data
    }
}

export const api = new ApiService(new FirebaseFunctionsClient());

 