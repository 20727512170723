<template>
<router-link :class="$style.card" :to="'/news/'+news.id">
  <img v-if="!isMobile" :src="news.image" alt="Новость" width="262" height="147">
  <img v-if="isMobile" :src="news.image" alt="Новость" height="147">
  <h3 :class="$style.heading">{{news.title}}</h3>
</router-link>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    news: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    }
  }
}
</script>

<style lang="scss" module>
@import "@/styles/_variables";

.card {
  display: flex;
  flex-flow: column;
  width: min-content;
  // margin: 0;
  img {
    border-radius: 16px;
    overflow: hidden;
  }
}
.heading {
  margin: 0;
  color: $light-black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0557948px;
  text-align: left;
  height: 24px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>