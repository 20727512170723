<template>
    <MainLayout>
        <MainSection id="part1" @buy="$emit('buy')"/>
        <WhatYouKnow id="part2"/>
        <ProgrammSection id="part3"/>
        <AboutAuthor id="part4"/>
        <ReviewSection id="part5"/>
        <WhySections id="part6"/>
        <RegistrationSection id="part7" :price="price ? price : 1990" @update="$emit('update')"/>
    </MainLayout>
</template>
<script>

import MainSection from './MainSection.vue'
import WhatYouKnow from './WhatYouKnow'
import ProgrammSection from './ProgrammSection'
import AboutAuthor from './AboutAuthor'
import ReviewSection from './ReviewSection'
import WhySections from './WhySections'
import RegistrationSection from './RegistrationSection'
import MainLayout from '@/components/layouts/MainLayout.vue'

export default {
    props: {
        price: {
            type:Number
        }
    },
    components: { MainLayout, MainSection, WhatYouKnow, ProgrammSection, AboutAuthor, ReviewSection, WhySections, RegistrationSection }
    
}
</script>

<style lang="scss" module>

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>