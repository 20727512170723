<template>
    <div :class="$style.main" v-if="!isMobile">
        <div :class="$style.logo">
            <router-link :class="$style.heading" to="/"><img :src="require('@/assets/img/logo.svg')"/></router-link>
            <div :class="$style.logoText">
                &nbsp; Mental Market
            </div>
        </div>
        <div :class="$style.menuBlock">
            <a href="#part2">
                <div :class="[$style.menuItem]">
                    Для кого
                <div :class="$style.line"></div>
            </div>
            </a>
            <a href="#part3">
                <div :class="$style.menuItem">
                    Что узнаете
                    <div :class="$style.line"></div>
                </div>
            </a>
            <a href="#part5">
                <div :class="$style.menuItem">
                    Ведущий
                    <div :class="$style.line"></div>
                </div>
            </a>
            <a href="#part7">
                <div :class="$style.menuItem">
                    Отзывы
                    <div :class="$style.line"></div>
                </div>
            </a>
            <a href="#part6">
                <div :class="$style.menuItem">
                    Вопросы
                    <div :class="$style.line"></div>
                </div>
            </a>
        </div>
    </div>
    <div v-else :class="$style.mobile">
        <div :class="$style.header">
            <div :class="$style.logo">
                <router-link :class="$style.heading" to="/"><img :src="require('./assets/logo.svg')"/></router-link>
            </div>
            <div :class="$style.menuButton" @click="openSidePanel">
                <img :src="require('./assets/burger.svg')">
            </div>
        </div>
        <div ref="sidePanel" :class="$style.sidePanel">
            <div :class="$style.topSide">
                <div :class="$style.navbar">
                    <div :class="$style.closeButton" @click="close">
                        <img :src="require('./assets/exit.svg')"/>
                    </div>
                </div>
            
                <a href="#part2" :class="$style.sideMenuItem" @click="close">
                    Для кого
                </a>
                <a href="#part3" :class="$style.sideMenuItem" @click="close">
                    Что узнаете
                </a>
                <a href="#part5" :class="$style.sideMenuItem" @click="close">
                    Ведущий
                </a>
                <a href="#part6" :class="$style.sideMenuItem" @click="close">
                    Вопросы
                </a>
            </div>

            <div :class="$style.footer">
                <div :class="$style.logo">
                <router-link :class="$style.heading" to="/"><img :src="require('@/assets/img/logo.svg')"/></router-link>
                <div :class="$style.logoText">
                    Авторский вебинар <br> доктора Дениса Иванова
                </div>
            </div>
                <div :class="$style.email">
                    serotoninfarma@gmail.com
                </div>
                <div :class="$style.social">
                    <div :class="$style.spacer"></div>
                    <a href="https://zen.yandex.ru/id/6127bfcb4deaaa546fa332d3" target="_blank"><img src="~@/assets/img/zen.svg" alt="Zen"></a>
                    <!-- <div :class="$style.spacer"></div>
                    <a href=""><img src="~@/assets/img/yt.svg" alt="YouTube"></a> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import gsap from 'gsap'
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    },
    methods: {
        openSidePanel() {
            gsap.fromTo(this.$refs['sidePanel'], {right: -244}, {right: 0, duration: 0.3})
        },
        close() {
            gsap.fromTo(this.$refs['sidePanel'], {right: 0}, {right: -244, duration: 0.3})
        }
    }
}
</script>
<style lang="scss" module>

.mobile {
    position: absolute;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: calc(100% - 32px);
    height: 24px;
    padding: 0 16px;
    z-index: 1;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .logo {
        img {
            // width: 180px;
        }
    }
}

.sidePanel {
    background: #FFFFFF;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 24px 36px 32px;
    right: -244px;
    top: 0;
    width: calc(244px - 56px);
    height: calc(100% - 60px);
    z-index: 1000;
}
.navbar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.closeButton {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 40px;
    height: 40px;

    img {
        width: 24px;
        height: 24px;
    }
}
.main {
    position: absolute;
    padding-left: calc((100% - 1099px) / 2);
    padding-right: calc((100% - 1099px) / 2);
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - (100% - 1099px));
    height: 64px;
    font-family: NoirPro;
    z-index: 1;
}
.logo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &:first-child {
        margin-right: 12px;
    }
}
.menuBlock {
    display: flex;
}

.menuItem {
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    line-height: 64px;
    letter-spacing: 0.0976408px;
    color: #000000;
    margin-right: 32px;
    cursor: pointer;

    // .line {
    //     width: 100%;
    //     height: 4px;
    //     border-radius: 4px;
    //     margin-top: 8px;
    // }

    &.menuItem_active {

    .line {
        background: linear-gradient(90deg, #0267E4 0.01%, #5ECEFF 99.35%);
    }
    }
}

    .menuButton {
        top: 16px;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
    }

    .sideMenuItem {
        margin-top: 20px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0976408px;
        color: #333333;
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        .name {
            margin-top: 16px;
            font-size: 18px;
            line-height: 24px;

            letter-spacing: 0.0976408px;

            color: #333333;
        }

        .email {
            margin-top: 16px;
            font-size: 18px;
            line-height: 24px;
            /* identical to box height, or 133% */

            letter-spacing: 0.0976408px;

            color: #1677F0;
        }

        .social {
            display: flex;
            margin-top: 16px;
        }

    }
    .topSide {
        display: flex;
        flex-direction: column;
    }

</style>