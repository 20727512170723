<template>
<div class="cart-wrapper" v-show="cartVisibility" @click.self="actionToggleCartVisibility(false);">
  <transition name="modalSlide">
    <div class="cart-popup" v-show="cartVisibility">
      <a class="cart__close" href="" @click.prevent="actionToggleCartVisibility(false);"></a>
      <CartList v-show="cartVisiblePage === 'cart'" />
      <div v-show="cartVisiblePage === 'delivery'">
        <div class="pd-menu__heading">
          <a v-if="isMobile" class="personal-data-back" href="" @click.prevent="back('delivery')"><img :src="require('@/assets/img/arrow.svg')" /></a>
          <span>Оформление заказа</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionToggleCartVisibility(false);"></a>
        </div>
      <form class="cart__c-delivery c-delivery" >        
        <label class="c-delivery__cd-label cd-label cd-label--firstname cd-label--required">
          <span class="cd-label__caption">Имя</span>
          <input class="cd-label__input" type="text" name="firstname" v-model="deliveryForm.firstname" required>
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--lastname">
          <span class="cd-label__caption">Фамилия</span>
          <input class="cd-label__input" type="text" name="lastname" v-model="deliveryForm.lastname" required>
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--email cd-label--required">
          <span class="cd-label__caption">Электронная почта</span>
          <input class="cd-label__input" type="email" name="email" v-model="deliveryForm.email" required>
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--phone cd-label--required"><span class="cd-label__caption">Номер телефона</span>
          <input class="cd-label__input" type="phone" name="phone" v-model="deliveryForm.phone" required>
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--comment"><span class="cd-label__caption">Комментарий к заказу</span>
          <input class="cd-label__input" type="text" name="comment" v-model="deliveryForm.comment">
        </label>
        <h2 class="c-delivery__delivery-heading">Способ доставки</h2>

        <div class="c-delivery__delivery-content">
          <label class="c-delivery__cd-radio cd-radio cd-radio--delivery cd-radio--required" v-for="(item, key) in delivery" :key="key" :value="item.id">
            <input type="radio" class="" name="delivery" :value="item.id" v-model="deliveryForm.delivery" :checked="key==0">{{item.label}}
          </label>
        </div>

        <label class="c-delivery__cd-label cd-label cd-label--country cd-label--required"><span class="cd-label__caption">Страна</span>
          <select class="c-delivery__cd-select cd-select cd-select--country cd-select--required" v-model="deliveryForm.country" @change="deliveryForm.city = ''">
            <option v-for="(item, key) in countries" :key="key" :value="item.id">{{item.label}}</option>
          </select>
        </label>

        <label class="c-delivery__cd-label cd-label cd-label--city cd-label--required"><span class="cd-label__caption">Город</span>
          <select class="c-delivery__cd-select cd-select cd-select--city cd-select--required" v-model="deliveryForm.city">
            <option v-for="(item, key) in avaliableCities" :key="key" :value="item.id">{{item.label}}</option>
          </select>
        </label>

        <label class="c-delivery__cd-label cd-label cd-label--adress cd-label--required"><span class="cd-label__caption">Адрес</span>
          <input class="cd-label__input" type="text" name="adress" v-model="deliveryForm.adress" required>
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--flat cd-label--required"><span class="cd-label__caption">Кв./Офис</span>
          <input class="cd-label__input" type="text" name="flat" v-model="deliveryForm.flat" required>
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--enter cd-label--required"><span class="cd-label__caption">Подъезд</span>
          <input class="cd-label__input" type="number" name="enter" v-model="deliveryForm.enter" required>
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--code"><span class="cd-label__caption">Домофон</span>
          <input class="cd-label__input" type="text" name="code" v-model="deliveryForm.code">
        </label>
        <label class="c-delivery__cd-label cd-label cd-label--level cd-label--required"><span class="cd-label__caption">Этаж</span>
          <input class="cd-label__input" type="number" name="level" v-model="deliveryForm.level" required>
        </label>
        <ul class="c-delivery__cd-prices cd-prices">
          <li class="cd-prices__item">Доставка: <span>{{ deliveryPrice | price }}</span></li>
          <li class="cd-prices__item">Товары: <span>{{ itemsPrice | price }}</span></li>
          <li class="cd-prices__item">Промокод: <span>-{{ promocodePrice | price }}</span></li>
          <li class="cd-prices__item">Итого: <span class="cd-prices__item--summary">{{summaryPrice | price}}</span></li>
        </ul>
        <button class="c-delivery__cd-submit" type="submit" @click.prevent="buyCartItems">Оплатить заказ</button>
      </form>
      </div>
      <Order source="cart" v-show="cartVisiblePage === 'order'" />
      <div  v-show="cartVisiblePage === 'course'">
        <div class="pd-menu__heading">
          <a v-if="isMobile" style="width: 24px"></a>
          <span>Покупка курса</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionToggleCartVisibility(false);"></a>
        </div>
        <div class="cart__c-course c-course">
          <p class="c-course__description">Доступ на полный курс "{{buyingDigitalItem.name}}" на неограниченное время за {{totalPrice}} рублей</p>
          <form class="c-course__cc-promocode cc-promocode">
            <p class="cc-promocode__caption">Промокод</p>
            <input class="cc-promocode__input" type="text" v-model="promocodeValue">
            <button class="cc-promocode__button" type="submit" @click.prevent="actionUsePromocode(promocodeValue);">Применить</button>

            <transition name="fade">
              <p class="cc-promocode__status cc-promocode__status--failure" v-show="promocodeUsed && !promocodeSuccess">Данный промокод не существует</p>
            </transition>

            <transition name="fade">
              <p class="cc-promocode__status cc-promocode__status--success" v-show="promocodeSuccess">Промокод активирован!</p>
            </transition>

          </form>
          <button class="c-course__button" type="submit" @click.prevent="buyCourse('course')">Оформить покупку</button>
          <p class="c-course__caption">Оформляя покупку, вы соглашаетесь с условиями <a href="">Пользовательского соглашения</a> и <a href="">Политики конфидециальности</a></p>
      </div>
      </div>
      <div v-show="cartVisiblePage === 'webinar'">
        <div class="pd-menu__heading">
          <a v-if="isMobile" style="width: 24px"></a>
          <span>Покупка вебинара</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionToggleCartVisibility(false);"></a>
        </div>

        <div class="cart__c-webinar c-webinar">
          
          <p class="c-webinar__description">Доступ на вебинар "{{buyingDigitalItem.name}}" на неограниченное время за {{totalPrice}} рублей</p>
          <form class="c-webinar__cc-promocode cc-promocode">
            <p class="cc-promocode__caption">Промокод</p>
            <input class="cc-promocode__input" type="text" v-model="promocodeValue">
            <button class="cc-promocode__button" type="submit" @click.prevent="actionUsePromocode(promocodeValue);">Применить</button>

            <transition name="fade">
              <p class="cc-promocode__status cc-promocode__status--failure" v-show="promocodeUsed && !promocodeSuccess">Данный промокод не существует</p>
            </transition>

            <transition name="fade">
              <p class="cc-promocode__status cc-promocode__status--success" v-show="promocodeSuccess">Промокод активирован!</p>
            </transition>

          </form>
          <button class="c-webinar__button" type="submit" @click.prevent="buyCourse('webinar')">Оформить покупку</button>
          <p class="c-webinar__caption">Оформляя покупку, вы соглашаетесь с условиями <a href="">Пользовательского соглашения</a> и <a href="">Политики конфидециальности</a></p>
        </div>
      </div>
      <div v-if="payUrl && cartVisiblePage == 'payment'">
        <div class="pd-menu__heading">
          <a v-if="isMobile" class="personal-data-back" href="" @click.prevent="back('payment')"><img :src="require('@/assets/img/arrow.svg')" /></a>
          <span>Оплата</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionToggleCartVisibility(false);"></a>
        </div>
        <iframe ref="iFrame" :src="payUrl" :class="$style.iframe" frameborder="0" @load="onIframeChange"/>
      </div>
      <div>
        <div :class="$style.loader" v-if="cartVisiblePage == 'loading'">
          <Loading />
        </div>
      </div>
      <Help v-show="cartVisiblePage === 'help'" />
    </div>
  </transition>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import { vFunctions, vfirestore } from '@/db.js'
import Help from '@/components/ModalComponent/Help.vue'
import Order from '@/components/ModalComponent/Order.vue'
import CartList from '@/components/ModalComponent/CartList.vue'
import Loading from '@/components/Loading.vue'
import pay from '@/commons/pay'

const DISCOUNTFIX = 'fix'
const DISCOUNTPERCENT = 'percent'

export default {
  name: 'Cart',
  components: {
    Help,
    Order,
    CartList,
    Loading
  },
  data() {
    return {
      payUrl: '',
      deliveryForm: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        comment: "",
        delivery: 1,
        country: "",
        city: "",
        adress: "",
        flat: "",
        enter: "",
        code: "",
        level: ""
      },
      prevPage: '',
      promocodeValue: '',
      promocodeUsed: false,
      promocodeSuccess: false,
      orderNumber: '',
      discountType: '',
      discountValue: '',
    }
  },
  methods: {
    ...mapActions({
      actionToggleCartVisibility: 'localData/toggleCartVisibility',
      actionToggleCartVisiblePage: 'localData/toggleCartVisiblePage',
      actionSetOrderData: 'serverData/setOrderData',
      actionAddUserBuying: 'serverData/addUserBuying',
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",

    }),
    submitDigitalOrder(type) {
      this.actionAddUserBuying({
        type: type,
        label: this.buyingDigitalItem.name,
        date: new Date().toDateString(),
        link: ""
      })
      this.actionToggleCartVisiblePage('cart')
      this.actionToggleCartVisibility(false)
    },
    async actionUsePromocode(code) {
      this.promocodeUsed=false;
      let res = await vFunctions.httpsCallable('getPromo')({code, productId: 'ivanovCovid'})
      if (res.data.error) {
        this.promocodeUsed=true;
        this.promocodeSuccess = false;
      } else {
          this.discountType = res.data.promo.type
          this.discountValue = res.data.promo.value
          this.promocodeSuccess = true
      }
    },
    submitOrder() {
      // let formFilled = true;
      // Object.keys(this.deliveryForm).forEach(el => {
      //   if (el === "firstname" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "email" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "phone" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "country" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "city" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "adress" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "flat" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "enter" && !this.deliveryForm[el]) return formFilled = false;
      //   if (el === "level" && !this.deliveryForm[el]) return formFilled = false;
      // })
      // if (!formFilled) {
      //   return alert('Заполните все поля!')
      // }

      this.deliveryForm.items = this.cart;
      let orderData = this.deliveryForm;
      orderData.deliveryPrice = this.deliveryPrice;
      orderData.itemsPrice = this.itemsPrice;
      orderData.promocodePrice = this.promocodePrice;
      orderData.summaryPrice = this.summaryPrice;

      this.actionToggleCartVisiblePage('order')
      //axios
      //get number back
      orderData.number = 534123;
      orderData.deliveryDate = '30.05.2021';
      orderData.status = 'processed';
      this.actionSetOrderData(orderData);
      this.actionAddUserBuying({
        type: 'order',
        number: orderData.number,
        date: new Date()
      })
    },
    back(from) {
      if (from == 'payment') {
        if (this.prevPage) {
          this.actionToggleCartVisiblePage(this.prevPage)
        } else {
          this.actionToggleCartVisiblePage('delivery')
        }
      } else if (from == 'delivery') {
        this.actionToggleCartVisiblePage('cart')
      }
    },
    async buyCourse(content) {
        this.actionToggleCartVisiblePage('loading')
        this.prevPage = content;
        try {
          await pay(this.totalPrice, this.user.uid, this.buyingDigitalItem.productId, this.user.email, content, this.ref, this.user.displayName)
          setTimeout(() => {
            this.submitDigitalOrder('course')
            this.actionToggleCartVisiblePage('done')
          }, 5000)
        } catch(e) {
          console.log(e)

        }
    },
    
    async buyCartItems() {

        this.actionToggleCartVisiblePage('loading')
      
        let payload = {
          "amount": "100",
          ...this.deliveryForm,
          "status": 0,
          "cart": JSON.stringify(this.cart)
        }


      let res = await vFunctions.httpsCallable('createOrder')(payload)

      this.payUrl = res.data.formUrl;
      this.orderNumber = res.data.orderNumber;
      
      this.actionToggleCartVisiblePage('payment')
    },
    async onIframeChange() {
      let order = ( await vfirestore.collection('v').doc('1').collection('orders').doc( this.orderNumber.toString() ).get() ).data()
      if (order.status == 1) {
        this.payUrl = '';
        this.orderNumber = '';
        this.submitDigitalOrder('course')
        this.actionToggleCartVisiblePage('done')
      }
    }
  },
  computed: {
    ...mapGetters({
      cart: 'cart/list',
      cartVisibility: 'localData/cartVisibility',
      cartVisiblePage: 'localData/cartVisiblePage',
      buyingDigitalItem: 'localData/buyingDigitalItem',
      promocode: 'serverData/promocode',
      delivery: 'serverData/delivery',
      countries: 'serverData/countries',
      cities: 'serverData/cities',
      items: 'serverData/items',
      orderData: 'serverData/orderData',
      isAuth: 'user/isAuth',
      user: 'user/user',
    }),
    promocodePrice: function() {
      return this.promocode && this.promocode.price ? this.promocode.price : 0
    },
    deliveryPrice: function() {
      return this.deliveryForm.delivery ? this.delivery.find(el => el.id === this.deliveryForm.delivery).price : 0
    },
    summaryPrice: function() {
      return this.itemsPrice + this.deliveryPrice
    },
    avaliableCities: function() {
      return this.deliveryForm.country ? this.cities.filter(el => el.countryId === this.deliveryForm.country) : this.cities
    },
    backgroundStyle: function() {
      return {
        background: this.category && this.category.image ? `url(${require('@/assets/img/' + this.category.image)})` : '#999'
      }
    },
    itemsPrice: function() {
        return this.cart.reduce((prev, current) => (prev + (current.count * current.price)), 0)
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    totalPrice() {
      if (!this.promocodeSuccess) return this.buyingDigitalItem.price;
      return this.discountType == DISCOUNTFIX ? this.discountValue : this.buyingDigitalItem.price / 100 * this.discountValue; 
    },
    ref() {
        return this.$route.query ? this.$route.query.ref : '';
    }
  },
  watch: {
    
  }
}
</script>

<style lang="scss" module>
  @import "@/styles/_variables";

  .iframe {
    width: 100%;
    height: 800px;
  }

  .loader {
    display: flex;
    justify-content: center;
    width: 100%;
  }
</style>
