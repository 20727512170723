<template>
<div v-if="isMobile">
  <div :class="$style.mobile_header">
    <div :class="$style.mobile_button" @click="actionTogglePersonalDataVisibility(true)">
      <img :src="require('@/assets/img/burger.svg')" alt="Корзина" />
    </div>
    <div>
      <router-link :class="$style.heading" to="/"><img :src="require('@/assets/img/logo-text.svg')"/></router-link>
    </div>

    <div :class="$style.mobile_button" @click.prevent="actionToggleCartVisiblePage('cart'); actionToggleCartVisibility(true);">
      <a href="">
        <img :src="require('@/assets/img/cart.svg')" alt="Корзина" />
        <span :class="$style['h-menu__item--cart-items']" v-show="cart.length">
          {{ cart.length }}
        </span>
      </a>
    </div>

  <Cart />
  <PersonalData />
  </div>

</div>
<div v-else>
  <div :class="$style.header">
    <router-link :class="$style.heading" to="/"><img :src="require('@/assets/img/logo-text.svg')"/></router-link>
    <ul :class="[$style['h-menu'], $style['h-menu']]">
      <li :class="$style['h-menu__item']" @click.prevent="actionToggleCartVisiblePage('cart'); actionToggleCartVisibility(true);">
        <a href="">
          <img :src="require('@/assets/img/cart.svg')" alt="Корзина" />
          <span :class="$style['h-menu__item--cart-items']" v-show="cart.length">
            {{ cart.length }}
          </span>
        </a>
      </li>
      <li :class="$style['h-menu__item']" @click.prevent="actionToggleLanguageSelectVisibility()">
        <a href="">
          <img :src="imageSrc" alt="Сменить язык" />
        </a>
      </li>
      <li :class="$style['h-menu__item']" @click.prevent="actionTogglePersonalDataVisibility(true)">
        <a href="">
          <img  :src="require('@/assets/img/personal.svg')" alt="Личный кабинет" />
        </a>
      </li>
    </ul>
    <LanguageSelect :class="$style['h-menu__lang-select']" source="header"/>
    <Cart />
    <PersonalData />
  </div>
</div>
</template>

<script>
import Cart from '@/components/Cart.vue'
import PersonalData from '@/components/PersonalData.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import {
  mapState,
  mapActions,
  mapGetters
} from "vuex";
export default {
  name: "Header",
  components: {
    Cart,
    PersonalData,
    LanguageSelect,
  },
  methods: {
    ...mapActions({
      actionSetCurrentLanguageId: "localData/setCurrentLanguageId",
      actionToggleLanguageSelectVisibility: "localData/toggleLanguageSelectVisibility",
      actionToggleCartVisibility: "localData/toggleCartVisibility",
      actionToggleCartVisiblePage: "localData/toggleCartVisiblePage",
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",
      actionTogglePersonalDataVisiblePage: "localData/togglePersonalDataVisiblePage"
    }),
    toggleLanguageSelectVisibility(value) {
      return this.showLanguageSelect = ((value && typeof value === Boolean) ? value : !this.showLanguageSelect);
    },
    toggleCartSelectVisibility(value) {
      return this.showLanguageSelect = value ? value : !this.showLanguageSelect;
    }
  },
  computed: {
    ...mapState({
      localData: function() {
        return this.$store.state.localData
      },
      serverData: function() {
        return this.$store.state.serverData
      },
      isMobile() {
        // return true;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
      }
    }),
    ...mapGetters({
      languages: "localData/languages",
      currentLanguageId: "localData/currentLanguageId",
      cart: "cart/list",
    }),
    imageSrc: function(){
      return this.languages.find(el => el.id === this.currentLanguageId).image
    },
    amountInCart() {
      return this.cart.reduce((prev, current) => (prev + (current.count * current.price)), 0)
    }
  }
}
</script>
<style lang="scss" module>
@import "@/styles/_variables";

.header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 0 170px;
  margin: 0;
}

.heading {
  display: flex;
  margin: 0;
  line-height: 16.5px;
  align-items: center;
  color: $light-black;

}

.h-menu {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 110px;
  margin: 0;
  padding: 0;
}

.h-menu__item--cart-items {
  position: absolute;
  top: -8px;
  left: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  padding: 1px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: $white;
  background: $red;
  border-radius: 50px;
}

.h-menu__lang-select {
  position: absolute;
  top: 80px;
  right: 200px;
  display: flex;
  width: 216px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  background: $white;
}

.mobile_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: calc(100% - 30px);
  overflow: hidden;
  padding: 15px 16px;
}
.mobile_button {
  width: 24px;
  height: 24px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

</style>