export default {
    updateCart(state, items) {
        state.cart = [...items]
    },
    addToCart(state, item) {
        state.cart = [...state.cart, ...[item]]
    },
    plusItemToCart(state, id) {
        let item = state.cart.find( i => i.id === id)
        if ( item ) item.count++
    },
    minusItemFromCart(state, id) {
        let item = state.cart.find( i => i.id === id)
        if ( item && item.count > 1 ) item.count--
        else state.cart = [...state.cart.filter(i => i.id !== id)]
    },
}