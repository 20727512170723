<template>
<div class="player" v-show="isPlaying">
  <div class="player__line" @click="changePlayerTime"><span class="player__marker player__marker--length" :style="lengthMarkerStyle"></span></div>
  <div class="player__controls">
    <a href="" @click.prevent><img :src="require('@/assets/img/back.svg')" alt="Назад"></a>
    <a href="" v-show="!isPaused" @click.prevent="isPaused=true"><img :src="require('@/assets/img/pause.svg')" alt="Пауза"></a>
    <a href="" v-show="isPaused" @click.prevent="isPaused=false"><img :src="require('@/assets/img/play.svg')" alt="Пуск"></a>
    <a href="" @click.prevent><img :src="require('@/assets/img/next.svg')" alt="Вперед"></a>
  </div>
  <h3 class="player__name">{{item.name}}</h3>
  <p class="player__length">{{item.length}}</p>
  <div class="player__volume"><span class="player__marker player__marker--volume" :style="volumeMarkerStyle"></span></div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
export default {
  name: 'Player',
  data() {
    return {
      playerWidth: 0,
      isPaused: false,
      item: {
        name: ''
      }
    }
  },
  methods: {
    ...mapActions({
      actionChangePlayerVolume: "localData/changePlayerVolume",
      actionChangePlayerTime: "localData/changePlayerTime"
    }),
    changePlayerTime: function(evt) {
      this.actionChangePlayerTime((evt.pageX - 6) / (evt.view.innerWidth - (evt.view.scrollbars.visible * 15)) * 100);
    }
  },
  computed: {
    ...mapGetters({
      player: "localData/player",
      isPlaying: "localData/isPlaying",
      courseContent: "serverData/courseContent"
    }),
    lengthMarkerStyle: function() {
      return {
        left: (this.player.length || 0) + '%',
      }
    },
    volumeMarkerStyle: function() {
      return {
        left: (this.player.volume || 0) + '%',
      }
    },
  },
  watch: {
    isPlaying: function() {
      this.item = this.courseContent[this.isPlaying.categoryId][this.isPlaying.chapterId].content
        .find(el => el.id === this.isPlaying.paragraphId);
    }
  }
}
</script>
