<template>
  <div v-if="isMobile">
    <div :class="$style.m_footer">
      <router-link :class="$style.heading" to="/"
        ><img :src="require('@/assets/img/logo-text.svg')"
      /></router-link>
      <div style="height: 32px"></div>
      <a
        :class="[$style['f-links__link'], $style['f-links__link--about']]"
        href=""
        >О проекте</a
      >
      <a
        :class="[$style['f-links__link'], $style['f-links__link--info']]"
        href=""
        >Юридическая информация</a
      >
      <div :class="[$style['footer__f-links'], $style['m-f-links']]">
        <ul :class="[$style['f-links__f-social'], $style['f-social']]">
          <li
            :class="[$style['f-social__item'], $style['f-social__item--zen']]"
          >
            <a href=""><img src="~@/assets/img/zen.svg" alt="Zen" /></a>
          </li>
          <li :class="[$style['f-social__item'], $style['f-social__item--yt']]">
            <a href=""><img src="~@/assets/img/yt.svg" alt="YouTube" /></a>
          </li>
        </ul>
      </div>
      <ul :class="[$style['footer__f-nav'], $style['m-f-nav']]">
        <li
          :class="$style['f-nav__item']"
          v-for="(item, key) in categories"
          :key="key"
        >
          <router-link :to="'/categories/' + item.id">{{
            item.name
          }}</router-link>
        </li>
      </ul>
      <div :class="[$style['footer__f-text'], $style['m-f-text']]">
        <p :class="$style['f-text__caption']">© 2020 ООО Mental Market</p>
      </div>
    </div>
  </div>
  <div v-else>
    <div :class="$style.footer">
      <div :class="[$style['footer__f-text'], $style['f-text']]">
        <h2 :class="$style['f-text__heading']">Mental Market</h2>
        <p :class="$style['f-text__caption']">© 2022 ООО “Спейсвортекс”</p>
        <p :class="$style['f-text__caption']">
          <img :class="$style['payment-logo']" src="/Visa_Logo_2014.svg" /><img
            :class="$style['payment-logo']"
            src="/Mastercard_Logo_2019.svg"
          /><img :class="$style['payment-logo']" src="/Mir-logo.svg" />
        </p>
      </div>
      <ul :class="[$style['footer__f-nav'], $style['f-nav']]">
        <li
          :class="$style['f-nav__item']"
          v-for="(item, key) in categories"
          :key="key"
        >
          <router-link :to="'/categories/' + item.id">{{
            item.name
          }}</router-link>
        </li>
      </ul>
      <div :class="[$style['footer__f-links'], $style['f-links']]">
        <a
          :class="[$style['f-links__link'], $style['f-links__link--about']]"
          href=""
          >О проекте</a
        >
        <a
          :class="[$style['f-links__link'], $style['f-links__link--info']]"
          target="_blank"
          href="/Mental Market Юридическая информация.pdf"
          >Юридическая информация</a
        >
        <a
          :class="[$style['f-links__link'], $style['f-links__link--info']]"
          target="_blank"
          href="/Mental Market Политика Конфиденциальности.pdf"
          >Политика конфиденциальности</a
        >
        <a
          :class="[$style['f-links__link'], $style['f-links__link--info']]"
          target="_blank"
          href="/Mental Market Договор Оферта.pdf"
          >Оферта</a
        >
        <a
          :class="[$style['f-links__link'], $style['f-links__link--info']]"
          target="_blank"
          href="/Mental Market Политика в отношении обработки персональных данных.pdf"
          >Политика в отношении обработки персональных данных</a
        >
        <a
          :class="[$style['f-links__link'], $style['f-links__link--info']]"
          target="_blank"
          href="/doc.pdf"
          >Оплатита заказа</a
        >
        <a
          :class="[$style['f-links__link'], $style['f-links__link--info']]"
          target="_blank"
          href="/LLC_signed.pdf"
          >Сертификат</a
        >

        <ul :class="[$style['f-links__f-social'], $style['f-social']]">
          <li
            :class="[$style['f-social__item'], $style['f-social__item--zen']]"
          >
            <a href=""><img src="~@/assets/img/zen.svg" alt="Zen" /></a>
          </li>
          <li :class="[$style['f-social__item'], $style['f-social__item--yt']]">
            <a href=""><img src="~@/assets/img/yt.svg" alt="YouTube" /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters({
      categories: "categories/list",
    }),
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? true
        : false;
    },
  },
};
</script>
<style lang="scss" module>
@import "@/styles/_variables";

.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  height: 225px;
  margin: auto 0 0;
  padding: 60px 170px;
  background: $light-grey;
}

.f-text {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 105px;
}

.f-text__heading {
  display: flex;
  margin: 0;
  line-height: 16.5px;
  align-items: center;

  &:before {
    margin: 0 10px 0 0;
    content: url("~@/assets/img/logo.svg");
    display: flex;
  }
}

.f-text__caption {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: $dark-grey;
}

.f-nav {
  display: flex;
  flex-flow: column wrap;
  width: 360px;
  min-width: 330px;
  max-height: 105px;
  margin: 0 auto;
  padding: 0;
}

.f-nav__item {
  align-items: flex-start;
  width: max-content;
  margin: 0 0 8px;

  &:nth-child(4n) {
    margin-bottom: 0;
  }

  & a {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: $light-black;
  }
}

.f-links {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin: 0;
  text-align: right;

  & a {
    color: $light-black;
    margin: 0 0 8px;
  }
}

.f-social {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  min-width: 145px;
  margin: 0;
  padding: 0;
}

.f-social__item {
  margin-left: 20px;
}

.m_footer {
  background: $light-grey;
  display: flex;
  flex-direction: column;
  padding: 32px 16px 16px 16px;
  margin-top: 48px;
  overflow: hidden;
}

.m-f-links {
  display: flex;
  margin: 0;
  text-align: right;
  margin-top: 32px;

  & a {
    color: $light-black;
    margin: 0 0 8px;
  }
}

.m-f-nav {
  display: flex;
  flex-flow: column wrap;
  width: 360px;
  min-width: 330px;
  max-height: 105px;
  padding: 0;
  margin-top: 32px;
}

.m-f-text {
  margin-top: 48px;
}

.payment-logo {
  margin-top: 8px;
  height: 15px;
}
</style>
