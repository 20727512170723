<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Программа вебинара:
            </div>
            <div :class="$style.programWrapper">
                <Programm name="Модуль 1" label="Вирусы" :color="0" :items="programmItems[0]" :text="programmText[0]" />
                <Programm name="Модуль 2" label="Профилактика" :color="1" :items="programmItems[1]" :text="programmText[1]"/>
                <Programm name="Модуль 3" label="Лечение" :color="2" :items="programmItems[2]" :text="programmText[2]"/>
            </div>
        </div>
    </div>
</template>
<script>
import Programm from './Programm.vue'
export default {
    components: { Programm },
    data() {
        return {
            programmText: [
                'Начнем со знакомства с вирусами, вы узнаете какие их виды существуют, какие симптомы вызывает каждый из них. Как они появляются и как проникают в наш организм.',
                'Посвящен иммунитету и способам его укрепления. Действительно, ли лук и чеснок помогают укреплять здоровье. Как кишечник и то, что мы едим связаны с иммунной системой.',
                'Расскажу какие лекарства стоит принимать, а какие лишь трата денег. Что и когда делать с температурой. Почему важно разжижать кровь и когда начинать пить антибиотики.'
            ],
            programmItems: [
                ['Виды вирусов', 'Эпидемиология', 'Пути проникновения в организм'],
                ['Что такое иммунитет', '8 факторов, которые влияют на его укрепление', 'Способы профилактики'],
                ['Способы облегчения симптомов', 'Температура. Когда сбивать', 'Рекомендации по лекарствам и их применению', 'Антибиотики']
            ]
        }
    },

    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>
    .main {
        background: #EEF6FE;
        width: 100%;

        &.mobile {
            width: calc(100% - 32px);
            padding: 0 16px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            padding: 40px 0;
        }
    }
    .programWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;

        .mobile & {
            margin-top: 32px;
        }
    }
    .title {
        font-size: 34px;
        line-height: 84px;
        letter-spacing: 0.278974px;
        font-weight: bold;
        color: #333333;
        .mobile & {
            font-size: 24px;
            line-height: 28px;
        }
    }
</style>