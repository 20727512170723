<template>
  <MainLayout>

<div :class="[$style['course-page'], isMobile ? $style.mobile : '']">
  <template v-if="loading">
    <div :class="$style.loader">
      <Loading />
    </div>
  </template>
  <template v-else>
    <Breadcrumbs v-if="item" :links="links"></Breadcrumbs>
    <div :class="[$style['course-page__cp-header'], $style['cp-header'], $style.header]">
      <div :class="[$style['cp-header__cp-text'], $style['cp-text']]">
        <h1 :class="$style['cp-text__heading']">{{item.name}}</h1>
      </div>
      <div v-if="!isMobile" :class="[$style['cp-header__cp-buy'], $style['cp-buy']]">
        <a :class="$style['cp-buy__trailer']" href="" @click.prevent="">Смотреть трейлер</a>
        <a :class="$style['cp-buy__button']" href="" @click.prevent="buyCourseWrapper" v-show="!paid">Купить курс <br> за {{ item.price * 100 | price }} </a>
      </div>
    </div>
    <p :class="$style['cp-text__description']">{{item.description}}</p>
    <div v-if="isMobile" :class="[$style['cp-header__cp-buy'], $style['cp-buy']]">
      <a :class="$style['cp-buy__trailer']" href="" @click.prevent="">Смотреть трейлер</a>
      <a :class="$style['cp-buy__button']" href="" @click.prevent="buyCourseWrapper" v-show="!paid">Купить курс <br> за {{ item.price * 100 | price }} </a>
    </div>
    <!-- <div class="course-page__cp-controls cp-controls">
      <label class="cp-controls__item" :class="{'cp-controls__item--active': contentType === 'watch'}"><input class="hidden" name="contentType" type="radio" value="watch" v-model="contentType">Смотреть</label>
      <label class="cp-controls__item" :class="{'cp-controls__item--active': contentType === 'listen'}"><input class="hidden" name="contentType" type="radio" value="listen" v-model="contentType">Слушать</label>
      <label class="cp-controls__item" :class="{'cp-controls__item--active': contentType === 'read'}"><input class="hidden" name="contentType" type="radio" value="read" v-model="contentType">Читать</label>
    </div> -->
    <div :class="$style.spacer"></div>
    <div :class="[$style['course-page__cp-webinars'], $style['cp-webinars']]" v-show="contentType === 'watch'">
      <div v-for="(item, key) in videos" :key="key" @click="openVideo(item)">
        <VideoCard :class="$style['cp-webinars__item']" :video="item"/>
      </div>

    </div>
    <div :class="[$style['course-page__cp-chapters'], $style['cp-chapters']]" v-show="contentType === 'listen'">
      <WebinarListen :class="$style['cp-chapters__item']" :numerate='true' v-for="(item, key) in content" :key="key" :item="item" :categoryId="category.id" />
    </div>
    <div :class="[$style['course-page__cp-chapters'], $style['cp-chapters']]" v-show="contentType === 'read'">
      <WebinarRead :class="$style['cp-chapters__item']" :numerate='true' v-for="(item, key) in content" :key="key" :item="item" />
    </div>
  </template>
  <transition name="slide">
    <template v-if="showVideo">
      <div :class="$style.popup">
        <div :class="$style.closePopup" @click="closePopup"></div>
        <CourseVideoPopup :video-id="currentVideo"/>
      </div>
    </template>
  </transition>
</div>
  </MainLayout>
</template>

<script>
import { vfirestore } from '@/db'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import VideoCard from '@/components/VideoCard.vue'
import WebinarRead from '@/components/WebinarRead.vue'
import WebinarListen from '@/components/WebinarListen.vue'
import Loading from '@/components/Loading.vue'
import CourseVideoPopup from '@/components/CourseVideoPopup.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import pay from '@/commons/pay'
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex'
export default {
  name: 'CoursePage',
  components: {
    Breadcrumbs,
    VideoCard,
    WebinarRead,
    WebinarListen,
    Loading,
    CourseVideoPopup,
    MainLayout
  },
  props: {
    itemId: {
      type: String,
      default: ''
    },
    courseId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      contentType: 'watch',
      item: false,
      content: {},
      videos: [],
      paid: false,
      loading: false,
      showVideo: false,
      currentVideo: '',
      toAuth: false,
    }
  },
  computed: {
    ...mapState({
      router: function() {
        return this.$router;
      },
      route: function() {
        return this.$route;
      }
    }),
    ...mapGetters({
      cartVisiblePage: 'localData/cartVisiblePage',
      webinars: "serverData/webinars",
      courses: "serverData/courses",
      categories: "serverData/categories",
      courseContent: "serverData/courseContent",
      isAuth: 'user/isAuth',
      user: 'user/user',
			categoryList: 'categories/list'
    }),
    links() {
      let list = [this.category, this.item];
      return list
    },
    backgroundStyle: function() {
      //video bg placeholder
      return {
        background: `url('${require('@/assets/img/'+this.item.image)}') no-repeat` || '#999'
      }
    },
    category() {
      return this.categoryList.find( i => i.id === this.itemId);
    },
		isMobile() {
			// return true;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
		}
  },
  methods: {
    ...mapActions({
      actionToggleCartVisibility: 'localData/toggleCartVisibility',
      actionToggleCartVisiblePage: 'localData/toggleCartVisiblePage',
      actionSetBuyingDigitalItem: 'localData/setBuyingDigitalItem',
      actionToggleCourseIsBought: "serverData/toggleCourseIsBought",
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",
    }),
    getCourseText: function() {
      return 'Пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста пример текста'
    },
    buyCourseWrapper() {
      if (this.isAuth) {
        this.buyCourse();
      } else {
        this.actionTogglePersonalDataVisibility(true);
        this.toAuth = true;
      }
    },
    async buyCourse() {
      await pay(this.item.price, this.user.uid, this.item.productId, this.user.email, 'course', this.ref, this.user.displayName, this.category.name, this.item.name)
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.initCourse();
      }, 5000)
      // this.actionToggleCourseIsBought({
      //   categoryId: this.item.categoryId,
      //   courseId: this.item.id,
      //   name: this.item.name,
      //   link: '/categories/courses/' + this.category.link + '/' + this.item.link,
      //   date: "31.05.2021",
      //   productId: this.item.productId,
      //   price: this.item.price
      // })
    },
    openVideo(item) {console.log('item')
      if (this.paid || !item.isPaid) {
        this.currentVideo = item.id;
        this.showVideo = true;
      } else {
        this.buyCourse();
      }
    },
    closePopup() {
      this.currentVideo = '';
      this.showVideo = false;
    },
    async initCourse(courseId) {
      this.loading = true;
      let _courseId = courseId || this.courseId
      let res = await vfirestore.collection('v').doc('1').collection('courses').doc(_courseId).get();
      this.item = { ...res.data(), name: res.data().title, id: res.id};
      if (this.isAuth) {
        let orders = ( await vfirestore.collection('v').doc('1').collection('orders').where('productId', '==', this.item.productId).where('status', '==', 1).where('uid', '==', this.user.uid).get() ).docs
        this.paid = orders.length ? true : false
      }
      res = await vfirestore.collection('v').doc('1').collection('videos').where('course', '==', _courseId).get();
      this.videos = res.docs.map(el => {
        return {
          ...el.data(),
          id: el.id
        }
      })

       if (this.toAuth)  {
        this.actionTogglePersonalDataVisibility(false);
        this.toAuth = false;
        if (!this.paid) this.buyCourse();
      }
      this.videos.sort((a , b) => a.number - b.number)
      this.loading = false;
    },
  },
  async mounted() {
    this.initCourse();
  },
  watch: {
    '$route' (to, from) {
      this.initCourse(to.params.courseId);
    },
    isAuth() {
      this.initCourse();
    },
    cartVisiblePage() {
      if (this.cartVisiblePage == 'done') this.initCourse()
    },
  }
}
</script>
<style lang="scss" module>
@import "@/styles/_variables";

.spacer {
  margin-top: 23px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.loader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.popup {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  box-shadow: 0px -7px 10px -5px rgba(34, 60, 80, 0.6);
}
.closePopup {
  position: absolute;
  right: 40px;
  top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: $light-grey;
  border-radius: 50px;
  cursor: pointer;

  &::after,
  &::before {
    content: "";
    position: absolute;
    display: flex;
    width: 15px;
    height: 2px;
    background: $light-black;
    transform: rotate(45deg);
    border-radius: 1px;
  }

  &::after {
    transform: rotate(-45deg);
  }

  .mobile & {
    right: 16px;
    top: 16px;
  }
}

.course-page {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;

  &.mobile {
    padding: 0 16px;
  }
}

.cp-header {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 10px 0 0;
  justify-content: space-between;
}

.cp-text__heading {
  display: flex;
  margin: 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;

  .mobile & {
    font-size: 28px;
  }
}

.cp-text__description {
  display: flex;
  margin: 5px 0 0;
  font-size: 40px;
  line-height: 47px;
  font-size: 16px;
  line-height: 20px;
}

.cp-buy {
  display: flex;
  flex-flow: row;
  margin: 5px 0 0;
}

.cp-buy__button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 190px;
  height: 48px;
  margin: 0;
  background: $blue;
  color: $white;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  line-height: 19px;
}

.cp-buy__trailer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 190px;
  height: 48px;
  margin: 0 12px 0 0;
  background: $light-grey;
  color: $blue;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  line-height: 19px;
}

.cp-controls {
  display: flex;
  flex-flow: row;
  margin: 40px 0 20px;
}

.cp-controls__item {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: min-content;
  height: 36px;
  margin: 0 8px 0 0;
  padding: 0 20px;
  background: $light-grey;
  color: $blue;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.cp-controls__item--active {
  background: $blue;
  color: $white;
}

.cp-webinars{
  display: flex;
  flex-flow: row wrap;
}

.cp-webinars__item{
  margin: 0 20px 20px 0;

  .mobile & {
    width: 100%;
    margin: 0;
    margin-top: 20px;
  }
}

.cp-chapters{
  display: flex;
  flex-flow: column;
  margin: 0 0 50px;
}


</style>
<style>

.slide-enter-active {
  top: 100%;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  top: 0;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-enter-to, .slide-leave {
  top: 0;
}

.slide-enter, .slide-leave-to {
  top: 100%;
}
</style>