<template>
<div :class="{
  'personal-data-wrapper': true,
  'mobile': isMobile
}" v-show="personalDataVisibility">
  <transition name="modalSlide">
    <div class="personal-data-popup">
      <a v-if="!isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
      <div class="personal-data__pd-menu pd-menu" v-show="personalDataVisiblePage === 'menu'">
        <div class="pd-menu__heading">
          <div v-if="isMobile" style="width: 24px"></div>
          <span>Профиль</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <h3 class="pd-menu__sub-heading">{{loginFormData.name}}</h3>
        <ul class="pd-menu__pd-menu pd-menu">
          <li class="pd-menu__item" @click.prevent="actionTogglePersonalDataVisiblePage('personal-data')"><a href="">Личные данные</a></li>
          <li class="pd-menu__item" @click.prevent="actionTogglePersonalDataVisiblePage('my-buyings')"><a href="">Мои покупки</a></li>
          <li class="pd-menu__item" @click.prevent="actionTogglePersonalDataVisiblePage('my-sertificates')"><a href="">Мои сертификаты</a></li>
          <li class="pd-menu__item" @click.prevent="actionTogglePersonalDataVisiblePage('help')"><a href="">Помощь</a></li>
        </ul>
        <ul class="pd-personal__pd-footer pd-footer">
          <li class="pd-footer__item" @click.prevent="actionTogglePersonalDataVisiblePage('language')"><a href="">Смена языка</a></li>
          <li class="pd-footer__item" @click.prevent=""><a href="">Юридическая информация</a></li>
          <li class="pd-footer__item" @click.prevent="logout(); actionTogglePersonalDataVisiblePage('login')"><a href="">Выйти из профиля</a></li>
        </ul>

      </div>

      <div class="personal-data__pd-login pd-login" v-show="personalDataVisiblePage === 'login'">
        <div class="pd-menu__heading">
          <div v-if="isMobile" style="width: 24px"></div>
          <span>Профиль</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <h3 class="pd-login__sub-heading">Вход/Регистрация</h3>
        <form class="pd-login__pd-login-form pd-login-form">
          <div v-if="error" :class="$style.error">
            {{ error }}
          </div>
          <!-- <label class="pd-login-form__pd-label pd-label pd-label--username">
            <span class="pd-label__caption">Номер телефона</span>
            <input class="pd-label__input" type="text" name="name" v-model="phone" required>
          </label> -->
          <label class="pd-login-form__pd-label pd-label pd-label--username">
            <span class="pd-label__caption">Логин</span>
            <input class="pd-label__input" type="text" name="name" v-model="login" required>
          </label>
          <label class="pd-login-form__pd-label pd-label pd-label--password">
            <span class="pd-label__caption">Пароль</span>
            <input class="pd-label__input" type="password" name="password" v-model="password" required>
          </label>
          
          <a class="pd-login-form__forgot-password" @click.prevent="actionTogglePersonalDataVisiblePage('recovery')">Забыли пароль?</a>
          <!-- <a class="pd-login-form__button pd-login-form__button--sign-in button--send-phone" @click.prevent="signIn">Отправить проверочный код</a> -->
          <a class="pd-login-form__button pd-login-form__button--sign-in" @click.prevent="signIn">Войти</a>
          <a class="pd-login-form__button pd-login-form__button--google" @click.prevent="logInWithGoogle">
            <img :src="require('@/assets/img/google.svg')" class="pd-login-form__google-logo"/>Войти через Google</a>
          <a class="pd-login-form__button pd-login-form__button--sign-up" @click.prevent="actionTogglePersonalDataVisiblePage('registration')">Зарегистрироваться</a>
        </form>

        <ul class="pd-personal__pd-footer pd-footer">
          <li class="pd-footer__item" @click.prevent="actionTogglePersonalDataVisiblePage('language')"><a href="">Смена языка</a></li>
          <li class="pd-footer__item" @click.prevent=""><a href="">Юридическая информация</a></li>
          <li class="pd-footer__item" @click.prevent="logout(loginFormData); actionTogglePersonalDataVisiblePage('login')"><a href="">Выйти из профиля</a></li>
        </ul>

      </div>
      
      <div class="personal-data__pd-login pd-login" v-show="personalDataVisiblePage === 'registration'">
        <div class="pd-menu__heading">
          <div v-if="isMobile" style="width: 24px"></div>
          <span>Профиль</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <h3 class="pd-login__sub-heading">Регистрация</h3>
        <form class="pd-login__pd-login-form pd-login-form">
          <label class="pd-login-form__pd-label pd-label pd-label--username">
            <span class="pd-label__caption">Email</span>
            <input class="pd-label__input" type="text" name="name" v-model="registrationData.email" required>
          </label>
          <label class="pd-login-form__pd-label pd-label pd-label--password">
            <span class="pd-label__caption">Пароль</span>
            <input class="pd-label__input" type="password" name="psss" v-model="registrationData.password" required>
          </label>
          <label class="pd-login-form__pd-label pd-label pd-label--password">
            <span class="pd-label__caption">Пароль еще раз</span>
            <input class="pd-label__input" type="password" name="confirm" v-model="registrationData.confirm" required>
          </label>
          <div id="recaptcha-container"></div>
          <div :class="$style.policy">Нажимая “Зарегистрироваться” Вы соглашаетесь с обработкой Ваших персональных данных. Правила обработки персональных данных Вы можете найти <a href="/Mental Market Политика в отношении обработки персональных данных.pdf">здесь</a></div>
          <a class="pd-login-form__button pd-login-form__button--sign-in"  type="submit" @click.prevent="signUp">Зарегистрироваться</a>
          <a class="pd-login-form__button pd-login-form__button--sign-up" @click.prevent="actionTogglePersonalDataVisiblePage('login')">Вход</a>
        </form>

        <ul class="pd-personal__pd-footer pd-footer">
          <li class="pd-footer__item" @click.prevent="actionTogglePersonalDataVisiblePage('language')"><a href="">Смена языка</a></li>
          <li class="pd-footer__item" @click.prevent=""><a href="">Юридическая информация</a></li>
        </ul>

      </div>

      <div class="personal-data__pd-login pd-login" v-show="personalDataVisiblePage === 'recovery'">
        <div class="pd-menu__heading">
          <a class="personal-data-back" href="" @click.prevent="back"><img :src="require('@/assets/img/arrow.svg')" /></a>
          <span>Профиль</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <h3 class="pd-login__sub-heading">Восстановление пароля</h3>
        <form class="pd-login__pd-login-form pd-login-form">
          <label class="pd-login-form__pd-label pd-label pd-label--username">
            <span class="pd-label__caption">Email</span>
            <input class="pd-label__input" type="text" name="name" v-model="registrationData.email" required>
          </label>
          <div id="recaptcha-container"></div>
          <a class="pd-login-form__button pd-login-form__button--sign-in"  type="submit" @click.prevent="recoveryPasssword">Восстановить пароль</a>
        </form>

        <ul class="pd-personal__pd-footer pd-footer">
        </ul>

      </div>

      <div class="personal-data__pd-login pd-login" v-show="personalDataVisiblePage === 'recovery-success'">
        <div class="pd-menu__heading">
          <div v-if="isMobile" style="width: 24px"></div>
          <span>Профиль</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <h3 class="pd-login__sub-heading">Восстановление пароля</h3>
        <form class="pd-login__pd-login-form pd-login-form">
          <div id="recaptcha-container"></div>
          Инструкция для восстановления пароля отправлена на email
        </form>

        <ul class="pd-personal__pd-footer pd-footer">
        </ul>

      </div>

      <div class="personal-data__pd-login pd-login" v-show="personalDataVisiblePage === 'reset-password'">
        <div class="pd-menu__heading">
          <div v-if="isMobile" style="width: 24px"></div>
          <span>Профиль</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <h3 class="pd-login__sub-heading">Новый пароль</h3>
          <form class="pd-login__pd-login-form pd-login-form">
          
            <label class="pd-login-form__pd-label pd-label pd-label--password">
                <span class="pd-label__caption">Новый пароль</span>
                <input class="pd-label__input" type="password" name="psss" v-model="registrationData.password" required>
              </label>
              <label class="pd-login-form__pd-label pd-label pd-label--password">
                <span class="pd-label__caption">Пароль еще раз</span>
                <input class="pd-label__input" type="password" name="confirm" v-model="registrationData.confirm" required>
              </label>
            <a class="pd-login-form__button pd-login-form__button--sign-in"  type="submit" @click.prevent="setNewPass">Создать новый пароль</a>
        </form>

        <ul class="pd-personal__pd-footer pd-footer">
        </ul>

      </div>


      <div class="personal-data__pd-login pd-login" v-show="personalDataVisiblePage === 'link-auth'">
        <div class="pd-menu__heading">
          <div v-if="isMobile" style="width: 24px"></div>
          <span>Профиль</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <h3 class="pd-login__sub-heading">Вход по ссылке</h3>
        <form class="pd-login__pd-login-form pd-login-form">
          <label class="pd-login-form__pd-label pd-label pd-label--username">
            <span class="pd-label__caption">Email</span>
            <input class="pd-label__input" type="text" name="name" v-model="registrationData.email" required>
          </label>
          <div id="recaptcha-container"></div>
          <a class="pd-login-form__button pd-login-form__button--sign-in"  type="submit" @click.prevent="signUpWithEmail">Войти</a>
        </form>

        <ul class="pd-personal__pd-footer pd-footer">
        </ul>

      </div>
      
      <div class="personal-data__pd-data pd-data" v-show="personalDataVisiblePage === 'personal-data'">
        <a v-if="!isMobile" class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" /></a>
        <div class="pd-menu__heading">
          <a v-if="isMobile" class="personal-data-back" href="" @click.prevent="back"><img :src="require('@/assets/img/arrow.svg')" /></a>
          <span>Личные данные</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <form class="pd-data__pd-data-form pd-data-form">
          <label class="pd-data-form__pddf-label pddf-label">
            <span class="pddf-label__caption">Email</span>
            <input class="pddf-label__input" type="email" name="email" v-model="user.email" required>
          </label>

          <label class="pd-data-form__pddf-label pddf-label">
            <span class="pddf-label__caption">Ваше имя</span>
            <input class="pddf-label__input" type="text" name="name" v-model="user.displayName" required>
          </label>

          <label class="pd-data-form__pddf-label pddf-label">
            <span class="pddf-label__caption">Номер телефона</span>
            <input class="pddf-label__input" type="phone" name="phone" v-model="user.phone" required>
          </label>
        </form>
      </div>

      <div class="pd-personal__pd-language pd-language" v-show="personalDataVisiblePage === 'language'">
        <a class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" /></a>
        <h2 class="pd-language__heading">Смена языка</h2>
        <LanguageSelect class="pd-language__language-select" source="personal" />
      </div>

      <div class="pd-personal__help" v-show="personalDataVisiblePage === 'help'">
        <a v-if="!isMobile" class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" /></a>
        <div class="pd-menu__heading">
          <a v-if="isMobile" class="personal-data-back" href="" @click.prevent="back"><img :src="require('@/assets/img/arrow.svg')" /></a>
          <span>Помощь</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <Help />
      </div>

      <div class="pd-personal__pd-buyings pd-buyings" v-show="personalDataVisiblePage === 'my-buyings'">

        <a v-if="!isMobile" class="personal-data-back" href="" @click.prevent="back">
        <img :src="require('@/assets/img/arrow.svg')" /></a>

        <div class="pd-menu__heading">
          <a v-if="isMobile" class="personal-data-back" href="" @click.prevent="back"><img :src="require('@/assets/img/arrow.svg')" /></a>
          <span>Мои покупки</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <ul class="pd-buyings__pdb-list pdb-list" v-show="userBuyings.length">
          <li class="pdb-list__pdbl-item pdbl-item" v-for="(item, key) in userBuyings" :key="key">
            <!-- <OrderListItem v-if="!item.productId" :item="item" @click.prevent="actionTogglePersonalDataVisiblePage('order'); openedItem = item"/> -->
            <OrderListItemProduct v-if="item.productId" :productItem="item" :products="products" @click.prevent="actionTogglePersonalDataVisiblePage('')"/>
          </li>
        </ul>
        <div class="pd-buyings__pdb-empty pdb-empty" v-show="!userBuyings.length">
          <h3 class="pdb-empty__heading">Список покупок пуст</h3>
          <p class="pdb-empty__caption">Оформляйте покупки и они появятся здесь</p>
        </div>
      </div>

      <div class="pd-personal__pd-order" v-show="personalDataVisiblePage === 'order'">
        <a class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" />
          </a>
        <Order source="personal" :orderData="openedItem"/>
      </div>

      <div class="pd-personal__pd-course" v-show="personalDataVisiblePage === 'course'">
        <a class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" />
          </a>
        <CourseBought :item='openedItem' />
      </div>

      <div class="pd-personal__pd-webinar" v-show="personalDataVisiblePage === 'webinar'">
        <a class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
         <img :src="require('@/assets/img/arrow.svg')" />
         </a>
        <WebinarBought :item="openedItem" />
      </div>

      <div class="pd-personal__pd-sertificates" v-show="personalDataVisiblePage === 'my-sertificates'">
        <a v-if="!isMobile" class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" /></a>
        <div class="pd-menu__heading">
          <a v-if="isMobile" class="personal-data-back" href="" @click.prevent="back"><img :src="require('@/assets/img/arrow.svg')" /></a>
          <span>Мои сертификаты</span>
          <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionTogglePersonalDataVisibility(false);"></a>
        </div>
        <ul class="pd-sertificates__pds-list pds-list" v-show="userSertificates.length">
          <li class="pds-list__pdsl-item pdsl-item" v-for="(item, key) in userSertificates" :key="key" @click.prevent="actionTogglePersonalDataVisiblePage('sertificate'); openedSertificate = item">
            <h3 class="pdsl-item__heading">{{"Курс \""+item.name+"\""}}</h3>
            <p class="pdsl-item__date">{{new Date().toDateString() == new Date(item.date).toDateString() ? 'Сегодня' : item.date}}</p>
          </li>
        </ul>
        <div class="pd-sertificates__pds-empty pds-empty" v-show="!userSertificates.length">
          <h3 class="pds-empty__heading">Список сертификатов пуст</h3>
          <p class="pds-empty__caption">Изучайте курсы, проходите тестирования и здесь будут храниться Ваши сертификаты</p>
        </div>
      </div>

      <div class="pd-personal__pd-course-unseen" v-show="personalDataVisiblePage === 'course-unseen'">
        <a class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" />
          </a>
        <h2 class="pd-course-unseen__heading">Наименование</h2>
        <div class="pd-course-unseen__pdc-empty pdc-empty">
          <h3 class="pdc-empty__heading">Курс не изучен</h3>
          <p class="pdc-empty__caption">Изучите полный курс, пройдите тестирование и здесь будет храниться Ваш сертификат</p>
        </div>
      </div>
      <div class="pd-personal__pd-sertificate" v-show="personalDataVisiblePage === 'sertificate'">
        <a class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()">
          <img :src="require('@/assets/img/arrow.svg')" />
          </a>
        <h2 class="pd-sertificate__heading">{{openedSertificate.name}}</h2>
        <img class="pd-sertificate__image" src="@/assets/img/sertificate.png" alt='Фото сертификата'>
        <a class="pd-sertificate__button" href="" @click.prevent="">Сохранить в pdf</a>
      </div>

    </div>
  </transition>
</div>
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect.vue'
import Help from '@/components/ModalComponent/Help.vue'
import Order from '@/components/ModalComponent/Order.vue'
import WebinarBought from '@/components/ModalComponent/WebinarBought.vue'
import CourseBought from '@/components/ModalComponent/CourseBought.vue'
import { vAuth, vfirestore } from '@/db.js'
import firebase from 'firebase/app'
import {
  mapActions,
  mapGetters
} from 'vuex'
import OrderListItem from '@/components/OrderListItem'
import OrderListItemProduct from '@/components/ModalComponent/OrderListItemProduct'
// import { getAuth, RecaptchaVerifier, sendPasswordResetEmail } from "firebase/auth";

export default {
  name: 'PersonalData',
  components: {
    LanguageSelect,
    Order,
    WebinarBought,
    CourseBought,
    Help,
    OrderListItem,
    OrderListItemProduct
  },
  data() {
    return {
      personalFormData: {},
      userBuyings: false,
      products: [],
      openedItem: {
        name: "",
        link: "",
        date: ""
      },
      openedSertificate: {
        name: "",
        date: ""
      },
      loginFormData: {
        name: '',
        password: ''
      },
      registrationData: {
        email: '',
        password: '',
        confirm: ''
      },
      userSertificates: [],
      error: '',
      login: '',
      password: ''
      // если надо показать меню сертификатов - раскомментировать то что внизу
      // userSertificates: [
      //   {name: "Сертификат 1", date: "2021.04.16"},
      //   {name: "Сертификат 2", date: "2021.03.18"}
      // ]
    }
  },
  methods: {
    ...mapActions({
      actionSetUserData: "serverData/setUserData",
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",
      actionTogglePersonalDataVisiblePage: "localData/togglePersonalDataVisiblePage",
      loginWidthGoogleWrapper: "user/loginWidthGoogle",
      logout: 'user/logout'

    }),

    async logInWithGoogle() {
      try {
        await this.loginWidthGoogleWrapper();
      } catch (e) {
        console.error(e);
      }
    },

    async signUp() {
      if (this.registrationData.password == this.registrationData.confirm) {
        let res = await vAuth.createUserWithEmailAndPassword(this.registrationData.email.trim(), this.registrationData.password)
        this.actionTogglePersonalDataVisiblePage('menu')
      }
    },
    async onSignInSubmit() {
      console.log('submited');
    },
    async signIn() {
      
      // var appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      // firebase.auth().signInWithPhoneNumber(this.phone, appVerifier)
      // .then(function (confirmationResult) {
      //   // confirmationResult can resolve with the fictional testVerificationCode above.
      //   return confirmationResult.confirm(testVerificationCode)
      // }).catch(function (error) {
      //   // Error; SMS not sent
      //   // ...
      // });

      
      vAuth
        .signInWithEmailAndPassword(this.login.trim(), this.password)
        .then(async (data) => {
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
    async getOrders() {
      let res = await vfirestore.collection('v').doc('1').collection('orders').where('uid', '==', this.user.uid).where('status', '==', 1).get();  
      this.userBuyings = res.docs.map( i => ({ ...i.data(), id: i.id })).filter(i => i.type)
      let productWebinarsIds = this.userBuyings.filter(item => item.type == 'webinar').map( i => i.productId ).filter( i => i);
      let productCourseIds = this.userBuyings.filter(item => item.type != 'webinar').map( i => i.productId ).filter( i => i);
      let productObject = {}
      // productIds.forEach(item => {
      //   productObject[item] = true
      // })
      // productIds = Object.keys(productObject)
      this.loadCourses(productCourseIds)
      this.loadWebinars(productWebinarsIds)
    },

    async loadCourses(productIds) {
      if (productIds.length == 0) return
      let res = await vfirestore.collection('v').doc('1').collection('courses').where('productId', 'in', productIds.splice(0, 9)).get();
      this.products = [...this.products, ...res.docs.map(i => ({...i.data(), id: i.id}))]
      this.loadCourses(productIds)
    },

    async loadWebinars(productIds) {
      if (productIds.length == 0) return
      let res = await vfirestore.collection('v').doc('1').collection('webinars').where('productId', 'in', productIds.splice(0, 9)).get();
      this.products = [...this.products, ...res.docs.map(i => ({...i.data(), id: i.id}))]
      this.loadWebinars(productIds)
    },

    back() {
      if (this.isAuth) {
        this.actionTogglePersonalDataVisiblePage('menu');
      } else {
        this.actionTogglePersonalDataVisiblePage('login');
      }
    },
    recoveryPasssword() {
      vAuth.sendPasswordResetEmail(this.registrationData.email)
        .then(() => {
          this.actionTogglePersonalDataVisiblePage('recovery-success')
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });

    },
    signUpWithEmail() {
       vAuth.signInWithEmailLink(this.registrationData.email, window.location.href)
        .then((result) => {
          window.location.href = 'https://mentalmarket.ru/categories/p8RJgvgrmWFTgXdhlVCl/webinars/SDjUnHIpjhxdXYFiwLGZ'
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    },
    async setNewPass() {
      if (this.registrationData.password == this.registrationData.confirm) {
       await vAuth.confirmPasswordReset(this.$route.query.oobCode, this.registrationData.password)
       alert('Пароль успешно изменен')
        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
        this.actionTogglePersonalDataVisiblePage('login')
      } else {
        alert('Пароли не совпадают')
      }
    }

  },
  computed: {
    ...mapGetters({
      userData: "serverData/userData",
      personalDataVisibility: "localData/personalDataVisibility",
      personalDataVisiblePage: "localData/personalDataVisiblePage",
      personalDataPreviousPage: "localData/personalDataPreviousPage",
      isAuth: 'user/isAuth',
      user: 'user/user'
    }),
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  },
  watch: {
    isAuth() {
      if (this.isAuth) {
        this.actionTogglePersonalDataVisiblePage('menu')
        // this.getOrders();

      } else {
        this.actionTogglePersonalDataVisiblePage('login')
      }
    },
    personalDataVisiblePage: function() {
      if (this.personalDataVisiblePage == 'my-buyings') {
        this.getOrders();
      }
      if (this.personalDataVisiblePage == 'login') {
        // setTimeout(() => {
        //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('button--send-phone', {
        //   'size': 'invisible',
        //   'callback': (response) => {
        //     this.signIn();
        //   }
        // });
        // },100)
      }
      // if (!Object.keys(this.userData).length && this.personalDataVisiblePage != 'registration') this.actionTogglePersonalDataVisiblePage('login')
    }
  },
  mounted() {
    
    if (this.isAuth) {
      this.actionTogglePersonalDataVisiblePage('menu')
    } else {
      this.actionTogglePersonalDataVisiblePage('login')
    }
  }
}
</script>
<style lang="scss" module>
.error {
  width: 100%;
  color: red;
  font-size: 15px;
  margin-bottom: 16px;
}
.policy {
    color: #999;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
}
</style>