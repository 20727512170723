export default {
    setUser(state, payload) {
        state.user = {
            uid: payload.uid,
            email: payload.email,
            displayName: payload.displayName,
            emailVerified: payload.emailVerified,
            photoURL: payload.photoURL,
            displayName: payload.displayName,
        }
    }
}