<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Регистрация на вебинар
            </div>
            <div :class="$style.info">
                <div :class="$style.inputContainer">
                    <input :class="[name.length == 0 && isDerty ? $style.error : '']" type="text" v-model="name" placeholder="Ваше Имя и Фамилия">
                </div>
                <div :class="$style.spacer"></div>
                <div :class="$style.inputContainer">
                    <input :class="[email.length == 0 && isDerty ? $style.error : '']" v-mask="emailMask" type="email" v-model="email" placeholder="Адрес электронной почты">
                </div>
                <div :class="$style.spacer"></div>
                <div :class="$style.inputContainer">
                    <input :class="[phone.length == 0 && isDerty ? $style.error : '']" v-mask="{mask: '9(999) 999-99-99'}" type="text" v-model="phone" placeholder="Телефон">
                </div>
            </div>
            <div :class="[$style.policy, isCheckboxValid ? '' : $style.error ]">
                <input v-model="isAgree" type="checkbox" />
                <span>
                    Я соглашаюсь на обработку моих персональных данных. <br> Принимаю пользовательское соглашение
                </span>
            </div>
            <button :class="$style.button" @click="buyWebinar">
                Зарегистрироваться на вебинар
            </button>
            <div :class="$style.price">
                Купи сейчас по выгодной цене {{ price }} руб.
            </div>
            <div :class="$style.agreement">
                и получи подарки от Дениса Викторовича
            </div>
            <div :class="$style.farma">
                вебинар: «Иммунитет против Вирусов» и <br>
                “Список лекарств первой необходимости в домашней аптечке”!
            </div>
        </div>
        <template v-if="isLoading">
            <div :class=    "$style.loader">
            <Loading />
            </div>
        </template>
        <div :class="$style.alert" v-if="isModalOpen">
            <div :class="$style.alert_content">
                <span>
                    Вам на почту <br><br>
                    было отправлено письмо с инструкцией! <br><br><br>
                    Спасибо за покупку!

                </span>
                <button :class="$style.button" @click="isModalOpen = false">
                Ок
            </button>
            </div>
        </div>
    </div>
    
</template>
<script>
import { vFunctions,vfirestore, vAuth } from '@/db.js'
import {pay} from '@/commons/pay.js'
import Loading from '@/components/Loading.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        Loading
    },

    props: {
        priceProp: {
            type:Number
        }
    },
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            isAgree: false,
            isLoading: false,
            isModalOpen: false,
            isCheckboxValid: true,
            isDerty: false,
            emailMask: {
                mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
                greedy: false,
                onBeforePaste: function (pastedValue, opts) {
                pastedValue = pastedValue.toLowerCase();
                return pastedValue.replace("mailto:", "");
                },
                definitions: {
                '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                    casing: "lower"
                }
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            isAuth: 'user/isAuth',
        }),
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
        ref() {
            return this.$route.query ? this.$route.query.ref : '';
        },
        oldPrice() {
            return this.$route.query ? this.$route.query.oldPrice : '';
        },
        price() {
            if (this.oldPrice) return 1490
            return this.priceProp
        }
    },
    methods: {
        ...mapActions({
            logout: 'user/logout'
        }),
        async buyWebinar() {
            let ref = this.$route.query ? this.$route.query.ref : '';
                this.isCheckboxValid = true;
                this.isDerty = true;
                if (!this.isAgree) {
                    this.isCheckboxValid = false;
                    return;
                }

                if (this.name && this.phone && this.email) {
                    this.isLoading = true;
                    
                    await vFunctions.httpsCallable('startBuying')({
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        productId: 'ivanovStress',
                        ref: this.ref ? this.ref : ''
                    });
                    
                    try {
                        let res = await vAuth.createUserWithEmailAndPassword(this.email, '111111')
                        let user = await vAuth
                            .signInWithEmailAndPassword(this.email, '111111')
                            await pay(this.price, user.user.uid, 'ivanovStress', this.email, "webinar", this.ref, this.name);
                            this.$emit('update')
                            this.isModalOpen = true;
                    }
                    catch(e) {
                        if (e && e.code == 'auth/email-already-in-use') {
                            await pay(this.price, '', 'ivanovStress', this.email, "webinar", this.ref, this.name);
                            this.isModalOpen = true;
                            this.$emit('update')
                        } else {
                            this.$emit('update')
                        }
                    }

                    
                } else {
                    this.isLoading = false;
                    alert('Не все поля заполнены.')
                    
                }
           

            this.isLoading = false;
        },
    },
    async mounted() {

        // let start = new Date('2022-02-01');
        // let res = await vfirestore.collection('v').doc('1').collection('startBuying').where('date', '>', start).get(); 
        // let resOrders = await vfirestore.collection('v').doc('1').collection('orders').where('status', '==', 1).get(); 
        // let now = Date.now()
        // let startBying = res.docs.map( item => item.data() );
        // let orders = resOrders.docs.map( item => item.data() ) 
        // let result = [];

        // startBying.forEach(item => {
        //     if (orders.find(order => (order.email == item.email && order.productId == 'ivanovStress') )  ) {
        //         result.push(item)
        //     }
        // })

        // console.log(result.map( item => item.email + '|' + item.name + '|' + item.phone + '|' + item.ref).toString())

    }
        
}
</script>
<style lang="scss" module>
    .main {
        width: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            width: calc(100% - 32px);
            padding: 40px 16px;
        }
    }

    .title {
        font-family: Montserrat-bold;
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
        /* or 133% */

        text-align: center;

        /* green */

        color: #41757C;

        .mobile & {
            text-align: center;
            font-size: 12px;
            line-height: 12px;
        }
    }

    .subtitle {
        font-family: NoirPro-Regular;
        font-size: 18px;
        line-height: 24px;
        margin-top: 12px;
        text-align: center;
        letter-spacing: 0.0976408px;

        color: #FFFFFF;
    }
    .price {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-top: 17px;

        letter-spacing: 0.0976408px;
        color: #FFFFFF;

        .oldPrice {
            text-decoration-line: line-through;
            margin-right: 4px;
        }
        .newPrice {
            text-decoration-line: none;
            background: #F0163D;
            border-radius: 9px;
            padding: 1px 6px 1px 6px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.0976408px;

            color: #FFFFFF;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        width: 100%;
        max-width: 732px;
        
        .mobile & {
            margin-top: 26px;
            flex-direction: column;
            justify-content: flex-start;
        }

    }

    .inputContainer {
        display: flex;
        width: 100%;
        margin-top: 36px;
        .mobile & {
            margin-top: 16px;
        }
        input {
            width: 100%;
            background: rgba(255, 255, 255, 0.7);
            height: 93px;

            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 12px;            
            padding: 14px 16px 14px 16px;
            

            font-family: Montserrat-Bold;
            font-weight: 500;
            font-size: 30px;
            line-height: 30px;
            letter-spacing: -0.01em;
            color: #000000;

            &::placeholder {
                font-family: Montserrat-Bold;
                font-weight: 500;
                font-size: 30px;
                line-height: 30px;
                letter-spacing: -0.01em;
                color: rgba(65, 117, 124, 0.4);
            }

            &:focus {
                outline: none;
            }

            &.error {
                border: 1px solid red;
            }

            .mobile & {
                height: 36px;
                font-size: 10px;
                line-height: 10px;
                border-radius: 6px;
                 &::placeholder {
                    font-size: 10px;
                    line-height: 10px;
                }
            }
        }
    }

    .spacer {
        width: 20px;
    }
    .button {
        width: 729px;
        height: 112px;
        background: #2A9D8F;
        border-radius: 10px;
        margin-top: 46px;
        border: none;
        font-family: Montserrat-Bold;
        font-weight: bold;
        font-size: 30px;
        line-height: 64px;
        
        color: #FFFFFF;

        .mobile & {
            height: 46px;
            width: 255px;
            border-radius: 6px;
            font-size: 12px;
            line-height: 14px;
        }
    }

    .loader {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .alert {
        background: rgba(0,0,0,0.3);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;

  
    }

    .alert_content {
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 400px;
        height: 300px;
        border-radius: 22px;

    
    }
    .policy {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 732px;
        margin-top: 19px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.01em;

        input {
            width: 20px;
            height: 20px;
            margin-right: 16px;
        }

        color: rgba(65, 117, 124, 0.4);

        .mobile & {
            font-size: 8px;
            line-height: 10px;
        }

        &.error {

            color: #E76F51;

            input {
                width: 20px;
                height: 20px;
                margin-right: 16px;
                border: 1px solid #E76F51;
            }

        }
    }

    .price {
        font-family: Montserrat-Bold;
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
        text-align: center;
        color: #C04B4B;

        .mobile & {
            font-size: 12px;
            line-height: 12px;
        }
    }

    .agreement {
        font-family: Montserrat-Bold;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        color: #437D76;
        
        .mobile & {
            font-size: 10px;
            line-height: 14px;
        }
    }

    .farma {
        font-family: Montserrat;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        text-decoration-line: underline;
        color: #437D76;


        .mobile & {
            font-size: 10px;
            line-height: 14px;
        }
    }

</style>