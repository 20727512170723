import VueRouter from "vue-router";
import Vue from 'vue'
import Index from '../views/Index.vue'
import Categories from '../views/Categories.vue'
import CategoryPage from '../views/CategoryPage.vue'
import News from '../views/News.vue'
import NewsPage from '../views/NewsPage.vue'
import Items from '../views/Items.vue'
import ItemPage from '../views/ItemPage.vue'
import Webinars from '../views/Webinars.vue'
import WebinarPage from '../views/WebinarPage.vue'
import Courses from '../views/Courses.vue'
import CoursePage from '../views/CoursePage.vue'
import CourseVideoPage from '../views/CourseVideoPage.vue'
import WebinarIvanov from '../views/Denisov/index.vue'
import StressIvanov from '../views/Stress/index.vue'
import Sex from '../views/Sex/index.vue'
import Authors from '../views/Authors.vue'

Vue.use(VueRouter)

const routes = window.location.href.includes('webinar-ivanov') ?
  [
    {
      path: '/',
      name: 'Index',
      component: WebinarIvanov
    }
  ] : [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/Stress',
    name: 'Stress',
    component: StressIvanov
  },
  {
    path: '/Sex',
    name: 'Sex',
    component: Sex
  },
  {
    path: '/liza',
    name: 'liza',
    component: Sex
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/news/:itemId',
    name: 'NewsPage',
    props: true,
    component: NewsPage
  },
  {
    path: '/items',
    name: 'Items',
    component: Items
  },
  {
    path: '/items/:id',
    name: 'ItemPage',
    component: ItemPage,
    props: true
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/categories/:itemId',
    name: 'CategoryPage',
    props: true,
    component: CategoryPage
  },
  {
    path: '/categories/:itemId/webinars',
    name: 'Webinars',
    component: Webinars,
    props: true
  },
  {
    path: '/categories/:itemId/webinars/:webinarId',
    name: 'WebinarPage',
    props: true,
    component: WebinarPage
  },

  {
    path: '/categories/:itemId/courses',
    name: 'Courses',
    component: Courses,
    props: true,
  },
  {
    path: '/categories/:itemId/courses/:courseId',
    name: 'CoursePage',
    props: true,
    component: CoursePage
  },
  {
    path: '/authors',
    name: 'Authors',
    component: Authors
  }

  
  // {
  //   path: '*',
  //   name: 'Error',
  //   component: Error
  // },
];

export default new VueRouter({
  mode: 'history',
  routes // сокращённая запись для `routes: routes`
})
// export default createRouter({
  
//   routes,
// });
