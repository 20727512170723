<template>
	<div :class="$style.categorySlider">
		<div :class="$style.slider" ref="slider">
			<transition v-if="!isMobile"  name="fade">
				<a :class="[$style.button, $style.buttonPrevious]" href="" v-show="canSlideToPrev" @click.prevent="slideToPrevItem"></a>
			</transition>

            <div ref="slideWrapper" :class="$style.slideWrapper">
                <slot>
                </slot>
			</div>

			<transition v-if="!isMobile" name="fade">
				<a :class="[$style.button, $style.buttonNext]" href="" v-show="canSlideToNext" @click.prevent="slideToNextItem"></a>
			</transition>
		</div>
	</div>
</template>

<script>
import gsap from 'gsap';

export default {
	name: "CategorySlider",
	data() {
		return {
			showItemsFrom: 0,
			amountOfVisibleItems: 0,
			list: [{},{},{},{},{},{}],
			scrollLeft: 0,
		}
	},
	methods: {
		slideToPrevItem() {
            let firstChildWidth = this.$refs['slideWrapper'].children[0].offsetWidth;
			if (this.$refs['slider'].scrollLeft > firstChildWidth) {
				gsap.fromTo(this.$refs['slider'], {scrollLeft: this.$refs['slider'].scrollLeft}, {scrollLeft: this.$refs['slider'].scrollLeft - firstChildWidth, duration: 0.3})
			} else {
				gsap.fromTo(this.$refs['slider'], {scrollLeft: this.$refs['slider'].scrollLeft}, {scrollLeft: 0, duration: 0.3})
				this.scrollLeft = 0
			}
		},
		slideToNextItem() {
            let firstChildWidth = this.$refs['slideWrapper'].children[0].offsetWidth;
			if (this.$refs['slider'].scrollLeft < this.$refs['slideWrapper'].offsetWidth - firstChildWidth) {
				gsap.fromTo(this.$refs['slider'], {scrollLeft: this.$refs['slider'].scrollLeft}, {scrollLeft: this.$refs['slider'].scrollLeft + firstChildWidth, duration: 0.3})
				this.scrollLeft = this.$refs['slider'].scrollLeft + firstChildWidth
			} else {
				gsap.fromTo(this.$refs['slider'], {scrollLeft: this.$refs['slider'].scrollLeft}, {scrollLeft: this.$refs['slideWrapper'].offsetWidth, duration: 0.3})
				this.scrollLeft = 'max';
			}
		},
		calculateVisibleItems() {
			return this.amountOfVisibleItems = Math.floor(this.$refs.slider.offsetWidth / 198) || 1
		}
	},
	computed: {
		canSlideToPrev: function() {
			return this.scrollLeft != 0
		},
		canSlideToNext: function() {
			return this.scrollLeft != 'max'
		},
		isMobile() {
			// return true;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
		}
	},
}
</script>

<style lang="scss" module>

@import "@/styles/_variables";

.categorySlider {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	width: 100%;
}

.slider {
	display: flex;
	width: 100%;
	padding-bottom: 20px;
	// height: 225px;
	overflow-x: auto;
}
.slideWrapper {
	display: flex;
    justify-content: space-between;

    & > * {
        margin: 0 20px 0 0;
    }
}


.item {
	margin: 0 20px 0 0;
}


.button {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: $white;
  border-radius: 50px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transform: translate(0, -50%);

  &::after,
  &::before {
    content: "";
    position: absolute;
    display: flex;
    width: 10px;
    height: 2px;
    background: $light-black;
    bottom: 11px;
    transform: rotate(-45deg);
    border-radius: 1px;
  }

  &::after {
    top: 11px;
    transform: rotate(45deg);
  }
}

.buttonNext {
  right: -45px;
}

.buttonPrevious {
  left: -45px;

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

</style>