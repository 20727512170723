<template>
  <MainLayout>

<div :class="[ $style['news-page'], isMobile ? $style.mobile : '']">
  <Breadcrumbs :links="[item]"></Breadcrumbs>
  <div :class="$style.video">
    <!-- <img v-if="!showVideo" :src="item.image" /> -->
    <video v-if="item.link" ref="video" width="540" height="304" controls="controls" :poster="item.image">
      <source :src="item.link">
    </video>
    <div v-if="!showVideo" :class="$style.playIcon" @click="playVideo">
      <img src="../assets/img/play.svg" alt="">
    </div>
    <!-- <IconPlay :class="$style.playIcon"/> -->
  </div>
  <div :class="[$style['news-page__np-content'], $style['np-content']]">
    <h1 :class="$style['np-content__heading']">{{item.name}}</h1>
    <div :class="$style['np-content__data']">
      <span :class="$style['np-content__data--time']">
          <!-- <ClockIcon :class="$style.clock_icon"/> -->
          {{item.date}}
        </span>
      <span :class="$style['np-content__data--views']">
        <!-- <EyeIcon :class="$style.eye_icon"/> -->
        {{item.views}}
      </span>
    </div>
    <p :class="{'np-content__text--full': showMore, 'np-content__text': true}">{{item.text}}</p>
    <a :class="$style['np-content__show-more']" href="" @click.prevent="showMore=true" v-show="!showMore">Показать полностью...</a>
  </div>
</div>
  </MainLayout>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
// import EyeIcon from '@/assets/img/eye.svg'
// import ClockIcon from '@/assets/img/clock.svg'
// import IconPlay from '@/assets/img/play.svg'
import {vfirestore} from '@/db.js'
import {
  mapState
} from "vuex"
export default {
  name: 'NewsPage',
  components: {
    Breadcrumbs,
    MainLayout,
    // IconPlay
    // EyeIcon,
    // ClockIcon
  },
  props: {
    itemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      item: {},
      showMore: false,
      showVideo: false
    }
  },
  methods: {
    playVideo() {
      this.showVideo = true;
      this.$nextTick(() => {
        this.$refs['video'].play()
      });
    }
  },
  computed: {
    ...mapState({
      router: function() {
        return this.$router;
      },
      route: function() {
        return this.$route;
      }
    }),
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    }
  },
  async mounted() {
    let res = await vfirestore.collection('v').doc('1').collection('news').doc(this.itemId).get();
    this.item = { ...res.data(),  id: res.id, name: res.data().title }
    if (!this.item) return this.router.push(`/error`)
  }
}
</script>

<style lang="scss" module>
@import "@/styles/_variables";

.eye_icon,
.clock_icon {
    margin: 0 5px;
}
.video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 304px;
  width: 540px;
  border-radius: 16px;
  margin: 20px 0 0;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }
  & > video {
    width: 100%;
    height: 100%;
  }
}
.playIcon {
  background: $white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
}

.news-page {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 170px 0;
  
  &.mobile {
    width: auto;
    padding: 0 16px;
  }
}

.news-page__video {
  display: flex;
  height: 304px;
  width: 540px;
  border-radius: 16px;
  margin: 20px 0 0;
}

.np-content {
  display: flex;
  flex-flow: column;
  width: 540px;
  margin: 20px 0 0 15px;
}

.np-content__heading {
  display: flex;
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
}

.np-content__data {
  display: flex;
  flex-flow: row wrap;
  margin: 5px 0 0;
}

.np-content__data--time,
.np-content__data--views {
  display: flex;
  color: $dark-grey;
  font-size: 16px;
  line-height: 19px;
}

.np-content__text {
  display: flex;
  margin: 15px 0 0;
  font-size: 16px;
  line-height: 19px;
  height: 60px;
  overflow: hidden;
}

.np-content__text--full{
  height: auto;
  overflow: visible;
}

.np-content__show-more{
  display: flex;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
}


</style>
