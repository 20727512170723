<template>
<div class="webinar-listen">
  <h2 class="webinar-listen__heading">{{item.name}}</h2>
  <div class="webinar-listen__wl-paragraph wl-paragraph" :class="{'wl-paragraph--playing': (isPlaying.chapterId === item.id && isPlaying.paragraphId === el.id)}" v-for="(el, key) in item.content" :key="key" @click="actionToggleIsPlaying({name: item.name, categoryId: categoryId, chapterId: item.id, paragraphId: el.id})">
    <h3 class="wl-paragraph__name">
      {{ numerateParagraphs ? (key+1)+'. ' : ''}}{{el.name}}
    </h3>
    <span class="wl-paragraph__length">{{el.length}}</span>
    <p class="wl-paragraph__text hidden">{{el.text}}</p>
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
export default {
  name: 'WebinarListen',
  props: {
    item: {
      type: Object,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },
    numerateParagraphs: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    ...mapActions({
      actionToggleIsPlaying: "localData/toggleIsPlaying"
    })
  },
  computed: {
    ...mapGetters({
      isPlaying: "localData/isPlaying"
    })
  }
}
</script>
