<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.picture">
            <img src="./assets/person2.png" />
        </div>
        <div :class="$style.content"><a name="main" />
            <div :class="$style.date">
            </div>
            <div v-if="!isMobile" :class="$style.whatWiilDo">
                Что делать, если<br> пропал секс?
            </div>
            <div v-if="isMobile" :class="$style.whatWiilDo">
                Что делать,<br>если пропал<br>секс?
            </div>
            <div v-if="!isMobile" :class="$style.description">
                Страсть уходит из любых долгих отношений. <br>
                Но это легко исправить, если знать, <br>
                как устроен секс.
            </div>
            <div v-if="isMobile" :class="$style.description">
                Страсть уходит из любых<br>
                долгих отношений. Но это<br>
                легко исправить, если знать,<br>
                как устроен секс.
            </div>
            <a href="#programm">
                <button :class="$style.button">
                    ПОДРОБНЕЕ
                </button>
            </a>
        </div>
        <div>
        </div>

    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        position: relative;
        display: flex;
        justify-content: space-between;

        &.mobile {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    .date {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #979797;
        margin-top: 112px;

        .mobile & {
            margin-top: 4px;
        }
    }

    .whatWiilDo {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 76px;
        color: #D75B3F;
        margin-top: 24px;
         .mobile & {
            margin-top: 4px;
            font-size: 30px;
            line-height: 34px;
        }
    }

    .description {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 43px;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;
        margin-top: 24px;

        .mobile & {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .button {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 20px;
        letter-spacing: 0.0557948px;
        text-transform: capitalize;

        color: #FFFFFF;

        background: #D75B3F;
        border: 1px solid #979797;
        border-radius: 12px;

        width: 350px;
        height: 58px;

        margin-top: 38px;

        .mobile & {
            width: 191px;
            height: 38px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .picture {
        position: absolute;
        z-index: 0;
        right: -57px;
        top: 2px;

        .mobile & {
            right: -82px;
            img {
                width: 250px;
                height: 349px;
            }
        }
    }

</style>