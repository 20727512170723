<template>
  <MainLayout>

	<div v-if="category" :class="[$style.category_page, isMobile ? $style.mobile : '' ]">
		<Breadcrumbs :links="[category]"></Breadcrumbs>
		
		<h1 :class="$style.heading">{{category.name}}</h1>
		<p :class="$style.caption">{{category.description}}</p>

		<WebinarSlider v-if="webinars.length" :category="category" :webinars="webinars"></WebinarSlider>
		
		<h2 v-if="courses.length" :class="$style.heading">Онлайн курсы</h2>
		<div v-if="courses.length" :class="$style.courses">
			<CourseCard :class="$style.item" v-for="(item, key) in courses" :key="key" :category="category" :course="item"></CourseCard>
		</div>
	</div>
  </MainLayout>
</template>

<script>
import {
  mapGetters
} from "vuex"
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import WebinarSlider from '@/components/WebinarSlider.vue'
import CourseCard from '@/components/CourseCard.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import { api } from '@/api/index.js'
import { vfirestore } from '@/db'

export default {
	name: 'CategoryPage',
	components: {
		Breadcrumbs,
		WebinarSlider,
		CourseCard,
		MainLayout
	},
	props: {
		itemId: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			webinars: [],
			courses: [],
		}
	},
	computed: {
		...mapGetters({
			categoryList: 'categories/list'
		}),
		category() {
			return this.categoryList.find( i => i.id === this.itemId)
		},
		isMobile() {
			// return true;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
		}
	},
	methods: {
		async apiMethods(name) {
			return await api[name](this.$route.params.itemId)
		},
		async initCategories(itemId) {
			let _itemId = itemId || this.itemId
			let webinarsRes = await vfirestore.collection('v').doc('1').collection('webinars').where('collection', '==', _itemId).where('hide', '==', false).get()
			this.webinars = webinarsRes.docs.length ? webinarsRes.docs.map( item => ({...item.data(), id: item.id}) ) : []
			let courseRes = await vfirestore.collection('v').doc('1').collection('courses').where('category', '==', _itemId).where('hide', '==', false).get()
			this.courses = courseRes.docs.length ? courseRes.docs.map( item => ({ id: item.id, ...item.data()}) ) : []

			this.webinars.sort((a,b) => {
				 if ( a.number < b.number ){
					return -1;
				}
				if ( a.number > b.number ){
					return 1;
				}
				return 0;
			})

			console.log(this.webinars)
		}
	},
	created() {
		this.initCategories()
  },
  watch: {
    '$route' (to, from) {
      this.initCategories(to.params.itemId);
    },
  }
}
</script>
<style lang="scss" module>
.category_page {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;

  &.mobile {
  	padding: 0 16px;
  }
}

.heading {
  display: flex;
  margin: 40px 0 10px;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
}

.caption {
  display: flex;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}

.courses {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.item {
  display: flex;
  margin: 0 20px 20px 0;
}

</style>