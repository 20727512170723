<template>
    <div v-if="item" :class="$style.card">
        <div :class="$style.avatar">
            <img :src="item.image" />
        </div>
        <div :class="$style.name">
            {{ item.name }}
        </div>
        <div :class="$style.description">
            {{ item.description }}
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AuthorCard',
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        }
        
    }
</script>
<style lang="scss" module>


.card {
    background: #F2F2F2;
    width: 228px;
    height: 119px;
    border-radius: 16px;
    padding: 16px;
    overflow: hidden;
}

.avatar {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 30px;

    img {
        width: 60px;
        height: 60px;
    }
}

.name {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    letter-spacing: 0.0662563px;

    color: #333333;

    margin-top: 8px;
}

.description {
    display: flex;
    height: 26px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0557948px;

    color: #999999;

    mix-blend-mode: normal;

    overflow: hidden;
    text-overflow: ellipsis;
   
}

</style>