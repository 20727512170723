<template>
<div class="webinar-read">
  <h2 class="webinar-read__heading">{{item.name}}</h2>
  <div class="webinar-read__wr-paragraph wr-paragraph" v-for="(el, key) in item.content" :key="key">
    <label class="wr-paragraph__name">
      {{ numerateParagraphs ? (key+1)+'. ' : ''}}{{el.name}}
      <input class="hidden" type="checkbox" name="openedParagraphs">
    </label>
    <p class="wr-paragraph__text hidden">{{el.text}}</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'WebinarRead',
  props: {
    item: {
      type: Object,
      required: true
    },
    numerateParagraphs: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
