<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.picture">
            <img src="./assets/person3.png" />
        </div>
        <div :class="$style.content">
            <a name="about" />
            <div :class="$style.title">
                Познакомимся?
            </div>
            <div style="align-self: flex-end;">
                <div v-if="!isMobile" style="height: 147px"></div>
                <div v-if="isMobile" style="height: 14px"></div>
                <div :class="[$style.about, isMobile ? $style.aboutMobile : '']">
                    Я - секс-блогер, секс-терапевт и популяризатор
                    науки. Рассказывая только основы секса, собрала
                    сотни тысяч подписчиков, десятки миллионов
                    просмотров в соцсетях
                </div>
            </div>
            <div v-if="!isMobile" style="height: 202px"></div>
            <div v-if="isMobile" style="height: 21px"></div>
            <div style="align-self: flex-start;" :class="$style.about">
                На себе пробовала все форматы отношений –
                от полиамории до длительного моногамного брака.
                <ul>
                    <li>Один муж</li>
                    <li>Сотни половых партнёров</li>
                    <li>Тысячи проведённых консультаций для пар</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        z-index: 1;

        .mobile & {
            align-items: center;
        }
    }

    .title {
        display: flex;
        align-self: flex-end;
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 76px;
        letter-spacing: 0.278974px;
        width: 50%;
        color: #D75B3F;

        .mobile & {
            font-size: 30px;
            line-height: 76px;   
            justify-content: center;
            width: 100%;
        }
    }

    .about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 36px 52px 36px 64px;

        background: linear-gradient(88.7deg, #77483D 2.22%, #333333 70.91%);
        border-radius: 12px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 38px;
        letter-spacing: 0.0976408px;

        color: #FFFFFF;
        word-break: break-all;

        .mobile & {
            padding: 11px 6px;
            font-size: 10px;
            line-height: 16px;
            background: linear-gradient(88.7deg, rgba(119, 72, 61, 0.7) 2.22%, rgba(51, 51, 51, 0.7) 70.91%);
        }
    }

    .aboutMobile {
        width: 208px;
    }

    .picture {
        position: absolute;
        z-index: 0;
        left: 0;
        top: -69px;

        .mobile & {
            top: 40px;
            left: -40px;
            img {
                width: 180px;
                height: 240px;
            }
        }
    }

</style>