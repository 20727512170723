<template>
    <div :class="[$style.main,  isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Иммунитет против Вирусов
            </div>
            <div :class="$style.subtitle">
                Вирус можно победить! Инструкции и советы доктора медицинских наук.
            </div>
            <!-- <div :class="$style.checklistTitle">
                На вебинаре вы узнаете:
            </div> -->
            <div :class="$style.listItem"><img :src="require('./ellipse.png')">Почему вирусы так опасны?</div>
            <div :class="$style.listItem"><img :src="require('./ellipse.png')">Почему иммунитет сильнее любого лекарства?</div>
            <div :class="$style.listItem"><img :src="require('./ellipse.png')">Как не получить осложнений, если уже заболел?</div>
            <div :class="$style.listItem"><img :src="require('./ellipse.png')">Как выжить, если попал в больницу?</div>
            <a href="#part7" :class="$style.registrationButton">
                Купить вебинар
            </a>
            <div :class="$style.photo">
                <img :src="require('./denisov.png')"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
.main {
    position: relative;
    background: #EEF6FE;
    width: 100%;
    height: 633px;
    padding-top: 100px;
    color: #333333;

    &.mobile {
        height: auto;
    }

}
.photo {
    position: absolute;
    right: 0;
    bottom: 0;

    .mobile & {
        position: relative;
        align-self: center;
        width: 100%;
        margin-top: 16px;

        img {
            width: 100%;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 633px;
    max-width: 1099px;
    margin: auto;
    
    .mobile & {
        height: auto;
        align-items: flex-start;
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;

    }
}
.title {
    font-family: 'NoirPro-Bold';
    font-size: 44px;
    line-height: 84px;
    font-weight: bold;
    letter-spacing: 0.278974px;

    .mobile & {
        line-height: 36px;
        font-size: 32px;
    }
}
.subtitle {
    font-family: NoirPro-Regular;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    
    .mobile & {
        margin-top: 12px;
    }

}
.checklistTitle {
    font-family: NoirPro-Medium;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.0976408px;
    margin-top: 32px;

}

.registrationButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 394px;
    height: 58px;
    margin-top: 40px;
    background: linear-gradient(91.12deg, #0065E3 0%, #5ECFFF 100%);
    border-radius: 12px;
    font-family: NoirPro-Medium;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.0557948px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    color: #FFFFFF;

    .mobile & {
        width: 100%;
        font-size: 16px;
        line-height: 19px;
    }
}
.listItem {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #333333;
    margin-top: 12px;

    img {
        margin-right: 12px;
    }

    .mobile & {
        display: flex;
        align-items: flex-start;
    }
}
</style>