<template>
	<div class="webinar-slider">
		<h2 class="webinar-slider__heading">Вебинары</h2>

		<router-link class="webinar-slider__all" :to="category.id+'/webinars'">Смотреть все</router-link>

		<h2 class="webinar-slider__empty" v-show="!webinars.length">Нет подходящих вебинаров.</h2>
		
		<Slider>
			<WebinarCard v-for="(item, key) in webinars" :key="key" class="ws-slides__item" :category="category" :webinar="item" />
		</Slider>
	</div>
</template>

<script>
import WebinarCard from '@/components/WebinarCard.vue'
import Slider from '@/components/Slider'

export default {
	name: "WebinarSlider",
	components: { WebinarCard, Slider },
	props: {
		category: { type: Object, required: false },
		webinars: {type: Array, default:() => [], required: true}
	},
	data() {
		return { 
		}
	},
	methods: {
	},
	computed: {
	},
	async mounted() {
		
	},
}
</script>
