<template>
     <MainLayout>
        <div :class="isMobile ? $style.m_authors : $style.authors">
            <template v-if="isLoading">
                <div :class="$style.loading">
                    <Loading />
                </div>
            </template>
            <template v-else>
                <Breadcrumbs :links="[list]"></Breadcrumbs>
                <h1 :class="$style.heading">Авторы</h1>
                <div :class="isMobile ?  $style.m_list : $style.list">
                    <AuthorCard v-for="(item, key) in list" :class="$style.item" :key="key" :item="item" />
                </div>
            </template>
        </div>
  </MainLayout>
</template>
<script>
import MainLayout from '../components/layouts/MainLayout.vue'
import AuthorCard from '../components/AuthorCard.vue'
import { vFunctions } from '@/db.js'
export default {
  components: { MainLayout, AuthorCard },
  data() {
      return {
          isLoading: false,
          list: []
      }
  },
  computed: {
		isMobile() {
			// return true;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
		}
  },
  async mounted() {
    let res = await vFunctions.httpsCallable('getAuthors')();
    this.list = res.data
  }
    
}
</script>

<style lang="scss" module>
.authors {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;
}

.m_authors {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 0 16px;
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.item {
    margin-right: 20px;
    margin-bottom: 20px;
}

.loading {

}
</style>