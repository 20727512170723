<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Нужно пойти на вебинар. чтобы
            </div>
            <div :class="$style.reasons">
                <ReasonTile :index="0" text="Знать эффективные способы профилактики вирусных заболеваний"/>
                <ReasonTile :index="1" text="Как не разориться на лекарствах и укрепить здоровье"/>
                <ReasonTile :index="2" text="Что делать если Вы заболели COVID-19?"/> 
                <ReasonTile :index="3" text="Когда закончится пандемия и мы начнём жить прежней жизнью"/>
                <ReasonTile :index="4" text="Восстановление после COVID 19"/>
                <ReasonTile :index="5" text="Перестать бояться и жить полноценной, яркой, счастливой жизнью"/>
            </div>
        </div>
    </div>
</template>
<script>
import ReasonTile from './ReasonTile'
export default {
    components: {
        ReasonTile
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>
    .main {
        width: 100%;
    }

    .content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            width: calc(100% - 32px);
            padding: 40px 16px;
        }
    }

    .reasons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

       .title {
        font-size: 34px;
        line-height: 84px;
        font-weight: bold;
        letter-spacing: 0.278974px;

        color: #333333;

        .mobile & {
            font-size: 24px;
            line-height: 28px;
        }
    }
</style>