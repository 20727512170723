<template>
  <MainLayout>

<div :class="[ $style['item-page'], isMobile ? $style.mobile : '']">
  <template v-if="isLoading">
    <div :class="$style.loading">
      <Loading />
    </div>
  </template>
  <template v-else>
    <Breadcrumbs :links="[item]"></Breadcrumbs>
    <img :class="$style['item-page__photo']" :src="item.image">
    <div :class="[$style['item-page__ip-content'], $style['ip-content']]">
      <h1 :class="$style['ip-content__heading']">{{item.name}}</h1>
      <p :class="$style['ip-content__price']">{{ item.price / 100 }} ₽</p>
      <a :class="$style['ip-content__button']" href="" v-show="!isInCart" @click.prevent="addToCartWrapper()">Купить</a>
      <a :class="[$style['ip-content__button'], $style['ip-content__button--in-cart']]" href="" v-show="isInCart" @click.prevent="actionToggleCartVisibility(true);  actionToggleCartVisiblePage('cart')"><span>Товар в корзине</span>Перейти в корзину</a>
      <p :class="{'ip-content__description--full': showMore, 'ip-content__description': true}">{{item.description}}</p>
      <!-- <a class="ip-content__show-more" href="" @click.prevent="showMore=true" v-show="!showMore">Показать полностью...</a> -->
    </div>
  </template>
</div>
  </MainLayout>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Loading from '@/components/Loading'
import MainLayout from '@/components/layouts/MainLayout.vue'
import { vfirestore } from '@/db.js'
import {
  mapState,
  mapActions,
  mapGetters
} from "vuex"
export default {
  name: 'Item',
  components: {
    Breadcrumbs,
    Loading,
    MainLayout
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      item: {},
      showMore: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      router: function() {
        return this.$router;
      },
      route: function() {
        return this.$route;
      }
    }),
    ...mapGetters({
      cart: "cart/list",
      items: "serverData/items",
      listCartItem: 'cart/list'
    }),
    isInCart() {
      return this.cart.find(el => el.id === this.item.id)
    },
    imageSrc: function() {
      return require('@/assets/img/' + this.item.image)
    },
    itemId: function() {
      return this.route.params.itemId
    },
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    }
  },
  methods: {
    ...mapActions({
      actionAddItemToCart: "localData/addItemToCart",
      actionToggleCartVisibility: "localData/toggleCartVisibility",
      actionToggleCartVisiblePage: "localData/toggleCartVisiblePage",
      addToCart: 'cart/addToCart'
    }),
    addToCartWrapper() { console.log(this.item)
      this.addToCart({ count: 1, size: 'm', ...this.item })
    },
    removeFromCartWrapper() {

    }

  },
  async created() {
    this.isLoading = true;
    let res = await vfirestore.collection('v').doc('1').collection('products').doc(this.id).get();
    this.item = {...res.data(), id: res.id}
    this.isLoading = false;

    if (!this.item) this.router.push(`/error`)
  }
}
</script>
<style lang="scss" module>
@import "@/styles/_variables";

.loading {
  display: flex;
  justify-content: center;
  width: 100%;
}

.item-page {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 170px;

  &.mobile {
    width: auto;
    padding: 0 16px;
  }
}

.item-page__photo {
  display: flex;
  height: 365px;
  width: 240px;
  margin: 20px 0 80px;
  .mobile & {
    margin: auto;
    margin-top: 16px;
  }
}

.ip-content {
  display: flex;
  flex-flow: column;
  width: 540px;
  margin: 20px 0 0 40px;
  .mobile & {
    width: auto;
    margin: 0;
    margin-top: 20px;
  }
}

.ip-content__heading {
  display: flex;
  margin: 50px 0 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  .mobile & {
    margin: 0;
  }
}

.ip-content__price {
  display: flex;
  margin: 10px 0 0;
  color: $dark-grey;
  font-size: 16px;
  line-height: 19px;
}

.ip-content__description {
  display: flex;
  margin: 15px 0 0;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
}

.ip-content__description--full {
  height: auto;
  overflow: visible;
}

.ip-content__show-more {
  display: flex;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
}


.ip-content__button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 200px;
  height: 48px;
  margin: 22px 0 0;
  padding: 0;
  background: $blue;
  color: $white;
  font-size: 16px;
  line-height: 19px;
  border-radius: 12px;
  border: none;

  .mobile & {
    width: auto;
  }
}

.ip-content__button--in-cart {
  background: $light-grey;
  position: relative;
  padding: 14px 0 0;
  color: $blue;
  font-weight: 500;
  text-align: center;

  & span {
    position: absolute;
    top: 8px;
    width: inherit;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

</style>
