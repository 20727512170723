<template>
  <MainLayout>

<div :class="[ $style.items, isMobile ? $style.mobile : '']">
  <Breadcrumbs :links="list"></Breadcrumbs>
  <h1 :class="$style['items__heading']">Все товары</h1>
  <div :class="[$style['items__items-list'], $style['items-list']]">
    <ItemCard :class="$style['items-list__item']" v-for="(item, key) in list" :key="key" :item="item" />
  </div>
</div>
  </MainLayout>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ItemCard from '@/components/ItemCard.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import { vfirestore } from '@/db.js'
import { mapGetters } from 'vuex'
export default {
  name: "Items",
  components: {
    ItemCard,
    Breadcrumbs,
    MainLayout
  },
  computed: {
    ...mapGetters({
      list: 'products/list'
    }),
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    }
  },
}
</script>
<style lang="scss" module>
.items {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;

  &.mobile {
    width: auto;
    padding: 0 16px;  
  }
}

.items__heading {
  display: flex;
  margin: 10px 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
}

.items-list {
  display: flex;
  flex-flow: row wrap;
}

.items-list__item {
  display: flex;
  margin: 0 20px 20px 0;

  &:last-child {
    margin-bottom: 110px;
  }
}

</style>