import { vAuth, functions } from '@/db.js'
import firebase from 'firebase/app'
import { vfirestore } from '../../db';
import { getAuth } from "firebase/auth";

export default {
    async loginWidthGoogle({commit}) {
        try {
            var provider = new firebase.auth.GoogleAuthProvider();
            let res = await vAuth.signInWithPopup(provider);
            let roleResults = await vfirestore.collection('v').doc('1').collection('rules').where('uid', '==', res.user.uid).get();
            let role;
            if (roleResults.docs.length) {
                console.log('1')
                role = roleResults.docs[0].data()
                commit('setUser', { ...res.user, role: role });
            } else {
                console.log('2')
                throw new Error('Нет прав для входа');
                // await vAuth.signOut();
            }
        } catch(e) {
            return e
        }
    },
    async initUser({commit}) {
        let auth = getAuth()
        if (auth.currentUser) {
            commit('setUser', auth.currentUser );
        }
    },
    async logout({commit}) {
        await vAuth.signOut();
        commit('setUser',  {});
    }
}