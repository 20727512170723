<template>
    <div :class="[$style.tile,  isMobile ? $style.mobile : '']">
        <img :src="[
                require('./assets/stress1.png'),
                require('./assets/stress2.png'),
                require('./assets/stress3.png'),
            ][img]"/>
        <div :class="$style.tileText">
            {{text}}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        img: {
            type: Number
        },
        text: {
            type: String
        }
    },
    computed: {
        isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>

    .tile {
        margin-top: 20px;
        // padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 280px;
        width: 30%;

        img {
            width: 180px;
            height: 180px;
        }

        &.mobile {
            padding: 8px;
            justify-content: flex-start;
            width: auto;
            height: auto;
            img {
                width: 60px;
                height: 60px;
            }
        }
    }
    .tileText {
        font-weight: bold;
        font-size: 28px;
        line-height: 30px;
        color: #41757C;

        .mobile & {
            text-align: center;
            font-size: 12px;
            line-height: 14px;
        }
    }
</style>