<template>
<div class="order">
  <a class="personal-data-back" href="" @click.prevent="actionTogglePersonalDataVisiblePage()" v-show="source==='personal'"></a>
  <h2 class="order__heading">Заказ #{{orderData.number}}</h2>
  <div class="order__o-state o-state" :class="{'o-state--canceled': isCanceled}">
    <h3 class="o-state__heading" v-show="orderData.status == 1">Заказ оплачен</h3>
    <h3 class="o-state__heading" v-show="isCanceled">Заказ отменен</h3>
    <h3 class="o-state__heading" v-show="isDelivered">Заказ доставлен</h3>
    <p class="o-state__date" v-show="!isCanceled">Доставка к {{orderData.deliveryDate}}</p>
  </div>


  <label class="order__label">
    <span class="order__caption">Адрес</span>
    <input class="order__input" type="text" :value="orderData.adress" disabled>
  </label>
  <label class="order__label">
    <span class="order__caption">Доставка</span>
    <input class="order__input" type="text" :value="delivery.find(el => el.id === orderData.delivery) ? delivery.find(el => el.id === orderData.delivery).label : ''" disabled>
  </label>

  <div class="order__o-items o-items">
    <OrderItem class="o-items__item" v-for="(cartItem, key) in items" :cartItem="cartItem" :key="key"></OrderItem>
  </div>

  <ul class="order__o-prices o-prices">

    <li class="o-prices__item">Доставка: <span>299</span></li>
    <li class="o-prices__item">Товары: <span>{{itemsPrice | price }}</span></li>
    <!-- <li class="o-prices__item">Промокод: <span>-{{orderData.promocodePrice}}</span></li> -->
    <li class="o-prices__item">Итого: <span class="o-prices__item--summary">{{summaryPrice | price}}</span></li>
  </ul>
  <div class="order__o-buttons">
    <button class="o-buttons__button" v-show="source === 'cart'" type="submit" @click.prevent="actionToggleCartVisiblePage('help')">  </button>
    <button class="o-buttons__button" v-show="source === 'personal'" type="submit" @click.prevent="actionTogglePersonalDataVisiblePage('help')">Помощь</button>
    <button class="o-buttons__button" type="submit" @click.prevent="orderData.status = 'canceled'" v-show="isProcessed">Отменить заказ</button>
  </div>
</div>
</template>
<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import OrderItem from '@/components/ModalComponent/OrderItem.vue'
export default {
  name: 'Order',
  props: {
    source: {
      type: String,
    },
    orderData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    OrderItem
  },
  methods: {
    ...mapActions({
      actionToggleCartVisiblePage: 'localData/toggleCartVisiblePage',
      actionTogglePersonalDataVisiblePage: 'localData/togglePersonalDataVisiblePage',
    }),
  },
  computed: {
    ...mapGetters({
      cart: 'localData/cart',
      cartVisibility: 'localData/cartVisibility',
      cartVisiblePage: 'localData/cartVisiblePage',
      buyingDigitalItem: 'localData/buyingDigitalItem',
      promocode: 'serverData/promocode',
      delivery: 'serverData/delivery',
    }),
    promocodePrice: function() {
      return this.promocode && this.promocode.price ? this.promocode.price : 0
    },
    deliveryPrice: function() {
      return 29900
    },
    itemsPrice: function() {
      let ip = 0
      this.cart.forEach(el => (ip += el.amount * this.items.find(item => item.id === el.id).price))
      return ip
    },
    itemsReducedPrice: function() {
      let ip = 0
      this.cart.forEach(el => (ip += el.amount * (this.items.find(item => item.id === el.id).price - this.promocodePrice)))
      return ip
    },
    summaryPrice: function() {
      return this.itemsPrice + 29900
    },
    isCanceled: function() {
      return this.orderData.status === 'canceled'
    },
    isShipped: function() {
      return this.orderData.status === 'shipped'
    },
    isProcessed: function() {
      return this.orderData.status === 'processed'
    },
    isDelivered: function() {
      return this.orderData.status === 'delivered'
    },
    items() {
      return this.orderData.cart ?  JSON.parse(this.orderData.cart) : []
    }
  }
}
</script>
