<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            
            <div :class="$style.listWrapper">
                <div v-if="!isMobile">
                    <img :src="require('./assets/ivanov.png')" />
                </div>
                <div >
                    <div :class="$style.title">
                        АВТОР ВЕБИНАРА
                    </div>
                    <div :class="$style.list">
                        <div v-if="isMobile"  :class="$style.listImage" >
                            <br>
                            <img :src="require('./assets/ivanov3.png')" />
                        </div>
                        <div :class="$style.listItem">
                            Доктор медицинских наук
                        </div>


                        <div :class="$style.listItem">
                            Профессор кафедры “Общая патология”
                        </div>


                        <div :class="$style.listItem">
                            Член-корреспондент Российской Академии 
                            Естествознания (РАЕ)
                        </div>


                        <div :class="$style.listItem">
                            Член-корреспондент Академии Медико-Технических 
                            Наук (АМТН)
                        </div>


                        <div :class="$style.listItem">
                            Академик Европейской Академии Естественных Наук (ЕАЕН)
                        </div>


                        <div :class="$style.listItem">
                            Заслуженный деятель науки и образования
                        </div>
                        <div :class="$style.listItem">
                            Почетный изобретатель Академии Медико-Технических 
                            Наук (АМТН)
                        </div>
                        <div :class="$style.listItem">
                            Специалист категории “А” в КНР
                        </div>
                        <div :class="$style.listItem">
                            Автор книги «ОчеCOVIDный обман. Афера на здоровье»
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isPlay: false
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
        screenWidth() {
            return this.isMobile ? (window.screen.width - 32) : 549 
        }
    }
}
</script>
<style lang="scss" module>

    .main {
        display: flex;
        justify-content: center;
        background: linear-gradient(180deg, #9FD7DE 0%, rgba(232, 232, 232, 0) 76.04%);
        width: 100%;
        // padding-left: calc((100% - 1099px) / 2);
        // padding-right: calc((100% - 1099px) / 2);
        
        &.mobile {
            width: calc(100% - 32px);
            padding: 0 16px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1099px;
        padding: 111px 0 0 0;

        .mobile & {
            flex-wrap: wrap;
            padding: 40px 0;
        }
    }

    .title {
        margin-bottom: 84px;
        font-family: Montserrat-Bold;
        font-style: normal;
        font-weight: 800;
        font-size: 42px;
        line-height: 64px;
        color: #41757C;

        .mobile & {
            font-size: 12px;
            line-height: 12px;
            align-self: center;
            text-align: center;
            margin-bottom: 13px;
        }
    }

    .avatar {
        display: flex;
        align-items: center;
        font-style: normal;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.0976408px;
        color: #333333;

        img {
            width: 80;
            height: 80;
            border-radius: 40px;
            margin-right: 20px;
        }
    }

.listItem {
    display: flex;
    margin-top: 12px;
    font-family: Montserrat-Bold;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #1F3234;

    .mobile & {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        font-size: 12px;
        line-height: 14px;
    }
}
.circle {
    margin-right: 12px; 
}
.list {
    width: 677px;
    .mobile & {
       width: 100%;
    }
}
.video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 549px;
    height: 446px;

    background: #F1F5F9;
    border-radius: 24px;

    .mobile & {
        width: 100%;
        height: 266px;
    }

    &.videoPreview {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/montyrey-7ce68.appspot.com/o/webinars%2Fimages%2F%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202021-12-01%20%D0%B2%2007.47.34.png?alt=media&token=abf63ad1-d3bd-4b9c-9157-8d2063c7cca2");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.videoWrapper { 
    .mobile & {
        width: 100%;
        margin-top: 32px;
    }
}
.listWrapper {
    display: flex;
    justify-content: center;
    margin: auto;
}

.listImage {
    float: left;
    width: 120px;
    height: 120px;
    margin-bottom: 16px;

    img {
        width: 120px;
        height: 120px;
    }
}
</style>