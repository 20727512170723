<template>
  <div>
    <MainLayout>
      <div :class="[$style['webinar-page'], isMobile ? $style.mobile : '']">

      <template v-if="isLoading">
        <div :class="$style.loader">
          <Loading />
        </div>
      </template>
      <Breadcrumbs v-if="item && category" :links="[category, item]" name="name"></Breadcrumbs>

      <div :class="isMobile ? '' : $style.contentWrapper">
          <div :class="$style.video"> 
          <div v-if="!showVideo" :class="$style.videoPreview" :style="{'background-image': `url(${item.image})`}" ></div>
          <video  v-if="showVideo && item.link && paid"  controls="true">
            <source :src="item.link"/>
          </video>
          <video  v-if="showVideo && item.trailer && !paid"  controls="true">
            <source :src="item.trailer"/>
          </video>
          <!-- <vimeo-player v-if="showVideo && item.vimeoLink" ref="video" player-width="520" player-height="284" :video-url='item.vimeoLink'></vimeo-player> -->
      <!-- 
          <video v-if="item.link" ref="video" width="540" height="304" controls="controls" :poster="item.image">
              <source :src="item.link">
          </video> -->
          <div v-if="!showVideo" :class="$style.playIcon" @click="playVideo">
              <img src="../assets/img/play.svg" alt="">
          </div>
          <!-- <IconPlay :class="$style.playIcon"/> -->
      </div>
      <div :class="[$style['wp-content']]">
        <h1 :class="$style['wp-content__heading']">{{item.fullName || item.name}}</h1>
        <p :class="$style['wp-content__date']">{{createDate}}</p>
        <div v-if="item && item.author" :class="[$style['wp-content__wp-author'], $style['wp-author']]">
          <img :class="$style['wp-author__image']" alt="Аватар" :src="item.author.image">
          <div :class="$style['wp-author__text']">
            <p :class="$style['wp-author__name']">{{item.author.name}}</p>
            <p v-if="item.productId == 'kuznetsovaSex' && showVideo && item.link && paid" :class="$style['wp-author__description']"><a href="https://firebasestorage.googleapis.com/v0/b/montyrey-7ce68.appspot.com/o/v%2F1%2Fdocs%2F%D0%A1%D0%B5%D0%BA%D1%81%20%D1%81%D1%87%D0%B0%D1%81%D1%82%D0%BB%D0%B8%D0%B2%D0%BE%D0%B3%D0%BE%20%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0_compressed.pdf?alt=media&token=365c27ae-823d-42ec-a3f1-ac5dba34d0b9">Презентация вебинара</a></p>
            <p></p>
            <p :class="$style['wp-author__description']" v-html="item.description"></p>
          </div>
        </div>
        <p :class="{'wp-content__text--full': showMore, 'wp-content__text': true}">{{item.text}}</p>
        <!-- <a class="wp-content__show-more" href="" @click.prevent="showMore=true" v-show="!showMore">Показать полностью...</a> -->
        <a :class="$style['wp-content__button']" v-if="!paid" href="" @click.prevent="buyCourseWrapper">Купить вебинар <br> за {{ item.price * 100 | price }} </a>
      </div>
      </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import { vfirestore } from '@/db'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import moment from 'moment'
import Loading from '@/components/Loading.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import IvanovLanding from './Denisov/index.vue'
import KuznetsovaLanding from './Sex/index.vue'
import IvanovStressLanding from './Stress/index.vue'
import {pay} from '@/commons/pay.js'
import {
  mapState,
  mapActions,
  mapGetters
} from "vuex"
export default {
  name: 'WebinarPage',
  components: {
    Breadcrumbs,
    Loading,
    MainLayout,
    IvanovLanding,
    IvanovStressLanding,
    KuznetsovaLanding
  },
  props: {
    webinarId: {
      type: String,
      default: ''
    },
    itemId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      item: false,
      showMore: false,
      showVideo: false,
      isLoading: false,
      paid: false,
      toAuth: false,
    }
  },
  computed: {
    ...mapState({
      router: function() {
        return this.$router;
      },
      route: function() {
        return this.$route;
      }
    }),
    ...mapGetters({
      cartVisiblePage: 'localData/cartVisiblePage',
      webinars: "serverData/webinars",
      categories: "serverData/categories",
      isAuth: 'user/isAuth',
      user: 'user/user',
			categoryList: 'categories/list'
    }),
    category() {
      return this.categoryList.find( i => i.id === this.itemId);
    },
    // category: function() {
    //   return this.categories.find(el => el.id === this.item.categoryId)
    // },
    backgroundStyle: function() {
      //video bg placeholder
      return {
        background: `url('${require('@/assets/img/'+this.item.image)}') no-repeat` || '#999'
      }
    },
    // imageSrc: function() {
    //   return require('@/assets/img/' + this.item.author.image)
    // }
    createDate() {
      return this.item ? 'Дата: ' + moment(this.item.date.seconds * 1000).format('MM.DD.YYYY') : ''
    },
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
    ref() {
        return this.$route.query ? this.$route.query.ref : '';
    },
  },
  methods: {
    ...mapActions({
      actionAddItemToCart: "localData/addItemToCart",
      actionToggleCartVisibility: "localData/toggleCartVisibility",
      actionToggleCartVisiblePage: "localData/toggleCartVisiblePage",
      actionSetBuyingDigitalItem: "localData/setBuyingDigitalItem",
      actionToggleWebinarIsBought: "serverData/toggleWebinarIsBought",
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",
    }),

    buyCourseWrapper() {
      if (this.isAuth) {
        this.buyCourse();
      } else {
        this.actionTogglePersonalDataVisibility(true);
        this.toAuth = true;
      }
    },
    async buyCourse() {
      try {
        this.isLoading = true;
        await pay(this.item.price, this.user.uid, this.item.productId, this.user.email, 'webinar', this.ref, this.user.displayName, this.category.name, this.item.name)
      } finally {
        setTimeout(() => {
          this.isLoading = false;
          this.initWebinar();
        }, 5000)
      }
     
      // this.actionToggleCourseIsBought({
      //   categoryId: this.category.id,
      //   webinarId: this.item.id,
      //   name: this.item.name,
      //   link: '/categories/webinars/' + this.category.link + '/' + this.item.link,
      //   date: "31.05.2021",
      //   productId: this.item.productId,
      //   price: this.item.price
      // })
    },
    playVideo() {
      if (!this.paid && this.item.trailer) this.showVideo = true;
      if (!this.paid) return;
      this.showVideo = true;
      // this.$nextTick(() => {
      //   this.$refs['video'].play()
      // });
    },
    async initWebinar(webinarId) {
      this.isLoading = true
      let _webinarId = webinarId || this.webinarId;
      let res = await vfirestore.collection('v').doc('1').collection('webinars').doc(_webinarId).get();
      this.item = { ...res.data(), ...{id: res.id }};
    
      if (this.isAuth) {
        let orders = ( await vfirestore.collection('v').doc('1').collection('orders').where('productId', '==', this.item.productId).where('status', '==', 1).where('uid', '==', this.user.uid).get() ).docs
        this.paid = orders.length ? true : false
        
        if (!this.paid) {
            orders = ( await vfirestore.collection('v').doc('1').collection('orders').where('productId', '==', this.item.productId).where('status', '==', 1).where('email', '==', this.user.email).get() ).docs
            this.paid = orders.length ? true : false
            console.log(this.paid)
        }
      }

      if (location.href.includes('asdafa453sdfhj78435bnjsdguf7')) {
        this.paid = true;
      }

      this.isLoading = false

      if (this.toAuth)  {
        this.actionTogglePersonalDataVisibility(false);
        this.toAuth = false;
        if (!this.paid) this.buyCourse();
      }
      
      if (!this.item) return this.router.push(`/error`)
    },

    async pay() {
        await pay(this.item.price, this.user.uid, this.item.productId, this.user.email, "webinar", this.ref, this.user.displayName);
        this.initWebinar();
    },
  },
  mounted() {
    this.initWebinar();
  },
  watch: {
    '$route' (to, from) {
      this.initWebinar(to.params.webinarId);
    },
    isAuth() {
      this.initWebinar();
    },
    cartVisiblePage() {
      if (this.cartVisiblePage == 'done') this.initWebinar()
    }
  }
}
</script>

<style lang="scss" module> 
@import "@/styles/_variables";


.video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 304px;
  width: 540px;
  border-radius: 16px;
  margin: 20px 0 0;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }
  & > video {
    width: 100%;
    height: 100%;
  }

  .mobile & {
    height: auto;
    width: 100%;
    min-height: 304px;
  }
}

.playIcon {
  background: $white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
}

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.webinar-page {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 170px;

  &.mobile {
    width: auto;
    padding: 0 16px;
  }
}

.webinar-page__video {
  position: relative;
  display: flex;
  height: 304px;
  width: 540px;
  border-radius: 16px;
  margin: 20px 0 0;

  & video{
    width: 100%;
    height: 100%;
  }
}

.webinar-page__video--online::before{
  content: 'Онлайн';
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  box-sizing: border-box;
  padding: 4px;
  color: $white;
  font-size: 13px;
  line-height: 15px;
  background: linear-gradient(260.43deg, #B455FF 0%, #FF3636 100%);
  border-radius: 8px;
}

.wp-content {
  display: flex;
  flex-flow: column;
  width: 540px; 
  margin: 20px 0 100px 15px;

  .mobile & {
    width: auto;
  }
}

.wp-content__heading {
  display: flex;
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;

  .mobile & {
    margin-top: 16px;
  }
}

.wp-content__date {
  display: flex;
  color: $dark-grey;
  font-size: 16px;
  line-height: 19px;
}

.wp-content__text {
  display: flex;
  margin: 15px 0 0;
  font-size: 16px;
  line-height: 19px;
  height: 60px;
  overflow: hidden;
}

.wp-content__text--full {
  height: auto;
  overflow: visible;
}

.wp-content__show-more {
  display: flex;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
}

.wp-author {
  display: flex;
  flex-flow: row;
}

.wp-author__image {
  display: flex;
  width: 48px;
  height: 48px;
  margin: 0 12px 0 0;
  border-radius: 50px;
}

.wp-author__text {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.wp-author__name {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

.wp-author__description {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: $dark-grey;
}

.wp-content__button {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 190px;
  margin: 20px 0 0;
  padding: 15px 0;
  background: $blue;
  color: $white;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: none;
  border-radius: 12px;
}

.contentWrapper {
  display: flex;
  // justify-content: space-between;
}

.videoPreview {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 304px;
}

</style>