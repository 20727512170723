<template>
<div class="help">
  <h3 class="help__sub-heading">Часто задаваемые вопросы</h3>
  <ul class="help__h-content h-content">
    <li class="h-content__h-item h-item" v-for="(el, key) in help" :key="key">
      <label class="h-item__name">
        {{el.name}}
        <input class="hidden" type="checkbox" name="openedHelpTabs" :value="key" v-model="openedHelpTabs[key]">
        <!-- <ListTick class="h-tick" :class="{'h-tick--rotated': openedHelpTabs[key]}"/> -->
      </label>
      <p class="h-item__text" :class="{'hidden': !openedHelpTabs[key]}">{{el.text}}</p>
    </li>
  </ul>
  <a class="help__contact">Написать на почту</a>
</div>
</template>

<script>
// import ListTick from '@/assets/img/right-arrow.svg'
import {
  mapGetters
} from 'vuex'
export default {
  name: 'Help',
  components: {
    // ListTick
  },
  data() {
    return {
      openedHelpTabs: []
    }
  },
  computed: {
    ...mapGetters({
      help: "serverData/help"
    })
  }
}
</script>
