<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.header">
             Тебе это будет полезно, если
            </div>
            <div :class="$style.advices">
                <div :class="$style.advice">
                    <div :class="$style.text">
                        Ты в долгих отношениях,<br>
                        из которых ушла<br v-if="isMobile">
                        страсть
                    </div>
                    <img :src="require('./assets/advice1.png')"/>
                </div>
                <div :class="$style.devider">
                </div>
                <div :class="$style.advice">
                    <div :class="$style.text">
                        Ты любишь секс и не готов/а<br v-if="!isMobile"> от него отказываться
                    </div>
                    <img :src="require('./assets/advice2.png')"/>
                </div>
                <div :class="$style.devider">
                </div>
                <div :class="$style.advice">
                    <div :class="$style.text">
                        Ты хочешь сохранить<br>
                        отношения и сделать<br>
                        их счастливыми
                    </div>
                    <img :src="require('./assets/advice3.png')"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content {
        display: flex;
        flex-direction: column;
    }

    .header {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 84px;
        letter-spacing: 0.278974px;
        color: #FFFFFF;
        z-index: 1;

        .mobile & {
            font-size: 17px;
            line-height: 84px;
        }
    }

    .advices {
        display: flex;
        
        .mobile & {
            flex-direction: column;
        }
    }
    
    .advice {
        position: relative;
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 353px;
        height: 228px;
        background: linear-gradient(88.7deg, #C55E46 2.22%, #333333 79.34%);
        border-radius: 12px;
        padding: 20px 0 0 20px;

        & > img {
            margin-left: 28px;
            width: 300px;
            height: 300px;
        }

        .mobile & {
            width: calc(100% - 40px);
            height: 70px;
            margin-bottom: 40px;
            padding: 20px 20px;
            & > img {
                position: absolute;
                right: 16px;
                bottom: -25px;
                width: 80px;
                height: 80px;
                border-radius: 40px;
            }
        }

    }
    .devider {
        width: 20px;
    }

    .text {
        display: flex;
        justify-content: flex-start;
        height: 50%;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;
        
        .mobile & {
            height: auto;
        }
    }

</style>