<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <template v-if="!isMobile">
            <div :class="$style.headerTitle">
                Авторский вебинар Елизаветы Кузнецовой
            </div>
            <div :class="$style.menu">
                <div v-for="(item, index) in menuItems" :key="item.id" :class="[$style.menuItem, index != menuItems.length - 1 ? $style.deivider : '' ]">
                    <a :href="item.href">{{ item.title }}</a>
                </div>
            </div>
        </template>
        <template v-else>
            <div :class="[$style.main, $style.mobile]">
                <div :class="$style.headerTitle">
                    Авторский вебинар<br>Елизаветы Кузнецовой
                </div>
                <div :class="$style.menuButton" @click="isOpen = !isOpen">
                    <img src="./assets/burger.svg" />
                </div>
            </div>
            <div :class="[$style.sideMenu,  isOpen ? $style.open : '']">
                <div :class="$style.close" @click="isOpen = false">
                    <img src="./assets/exit.svg" />
                </div>
                <div :class="$style.menuItem" v-for="item in menuItems" :key="item.id" @click="isOpen = false">
                    <a :href="item.href">{{ item.title }}</a>
                </div>

                <div :class="$style.contacts">
                    <div :class="$style.author">
                        Авторский вебинар<br>Елизаветы Кузнецовой
                    </div>
                    <div :class="$style.email">
                        support@mentalmarket.ru
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isOpen: false,
            menuItems: [
                {
                    id: '1',
                    title: 'Главная',
                    href: '#main'
                },
                {
                    id: '2',
                    title: 'Программа',
                    href: '#programm'
                },
                {
                    id: '3',
                    title: 'Об авторе',
                    href: '#about'
                },
                {
                    id: '4',
                    title: 'Важно',
                    href: '#important'
                }
            ]
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style module lang="scss">
    .main {
        display: flex;
        position: fixed;
        z-index: 10;
        background: #000;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        width: calc(100% - 200px);
        padding: 0 100px;
        &.mobile {
            padding: 0 46px 0 23px;
            width: calc(100% - 69px);
        }
    }

    .headerTitle {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;

         &.mobile {
            font-size: 12px;
            line-height: 18px;
        }
    }
    .menu {
        display: flex;

    }
    .menuItem {
        a {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.0976408px;

            color: #FFFFFF;
        }
    }

    .deivider {
        margin-right: 40px;
    }

    .menuButton {
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width: 40px;
        height: 40px;
    }

    .sideMenu {
        position: relative;
        background: #DBDBDB;
        display: flex;
        flex-direction: column;
        transition: right .3s;
        position: fixed;
        width: 212px;
        height: calc(100vh - 64px);
        z-index: 10;
        right: -244px;
        top: 0;
        padding-top: 64px;
        padding-left: 32px;
    }

    .open {
        right: 0px;
    }

    .close {
        position: absolute;
        top: 24px;
        right: 24px;

    }

    .menuItem {
        margin-bottom: 20px;
        a {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.0976408px;
            color: #C55E46;
        }

    }
    .contacts {
        display: flex;
        flex-direction: column;

        position: absolute;
        bottom: 72px;
        left: 32px;
    }
    .author {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0976408px;
        color: #333333;

    }

    .email {
        margin-top: 14px;
        font-family: 'NoirPro-Regular';
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.0976408px;
        color: #1677F0;
    }

</style>