import Vue from 'vue'
import App from './App.vue'
import routers from './router'
import store from './store'
import vueVimeoPlayer from 'vue-vimeo-player'
import '@/filters/index'
const VueInputMask = require('vue-inputmask').default

// Vue.use(router)
// Vue.use(store)
// Vue.use(filters)
Vue.use(vueVimeoPlayer)
Vue.use(VueInputMask)

new Vue({
    store: store,
    router: routers,
    render: h => h(App),
}).$mount('#app')

// const app = createApp(App).use(router).
// app.config.globalProperties.$filters = filters
// app
// app.mount('#app')