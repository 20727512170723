<template>
<div id="app" :class="{'mobile': isMobile}">
  <router-view />
  <Player />
</div>
</template>

<script>
import Header from "@/components/Header.vue"
import Player from "@/components/Player.vue"
import Footer from "@/components/Footer.vue"
import './assets/less/style.less'
import { mapActions } from 'vuex'
import { vAuth } from '@/db.js'
import './styles/main.css'
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  components: {
    Header,
    Player,
    Footer
  },
  computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
  },
  methods: {
    ...mapActions({
      initUser: "user/initUser",
      logout: "user/logout",
      fetchCategories: 'categories/fetchCategories',
      fetchNews: 'news/fetchNews',
      fetchProducts: 'products/fetchProducts',
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",
      actionTogglePersonalDataVisiblePage: "localData/togglePersonalDataVisiblePage",
    })
  },
  async mounted() {

    this.fetchCategories();
    this.fetchNews();
    this.fetchProducts();
  
    vAuth.onAuthStateChanged(() => {
      // console.log('2222')
      this.initUser();
    });

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    this.initUser();
  });

if (window.location.href.includes('recoveryPassword')) {
      this.actionTogglePersonalDataVisibility(true)
      this.logout();
      setTimeout(() => {
        this.actionTogglePersonalDataVisiblePage('recovery')
      }, 1000)
    }

    if (window.location.href.includes('resetPassword')) {
      this.actionTogglePersonalDataVisibility(true)
      vAuth.signOut();
      setTimeout(() => {
        this.actionTogglePersonalDataVisiblePage('reset-password')
      }, 1000)
    }

    if (window.location.href.includes('mode=auth')) {
      this.actionTogglePersonalDataVisibility(true)
      vAuth.signOut();
      setTimeout(() => {
        this.actionTogglePersonalDataVisiblePage('login')
      }, 1000)
    }
    

    if (vAuth.isSignInWithEmailLink(window.location.href)) {
      this.actionTogglePersonalDataVisibility(true)
      setTimeout(() => {
        this.actionTogglePersonalDataVisiblePage('link-auth')
      }, 100)
      }
    
  }
}
</script>
<style>
.mobile {
  overflow: hidden;
}
</style>