import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/functions";

// Get a Firestore instance
export const app = firebase
.initializeApp({
  apiKey: "AIzaSyBZ3vOA0rI9L4cSbWIuhdwTi65SQg3AQ9c",
  authDomain: "montyrey-7ce68.firebaseapp.com",
  databaseURL: "https://montyrey-7ce68.firebaseio.com",
  projectId: "montyrey-7ce68",
  storageBucket: "montyrey-7ce68.appspot.com",
  messagingSenderId: "705155567628",
  appId: "1:705155567628:web:1db93b751354beaab0de29",
  measurementId: "G-B1NE3YHTZL"
 })
 export const vAuth = app.auth();
 export const vFunctions = app.functions('europe-west2');
 export const vfirestore = app.firestore();


