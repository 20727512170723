<template>
<ul class="lang-select" v-show="languageSelectVisibility || source === 'personal'" @change="actionToggleLanguageSelectVisibility(false); actionSetCurrentLanguageId(selectedLanguageId)">
  <li class="lang-select__item" v-for="(item,key) in languages" :key="key">
    <img :src="item.image" :class="$style.lang_selector_icon" v-show="source === 'header'" />
    <label class="lang-select__label">
      <input class="hidden" type="radio" name="languageSelector" :value="item.id" v-model="selectedLanguageId" :selected="item.id === currentLanguageId">
      {{item.label}}
      <img :src="require('@/assets/img/active-tick.svg')" class="lang-select__tick" v-show="source === 'header' && item.id === currentLanguageId" />
      <img :src="require('@/assets/img/active-round.svg')" class="lang-select__tick" v-show="source === 'personal' && item.id === currentLanguageId" />
    </label>
  </li>
</ul>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
export default {
  name: "LanguageSelect",
  props: {
    source: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedLanguageId: 1
    }
  },
  methods: {
    ...mapActions({
      actionToggleLanguageSelectVisibility: "localData/toggleLanguageSelectVisibility",
      actionSetCurrentLanguageId: "localData/setCurrentLanguageId"
    })
  },
  computed: {
    ...mapGetters({
      languages: "localData/languages",
      currentLanguageId: "localData/currentLanguageId",
      languageSelectVisibility: "localData/languageSelectVisibility",
    })
  }
}
</script>

<style lang="scss" module>
.lang_selector_icon {
    width: 32px;
    margin: 0 10px;
}
</style>
