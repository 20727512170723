<template>
<div v-if="item" :class="$style['product-item']" @click="toPage">
  <div :class="$style.title">
      {{ isCourse ? 'Покупка курса' : 'Покупка вебинара' }} “{{ item.name ? item.name : item.title }}”
  </div>
  <div :class="$style.date">
    20.05.2020
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex"
export default {
  props: {
    productItem: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions({
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",
    }),
      toPage() {
          if (this.isCourse) {
            this.$router.push(`/categories/${this.item.category}/courses/${this.item.id}`)
          } else {
            this.$router.push(`/categories/${this.item.collection}/webinars/${this.item.id}`)
          }
        this.actionTogglePersonalDataVisibility(false);
      }

  },
  computed: {
    ...mapGetters({
      cart: "localData/cart",
      items: "serverData/items",
      promocode: "serverData/promocode",
    }),
    item: function() {
      return this.products.find(el => el.productId === this.productItem.productId)
    },
    isCourse() {
        return this.item.title ? true : false
    }
  },
}
</script>


<style lang="scss" module>

  @import "@/styles/_variables";

.product-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.title {
    font-size: 16px;
    line-height: 19px;
    color: $light-black;
}

.date {
    margin-top: 6px;
    font-size: 12px;
    line-height: 14px;
    color: $light-black9;
}
</style>