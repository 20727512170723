<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div v-if="!isMobile" :class="$style.title">
                Отзывы:
            </div>
            <div v-if="!isMobile" :class="$style.reviews" ref="reviews">
                <div>
                    <div ref="slideWrapper">
                        <Review name="Юлия, 34 года" text="Я надеюсь, после того как я подготовлю выжимки из вашего вебинара, это поможет людям узнать истину об “опасной” болезни и вообще о вирусах. Спасибо вам за ваши труды и команде всей вашей. Спасибо, что помогли за 2 часа донести по максимуму важной информации"/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="Любовь, 54 года" text="Дорогой уважаемый Доктор, Денис Викторович, выражаю вам свою благодарность. Вы доктор от Бога! Вебинар прошел на высшем уровне. Как врач вы не поддерживаете тех специалистов, которые ради денег прогнулись под систему. Вы даете объективную оценку происходящим событиям в России. Всегда смотрела видео с Вашим участием, а теперь оказалась на вашем вебинаре. С вами было очень уютно. Остались еще некоторые вопросы, но уверена, что найду на них ответы в прямых эфирах"/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="Татьяна, 23 года" text="Благодарю за вебинар Вас, Денис Викторович, и всю команду, которая над ним работала! Вы умеете создать нескучное пространство. В потоке тягостной, удручающей информации о всяких законопроектах и инициативах Ваша уверенная и легкая подача материала как лучик света!"/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="Тамара, 47 лет" text="Считаю, что дебют ваш, доктор, удался! Я всегда придерживался мнения — поменьше химии, побольше позитива. И любая болезнь отступит."/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="Анастасия, 31 год" text="Спасибо вам большое за то, что вы это сделали. Мне было важно, чтобы это рассказали именно вы. Сейчас многое зависит от народа, который будет по цепочке передавать эту полезную и нужную информацию! Спасибо, что вы есть"/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="Виктория, 35 лет " text="Денис Викторович, благодарю за вебинар! Очень интересная подача материала. Два часа пролетели как миг. Хороших выходных!"/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="Татьяна, 23 года" text="Благодарю за вебинар Вас, Денис Викторович, и всю команду, которая над ним работала! Вы умеете создать нескучное пространство. В потоке тягостной, удручающей информации о всяких новых вирусах и штаммах, Ваша уверенная и легкая подача материала и информации 'что делать с этим' – как лучик света!"/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="Виктория, 35 лет" text="Денис Викторович, благодарю за вебинар! Никогда не думала, что все настолько просто – было бы желание! Два часа пролетели как миг. Хороших выходных!"/>
                    </div>
                </div>
            </div>
            <div :class="$style.about">
                <span>
                    Стресс - это бич современности. Он может приводить к таким болезням как синдром раздражённого кишечника, болезням сердца, головным болям и многим дргуим болезням.
                </span>
                <img :src="require('./assets/ivanov1.png')" />
                <br>
                <br>
                <span>
                    Узнай, как этого не допустить! 
                    Приходи на Вебинар
                </span>

            </div>
        </div>
    </div>
</template>
<script>
import Review from './Review.vue'
import gsap from 'gsap'
export default {
    components: { Review },
    data() {
        return {
            scrollLeft: 0
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    },
    methods: {
        scrollToLeft() {
            let firstChildWidth = this.$refs['slideWrapper'].children[0].offsetWidth;
			if (this.$refs['reviews'].scrollLeft > firstChildWidth) {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: this.$refs['reviews'].scrollLeft - firstChildWidth, duration: 0.3})
			} else {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: 0, duration: 0.3})
				this.scrollLeft = 0
			}
        },
        scrollRight() {
            let firstChildWidth = this.$refs['slideWrapper'].children[0].offsetWidth;
			if (this.$refs['reviews'].scrollLeft < this.$refs['slideWrapper'].offsetWidth - firstChildWidth) {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: this.$refs['reviews'].scrollLeft + firstChildWidth, duration: 0.3})
				this.scrollLeft = this.$refs['reviews'].scrollLeft + firstChildWidth
			} else {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: this.$refs['reviews'].offsetWidth, duration: 0.3})
				this.scrollLeft = 'max';
			}
        }
    }
}
</script>
<style lang="scss" module>
    
    .main {
        background: linear-gradient(180deg, #9FD7DE 0%, #ffffff 16.04%);
        width: calc( 100% - (100% - 1099px) );
        padding-left: calc((100% - 1099px) / 2);
        padding-right: calc((100% - 1099px) / 2);

        &.mobile {
            width: calc( 100% - 32px );
            padding-left: 16px;
            padding-right: 16px;
        }

    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;
        
        .mobile & {
            width: calc(100% - 16px);
            padding: 0px 0 31px 16px;
        }
    }

    .reviews {
        display: flex;
        overflow-x: scroll;
        margin-top: 93px;
        & > div {
            display: flex;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }
    .scrollButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: linear-gradient(91.12deg, #0065E3 0%, #5ECFFF 100%);
        border-radius: 25px;
        margin-left: 8px;
        cursor: pointer;
    }

    .title {
        font-family: Montserrat-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 64px;
        text-align: center;
        color: #41757C;

        .mobile & {
            font-size: 24px;
            line-height: 28px;
        }
    }

    .about {
        font-family: Montserrat-Bold;
        margin-top: 190px;
        font-weight: bold;
        font-size: 40px;
        line-height: 60px;
        color: #E76F51;

        & img {
            float: right;
        }

        .mobile & {
            font-size: 12px;
            line-height: 14px;
            margin-top: 60px;

            & img {
                width: 100px;
                height: 100px;
            }
        }
    }

</style>