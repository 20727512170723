<template>
<div class="webinar-bought">
  <h2 class="webinar-bought__wb-heading">Вебинар "{{item.label}}"</h2>
  <div class="webinar-bought__wb-content wb-content">
    <img :src="require('@/assets/img/order-check.svg')" class="wb-content__check" />
    <h3 class="wb-content__heading">Вебинар куплен</h3>
    <p class="wb-content__caption">Доступ на вебинар “Наименование вебинара” на неограниченное время за 149 рублей</p>
    <router-link class="wb-content__button" :to="item.link">Перейти к вебинару</router-link>
  </div>
</div>
</template>

<script>
export default {
  name: 'WebinarBought',
  props: {
    item: {
      type: Object,
      required: true
    },
  }
}
</script>
