<template>
    <div :class="[$style.tile,  isMobile ? $style.mobile : '']">
        <img :src="[
                require('./assets/microbe.png'),
                require('./assets/face-with-thermometer.png'),
                require('./assets/pill.png'),
                require('./assets/person-in-lotus-position.png'),
                require('./assets/face-with-monocle.png'),
                require('./assets/face-with-medical-mask.png')
            ][img]"/>
        <div :class="$style.tileText">
            {{text}}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        img: {
            type: Number
        },
        text: {
            type: String
        }
    },
    computed: {
        isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>

    .tile {
        margin-top: 20px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        height: 172px;
        border: 1px solid #ACC7E2;
        box-sizing: border-box;
        border-radius: 16px;

        img {
            width: 60px;
            height: 60px;
        }

        &.mobile {
            width: 100%;
        }
    }
    .tileText {
        font-family: NoirPro-Regular;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.0976408px;
        color: #333333;
    }
</style>