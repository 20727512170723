<template>
  <MainLayout>
    <div :class="isMobile ? $style.m_index : $style.index">
      <div v-if="isMobile" :class="$style.m_background"></div>
      <h1 :class="isMobile ? $style.m_heading : $style.heading">
        Образовательная онлайн платформа
        <img src="@/assets/img/notebookMan.svg" alt="" />
      </h1>
      <CategorySlider />
      <div :class="$style.spacer"></div>
      <NewsSlider />
      <!-- <ItemSlider /> -->
      <DownloadApp v-if="!isMobile" />
    </div>
  </MainLayout>
</template>

<script>
import CategorySlider from "@/components/CategorySlider.vue";
import NewsSlider from "@/components/NewsSlider.vue";
import ItemSlider from "@/components/ItemSlider.vue";
import DownloadApp from "@/components/DownloadApp.vue";
import MainLayout from "@/components/layouts/MainLayout.vue";

export default {
  name: "Index",
  components: {
    CategorySlider,
    NewsSlider,
    ItemSlider,
    DownloadApp,
    MainLayout,
    MainLayout,
  },
  computed: {
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? true
        : false;
    },
  },
};
</script>
<style lang="scss" module>
.spacer {
  height: 80px;
}
.index {
  display: flex;
  flex-flow: column;
  padding: 0 170px;
}

.heading {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 430px;
  margin: -100px -170px -137px;
  padding: 100px 170px 240px;
  background: linear-gradient(264.52deg, #a9ffff 0%, #f6ffc0 100%);
  font-weight: bold;
  font-size: 44px;
  line-height: 84px;
  text-align: left;
  z-index: -1;

  & img {
    width: 48px;
    height: 48px;
    margin: 0 0 0 16px;
  }
}

.m_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 440px;
  background: linear-gradient(264.52deg, #a9ffff 0%, #f6ffc0 100%);
  z-index: -1;
}
.m_index {
  display: flex;
  flex-flow: column;
  width: calc(100% - 32px);
  padding: 0 16px;
}

.m_heading {
  display: flex;
  box-sizing: border-box;
  margin-top: 48px;
  margin-bottom: 48px;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.139487px;
  text-align: left;
  z-index: -1;

  & img {
    width: 48px;
    height: 48px;
    margin: 0 0 0 16px;
  }
}
</style>
