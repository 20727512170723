<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.image">
            <img :src="[
                require('./assets/1.png'),
                require('./assets/2.png'),
                require('./assets/3.png'),
                require('./assets/4.png'),
                require('./assets/5.png'),
                require('./assets/6.png')
            ][index]"/>
        </div>
        <div :class="$style.text">{{ text }}</div>
    </div>
    
</template>
<script>
export default {
    props: {
        index: {
            type: Number
        },
        text: {
            type: String
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>
.main {
    display: flex;
    align-items: center;
    width: 540px;
    height: 147px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 12px;
    border-radius: 24px;
    margin-top: 20px;
    padding: 20px;

    font-size: 20px;
    line-height: 24px;

    letter-spacing: 0.0976408px;

    color: #333333;

    &.mobile {
        width: auto;
        height: auto;
    }


}
.image {
    display: flex;
    justify-content: flex-start;
    width: 116px;
    height: 116px;
    margin-right: 20px;
    float: left;

    & img {
        width: 116px;
        height: 116px;
    }

    .mobile & {
        width: 60px;
        height: 60px;
        
        & img {
            width: 60px;
            height: 60px;
        }
    }
}

.text {
    display: flex;
}

</style>