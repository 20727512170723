<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.exit" @click="$emit('close')">
                <img src="./assets/exit.png" />
            </div>

            <div :class="$style.text">
                СПАСИБО!
            </div>
            <div style="height: 49px"></div>
            <div :class="$style.description">
                Чек об оплате и инструкция с дальнейшими<br> действиями придет вам на почту!
            </div>
            <div style="height: 149px"></div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        background: #F5F5F5;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 101;
    }

    .content {
        background: linear-gradient(88.7deg, #C55E46 2.22%, #333333 79.34%);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        width: 865px;
        height: 545px;

        .mobile & {
            margin: 0 18px;
        }
    }

    .exit {
        position: absolute;
        right: 24px;
        top: 24px;
    }

    .text {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        letter-spacing: 0.0976408px;

        color: #FFFFFF;
    }

    .description {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.0976408px;

        color: #FFFFFF;
    }

</style>