<template>
    <div :class="[$style.main,  isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                ОНЛАЙН-ВЕБИНАР 
            </div>
            <div :class="$style.subtitle">
                Возьми стресс<br v-if="isMobile"> под <br v-if="!isMobile"> контроль! 
            </div>
            <div :class="$style.promo_text">
                Природа возникновения <br v-if="isMobile"> стресса <br v-if="!isMobile"> и его влияние <br v-if="isMobile"> на организм
            </div>
            <a href="#part8" :class="$style.registrationButton">
                ХОЧУ НА ВЕБИНАР
            </a>
            <div v-if="!isMobile" :class="$style.infoBlock">
                <div :class="$style.infoBlockLeft">
                    <strong>35 лет</strong>
                    Стаж врачебной <br> практики
                </div>
                <div :class="$style.infoBlockRight">
                    <strong>1200 часов</strong>
                    Лекций ля студентов <br> мед. вузов
                </div>

            </div>
            <div v-if="!isMobile" :class="$style.photo">
                <img :src="require('./denisov.png')"/>
            </div>
            <div v-if="isMobile"  :class="$style.photo">
                <img :src="require('./assets/ivanov4.png')"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
.main {
    position: relative;
    background: linear-gradient(180deg, #9FD7DE 0%, rgba(232, 232, 232, 0) 76.04%);
    width: 100%;
    height: 633px;
    padding-top: 80px;
    color: #333333;
    overflow: hidden;

    &.mobile {
        height: auto;
        height: 359px;
    }

}
.photo {
    position: absolute;
    height: 100%;
    right: 0;
    top: 120px;

    .mobile & {
        position: absolute;
        align-self: center;
        width: 176px;
        height: 306px;
        margin-top: 16px;
        z-index: 0;
        right: 0px;
        top: 53px;

        img {
            width: 176px;
            height: 306px;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // height: 633px;
    max-width: 1099px;
    margin: auto;
    
    .mobile & {
        height: auto;
        align-items: flex-start;
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;

    }
}
.title {
    font-size: 32px;
    line-height: 64px;
    color: #41757C;

    .mobile & {
        line-height: 28px;
        font-size: 18px;
        z-index: 1;
    }
}
.subtitle {
    font-family: Montserrat-Bold;
    font-weight: bold;
    margin-top: 25px;
    font-size: 54px;
    line-height: 64px;
    color: #E76F51;
    .mobile & {
        font-size: 20px;
        line-height: 26px;
        z-index: 1;
    }

}
.checklistTitle {
    font-family: NoirPro-Medium;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.0976408px;
    margin-top: 32px;

}

.registrationButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 537px;
    height: 112px;
    margin-top: 22px;
    background: #2A9D8F;
    border-radius: 10px;
    font-family: Montserrat-Bold;
    font-size: 30px;
    line-height: 21px;
    letter-spacing: 0.0557948px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    color: #FFFFFF;

    .mobile & {
        width: 194px;
        height: 37px;
        font-family: Montserrat-Bold;
        font-size: 12px;
        line-height: 20px;
        z-index: 1;
    }
}
.listItem {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #333333;
    margin-top: 12px;

    img {
        margin-right: 12px;
    }

    .mobile & {
        display: flex;
        align-items: flex-start;
    }
}
.promo_text {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #41757C;
    margin-top: 13px;

    .mobile & {
        font-size: 14px;
        line-height: 20px;
        z-index: 1;
    }
}
.infoBlock {
    display: flex;
    justify-content: space-between;
    width: 493px;
    margin-top: 40px;
    padding: 0 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #41757C;
    .infoBlockLeft {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .infoBlockRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>