import { vfirestore } from '@/db'

export default {
    namespaced: true,
    state: {
        list: []
    },
    getters: {
        list: (state) => state.list
    },
    mutations: {
        setCategories(state, categories) {
            state.list = categories;
        }
    },
    actions: {
        async fetchCategories({ commit }) {
            let res = await vfirestore.collection('categories').get()
            if (res.docs.length) {
                let docs = res.docs.map( item => ({ ...item.data(), id: item.id }))
                docs.sort((a, b) => a.number - b.number)
                commit('setCategories', docs);
            } else {
                commit('setCategories', []);
            }

        }
    },
}