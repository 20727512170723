<template>
<div :class="$style['cart-item']">
  <img :class="$style['cart-item__image']" :src="item.image" width="80" height="120">
  <div :class="[ $style['cart-item__ci-content'], $style['ci-content']]">
    <h2 :class="$style['ci-content__heading']">{{item.name}}</h2>
    <div :class="[$style['ci-content__ci-controls'], $style['ci-controls']]">
      <a :class="[$style['ci-controls__button'], $style['ci-controls__button--minus']]" @click.prevent="minusItemFromCart(item.id)"></a>
      <p :class="$style['ci-controls__amount']">{{item.count }} шт</p>
      <a :class="[$style['ci-controls__button'], $style['ci-controls__button--plus']]" @click.prevent="plusItemToCart(item.id)"></a>
    </div>
    <p :class="[$style['ci-content__price'], promocode && promocode.price ? $style['ci-content__price--decreased'] : '']">
      <span :class="$style['ci-content__price--raw']" v-show="promocode && promocode.price">{{ (item.count * item.price) | price }}</span>
      {{ (item.count * item.price) | price }}
    </p>
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex"
export default {
  name: "CartItem",
  props: {
    itemId: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      plusItemToCart: "cart/plusItemToCart",
      minusItemFromCart: "cart/minusItemFromCart"
    }),

  },
  computed: {
    ...mapGetters({
      cart: "cart/list",
      items: "serverData/items",
      promocode: "serverData/promocode",
    }),
    item() {
      return this.cart.find(el => el.id === this.itemId)
    },
    price() {
      return (this.promocode && this.promocode.price) ? this.item.price - this.promocode.price : this.item.price 
    }
  }
}
</script>

<style lang="scss" module>
  @import "@/styles/_variables";

.cart-item {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  width: 100%;
  height: 144px;
  margin: 0;
  padding: 12px 0;
}

.cart-item__image {
  margin: 0 16px 0 0;
}

.ci-content {
  display: flex;
  flex-flow: row wrap;
  width: 220px;
}

.ci-content__heading {
  width: 100%;
  margin: 30px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

.ci-controls {
  display: flex;
  flex-flow: row wrap;
  min-width: 110px;
  height: 32px;
  margin: 5px 0 0;
  align-items: center;
}

.ci-controls__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: $light-grey;
  border-radius: 8px;
  cursor: pointer;

  &:active {
    background: $grey;
  }
}

.ci-controls__amount {
  box-sizing: content-box;
  min-width: 40px;
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}

.ci-controls__button--minus::before {
  position: absolute;
  content: "";
  display: flex;
  width: 10px;
  height: 2px;
  background: $blue;
}

.ci-controls__button--plus::after,
.ci-controls__button--plus::before {
  position: absolute;
  content: "";
  display: flex;
  width: 10px;
  height: 2px;
  background: $blue;
}

.ci-controls__button--plus::after {
  transform: rotate(90deg);
}

.ci-content__price {
  position: relative;
}

.ci-content__price--raw {
  position: absolute;
  top: -16px;
  right: 0;
  color: $dark-grey;
  text-decoration: line-through;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
}

.ci-content__price--decreased {
  color: $green;
}

.ci-content__price {
  display: flex;
  margin: 10px 0 0 auto;
}


</style>