<template>
	<router-link
		class="webinar-card"
		:class="{'webinar-card--online': webinar.online}"
		:to="`/categories/${category.id}/webinars/${webinar.id}`"
	>
		<div class="image-wrapper" :style="{'background-image': `url(${webinar.thumb})`}"></div>
		<h3 class="webinar-card__heading">{{webinar.name}}</h3>
	</router-link>
</template>

<script>
export default {
	name: "WebinarCard",
	props: {
		category: { type: Object, required: true },
		webinar: { type: Object, required: true },
	}
}
</script>

<style scoped>

.image-wrapper {
	background-size: cover;
	background-position-y: center;
	background-repeat: no-repeat;
	width: 170px;
	height: 147px;
	overflow: hidden;
	border-radius: 16px;
}

</style>
