<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Что вы узнаете на вебинаре
            </div>
            <div :class="$style.programWrapper">
                <ReasonTile :index="0" text="Как избавиться от стресса и напряжения"/>
                <ReasonTile :index="1" text="Как избежать психосоматических болезней"/>
                <ReasonTile :index="2" text="Как начать отдыхать вовремя, а не когда уже пора к врачу"/>
                <ReasonTile :index="3" text="Как отличать реакции организма на стресс от других болезней"/>
                <ReasonTile v-if="!isMobile" :index="4" text="Приводит ли стресс к паническим атакам"/>
                <ReasonTile v-if="!isMobile" :index="5" text="Когда следует незамедлительно обратиться к врачу"/>
            </div>
        </div>
    </div>
</template>
<script>
import Programm from './Programm.vue'
import ReasonTile from './ReasonTile.vue'
export default {
    components: { Programm, ReasonTile },
    data() {
        return {
            programmText: [
                'Начнем со знакомства с вирусами, вы узнаете какие их виды существуют, какие симптомы вызывает каждый из них. Как они появляются и как проникают в наш организм.',
                'Посвящен иммунитету и способам его укрепления. Действительно, ли лук и чеснок помогают укреплять здоровье. Как кишечник и то, что мы едим связаны с иммунной системой.',
                'Расскажу какие лекарства стоит принимать, а какие лишь трата денег. Что и когда делать с температурой. Почему важно разжижать кровь и когда начинать пить антибиотики.'
            ],
            programmItems: [
                ['Виды вирусов', 'Эпидемиология', 'Пути проникновения в организм'],
                ['Что такое иммунитет', '8 факторов, которые влияют на его укрепление', 'Способы профилактики'],
                ['Способы облегчения симптомов', 'Температура. Когда сбивать', 'Рекомендации по лекарствам и их применению', 'Антибиотики']
            ]
        }
    },

    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>
    .main {
        background: linear-gradient(180deg, #9FD7DE 0%, rgba(232, 232, 232, 0) 76.04%);
        width: calc( 100% - (100% - 1099px) );
        padding-left: calc((100% - 1099px) / 2);
        padding-right: calc((100% - 1099px) / 2);

        &.mobile {
            width: calc(100% - 32px);
            padding: 0 16px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            padding: 40px 0;
        }
    }
    .programWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;

        .mobile & {
            margin-top: 13px;
        }
    }
    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 64px;
        text-align: center;
        color: #41757C;

        .mobile & {
            font-size: 24px;
            line-height: 28px;
        }
    }
</style>