<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']" >
        <a name="programm" />
        <div :class="$style.content">
            <div :class="$style.programm">
                Программа вебинара:
            </div>
            <div v-if="!isMobile" :class="$style.advices">
                <div :class="$style.advice">
                    <div>
                        <div :class="$style.label">
                            Модуль 1
                        </div>
                    </div>
                    <div :class="$style.title">
                        Природа секса
                    </div>
                    <div :class="$style.list">
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Как мы устроены природой</div></div>
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Существует ли моногамия</div></div>
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Инстикты в социальном мире</div></div>
                    </div>
                </div>
                <div :class="$style.advice">
                    <div>
                        <div :class="$style.label">
                            Модуль 2
                        </div>
                    </div>
                    <div :class="$style.title">
                        Сценарии секса
                    </div>
                    <div :class="$style.list">
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Что это такое</div></div>
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Для чего нужны эти сценарии</div></div>
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Какой сценарий у вас и вашего партнера</div></div>
                    </div>
                </div>
                <div :class="$style.advice">
                    <div>
                        <div :class="$style.label">
                            Модуль 3
                        </div>
                    </div>
                    <div :class="$style.title">
                        Что такое эротизм
                    </div>
                    <div :class="$style.list">
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Как поселить его в отношениях</div></div>
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Какие правила в нем существуют</div></div>
                        <div :class="$style.listItem"><div :class="$style.point"></div><div>Как он влияет на качество секса</div></div>
                    </div>
                </div>
            </div>
            <div v-if="isMobile" :class="$style.advicesMob">
                <div :class="$style.adviceMob" v-for="(item, index) in advices" :key="index" @click="current = item">
                    <div :class="$style.adviceTitle">
                        {{index + 1}} модуль - {{item.title}}
                    </div>
                    <span :class="$style.advicePlus">+</span>
                </div>
            </div>
            <div :class="$style.more">Ещё более 20 упражнений для яркого <br> секса в любых отношениях</div>
            <a href="#registration" :class="$style.button">
                <button>
                    Получить доступ
                </button>
            </a>

            <div :class="[$style.sideMenu,  current ? $style.open : '']">
                <div :class="$style.close" @click="current = false">
                    <img src="./assets/exit1.svg" />
                </div>

                <div :class="$style.contacts">
                    <div :class="$style.sideMenuTitle">
                        {{current.title}}
                    </div>
                    <div v-for="(i, index) in current.items" :key="index" :class="$style.item">
                        <div :class="$style.pointMobile">
                        </div>
                        <div :class="$style.itemText">
                            {{i}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            current: false,
            advices: [
                {
                    title: 'Природа секса',
                    items: [
                        'Как мы устроены природой',
                        'Существует ли моногамия',
                        'Инстикты в современном мире'
                    ]
                },
                {
                    title: 'Сценарии секса',
                    items: [
                        'Что это такое',
                        'Для чего нужны эти сценарии',
                        'Какой сценарий у вас и вашего партнера'
                    ]
                },
                {
                    title: 'Эротизм',
                    items: [
                        'Как поселить его в отношениях',
                        'Какие правила в нем существуют',
                        'Как он влияет на качество секса'
                    ]
                }
            ]
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        display: flex;
        justify-content: space-between;
    }

    .content {
        display: flex;
        flex-direction: column;

        .mobile & {
            align-items: center;
            width: 100%;
        }
    }

    .programm {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 84px;
        letter-spacing: 0.278974px;

        color: #D75B3F;

        .mobile & {
            font-size: 20px;
            line-height: 84px;
        }
    }


    .advices {
        display: flex;
        .mobile & {
            flex-direction: column;
        }
    }
    
    .advice {
        display: flex;
        flex-direction: column;
        width: 353px;

    }
    .devider {
        width: 20px;
    }

    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;
    }

    .label {
        display: inline-block;
        padding: 6px 10px;
        background: #F0163D;
        border-radius: 12px;

        font-family: 'NoirPro-Regular';
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;
    }

    .title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 84px;
        letter-spacing: 0.278974px;
        color: #FFFFFF;
    }

    .list {
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;
        
    }
    .point {
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 4px solid #FFFFFF;
        border-radius: 16px;
        margin-right: 12px;
    }

    .listItem {
        display: flex;
        margin-top: 12px;
    }

    .more {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 38px;

        margin-top: 53px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.278974px;

        color: #FFFFFF;

        .mobile & {
            font-size: 14px;
            line-height: 18px;
        }

    }


    .button {
        align-self: center;

        button {

            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 20px;
            letter-spacing: 0.0557948px;
            color: #FFFFFF;

            background: #D75B3F;
            border: 1px solid #979797;
            border-radius: 12px;

            width: 546px;
            height: 87px;

            margin-top: 38px;

            .mobile & {
                width: 269px;
                height: 52px;
                font-size: 16px;
            }
        }
    }

    .sideMenu {
        position: relative;
        background: #000000;
        border: 1px solid #FFFFFF;
        display: flex;
        flex-direction: column;
        transition: right .3s;
        position: fixed;
        width: 212px;
        height: calc(100vh - 64px);
        z-index: 10;
        right: -244px;
        top: 0;
        padding-top: 64px;
        padding-left: 32px;

        .sideMenuTitle {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 84px;
            letter-spacing: 0.278974px;

            color: #FFFFFF;

        }
    }
    
    .open {
        right: 0px;
    }
    .adviceMob {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;


        font-family: 'Montserrat';
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.278974px;
        text-decoration-line: underline;

        color: #FFFFFF;


        .advicePlus {
            text-decoration-line: none;
        }
    }

    .adviceTitle {
        margin-right: 20px;
    }


    .close {
        position: absolute;
        top: 24px;
        right: 24px;

    }

    .pointMobile {
        width: 12px;
        height: 12px;
        box-sizing: border-box;
        border-radius: 12px;
        border: 4px solid #FFFFFF;
        margin-right: 12px;
        margin-top: 4px;
    }

    .item {
        display: flex;
        align-items: flex-start;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0976408px;
        margin-top: 12px;
        color: #FFFFFF;
    }


</style>