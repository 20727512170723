<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <a name="registration" />
        <div :class="$style.title">
            Регистрация
        </div>
        <div :class="$style.form">
            <div :class="$style.input">
                <input name="name" v-model="name" placeholder="Имя"/>
            </div>
            <!-- <div style="width: 20px"></div> -->
            <div :class="$style.input">
                <input v-model="phone" v-mask="{mask: '9(999) 999-99-99'}" name="name" placeholder="Телефон"/>
            </div>
            <!-- <div style="width: 20px"></div> -->
            <div :class="$style.input">
                <input v-model="email" name="name" placeholder="Электронная почта"/>
            </div>
            <!-- <div style="width: 20px"></div> -->
            <div :class="$style.input">
                <input v-model="emailConfirm" name="name" placeholder="Введите почту еще раз"/>
            </div>
        </div>

        <button :class="$style.button" @click="openPayModal">
            Хочу вернуть секс!
        </button>



        <div v-if="isPayModalWrapper" :class="$style.payModal">

            <div :class="$style.modalClose" @click="isPayModalWrapper = false">
                <img src="./assets/exit.svg" />
            </div>
            <div :class="$style.modalContent">
                <div v-if="!isMobile" style="height: 70px"></div>
                <div v-if="isMobile" style="height: 29px"></div>
                
                <div style="height: 24px"></div>
                <div :class="$style.modalDivider">
                </div>
                <div :class="$style.modalChoose">
                    Выберите способ оплаты и совершите платеж:
                </div>
                <div style="height: 24px"></div>
                <div :class="$style.payVariant">
                     <div>
                        <img src="./assets/cards.png" />
                    </div>
                    <div :class="$style.payText">
                        Оплата картой<br v-if="!isMobile"> российского банка
                    </div>
                    <button :class="$style.payButton" @click="buyWebinar">
                        Оплатить 2 490 руб.
                    </button>
                </div>
                <div style="height: 16px"></div>
                <div :class="$style.payVariant">
                     <div>
                        <img src="./assets/cards.png" />
                    </div>
                    <div :class="$style.payText">
                        Оплата картой<br v-if="!isMobile"> зарубежного банка
                    </div>
                    <button :class="$style.payButton" @click="payUsdWrappers">
                        Оплатить 2 490 руб.
                        <!-- Оплатить 41.5$ -->
                    </button>
                    
                </div>
            </div>
        </div>

        <div :class="$style.alert" v-if="isModalOpen">
            <div :class="$style.alert_content">
                <span>
                    Вам на почту было отправлено письмо <br><br>
                        со ссылкой на вебинар! <br><br><br>

                    Спасибо за покупку!

                </span>
                <button :class="[$style.button, $style.small]" @click="isModalOpen = false">
                Ок
            </button>
            </div>
        </div>

        <template v-if="isLoading">
            <div :class="$style.loader">
            <Loading />
            </div>
        </template>
    </div>
</template>
<script>

import { vFunctions,vfirestore, vAuth } from '@/db.js'
import {pay, payUsd} from '@/commons/pay.js'
import Loading from '@/components/Loading.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Loading
    },
    props: {
        price: {
            type:Number
        }
    },
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            isLoading: false,
            isModalOpen: false,
            isPayModalWrapper: false,
            emailConfirm: '',
            emailMask: {
                mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
                greedy: false,
                onBeforePaste: function (pastedValue, opts) {
                pastedValue = pastedValue.toLowerCase();
                return pastedValue.replace("mailto:", "");
                },
                definitions: {
                '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                    casing: "lower"
                }
                }
            }
        }
    },


    computed: {
        ...mapGetters({
            isAuth: 'user/isAuth',
        }),
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
        ref() {
            return this.$route.query ? this.$route.query.ref : '';
        },
    	isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    
    },

    methods: {
        ...mapActions({
            logout: 'user/logout'
        }),

        openPayModal() {

            if (this.name && this.phone && this.email) {


                if (this.emailConfirm != this.email) {
                    this.isLoading = false;
                    alert('Email не совпадает')
                    return
                }
                
                try {
                    this.payWrappers();
                } finally {
                    
                }

            } else {
                this.isLoading = false;
                alert('Не все поля заполнены.')
            }

        },

        async payProdamus() {
            let ref = this.$route.query ? this.$route.query.ref : '';

            try {
                this.isLoading = true;
            
                await vFunctions.httpsCallable('startBuying')({
                    name: this.name,
                    phone: this.phone,
                    email: this.email,
                    productId: 'kuznetsovaSex',
                    ref: ref ? ref : ''
                });
                
                let date = Date.now().toString().split('');
                date = date.splice(6, 5)
                date = date.join('')

                try {
                    let res = await vAuth.createUserWithEmailAndPassword(this.email, '111111')
                    let user = await vAuth
                        .signInWithEmailAndPassword(this.email, '111111')
                        let link = await axios.get(`https://mentalmarket.payform.ru/?do=link&order_id=${date}&customer_phone=${this.phone}&customer_email=${this.email}&products[0][price]=${this.price}&products[0][name]=kuznetsovaSex&products[0][quantity]=1&customer_extra=${this.name}`)
                        window.location.href = link.data
                        this.$emit('update')
                        this.isModalOpen = true;
                }
                catch(e) {
                    if (e && e.code == 'auth/email-already-in-use') {
                        let link = await axios.get(`https://mentalmarket.payform.ru/?do=link&order_id=${date}&customer_phone=${this.phone}&customer_email=${this.email}&products[0][price]=${this.price}&products[0][name]=kuznetsovaSex&products[0][quantity]=1&customer_extra=${this.name}`)
                        window.location.href = link.data
                        this.isModalOpen = true;
                        this.$emit('update')
                    } else {
                        this.$emit('update')
                    }
                }
            } finally {
                this.isLoading = false;
                this.isModalOpen = false;
            }
            
           
        },

        async payWrappers() {
                let ref = this.$route.query ? this.$route.query.ref : '';
                this.isCheckboxValid = true;
                this.isDerty = true;
                // if (!this.isAgree) {
                //     this.isCheckboxValid = false;
                //     return;
                // }

                if ((this.name && this.phone && this.email)) {
                    this.isLoading = true;
                    
                    await vFunctions.httpsCallable('startBuying')({
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        productId: 'kuznetsovaSex',
                        ref: ref ? ref : ''
                    });
                    
                    try {
                        let res = await vAuth.createUserWithEmailAndPassword(this.email, '111111')
                        let user = await vAuth
                            .signInWithEmailAndPassword(this.email, '111111')
                            // await payUsd(this.price, user.user.uid, 'kuznetsovaSex', this.email, "webinar", ref, this.name);
                            await pay(this.price, user.user.uid, 'kuznetsovaSex', this.email, "webinar", ref, this.name);
                            this.$emit('update')
                            this.isModalOpen = true;
                    }
                    catch(e) {
                        if (e && e.code == 'auth/email-already-in-use') {
                            await pay(this.price, '', 'kuznetsovaSex', this.email, "webinar", ref, this.name);
                            // await payUsd(this.price, '', 'kuznetsovaSex', this.email, "webinar", ref, this.name);
                            this.isModalOpen = true;
                            this.$emit('update')
                        } else {
                            this.$emit('update')
                        }
                    }

                    finally {
                        this.isLoading = false;
                        this.isPayModalWrapper = false;
                    }

                    
                } else {
                    this.isLoading = false;
                    alert('Не все поля заполнены.')
                    
                }
           

            this.isLoading = false;
        },
            
        async buyWebinar() {
                let ref = this.$route.query ? this.$route.query.ref : '';
                this.isCheckboxValid = true;
                this.isDerty = true;
                // if (!this.isAgree) {
                //     this.isCheckboxValid = false;
                //     return;
                // }

                if ((this.name && this.phone && this.email)) {
                    this.isLoading = true;
                    
                    await vFunctions.httpsCallable('startBuying')({
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        productId: 'kuznetsovaSex',
                        ref: ref ? ref : ''
                    });
                    
                    try {
                        let res = await vAuth.createUserWithEmailAndPassword(this.email, '111111')
                        let user = await vAuth
                            .signInWithEmailAndPassword(this.email, '111111')
                            await pay(this.price, user.user.uid, 'kuznetsovaSex', this.email, "webinar", ref, this.name);
                            this.$emit('update')
                            this.isModalOpen = true;
                    }
                    catch(e) {
                        if (e && e.code == 'auth/email-already-in-use') {
                            await pay(this.price, '', 'kuznetsovaSex', this.email, "webinar", ref, this.name);
                            this.isModalOpen = true;
                            this.$emit('update')
                        } else {
                            this.$emit('update')
                        }
                    }

                    finally {
                        this.isLoading = false;
                        this.isPayModalWrapper = false;
                    }

                    
                } else {
                    this.isLoading = false;
                    alert('Не все поля заполнены.')
                    
                }
           

            this.isLoading = false;
        },
    },
}
</script>
<style lang="scss" module>

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-family: 'Codec Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 76px;
    letter-spacing: 0.278974px;

    color: #D75B3F;

    .mobile & {
        font-size: 40px;
        line-height: 76px;
    }
}

.form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    justify-content: center;
    .mobile & {
        flex-direction: column;
        width: 100%;
    }
}

.input {
    margin: 20px;
    input {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 12px;
        border: none;
        width: 353px;
        padding: 13px 26px;

        font-family: 'NoirPro-Regular';
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        letter-spacing: 0.0976408px;

        color: #FFFFFF;

        &::placeholder {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            /* identical to box height, or 133% */

            letter-spacing: 0.0976408px;

            color: #FFFFFF;
        }
    }


    .mobile & {
        margin-top: 16px;
        input {
           width: calc(100% - 52px);
        }
    }
}

     .button {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 20px;
        letter-spacing: 0.0557948px;
        text-transform: capitalize;

        color: #FFFFFF;

        background: #D75B3F;
        border: 1px solid #979797;
        border-radius: 12px;

        align-self: center;

        width: 546px;
        height: 87px;

        margin-top: 60px;

        .mobile & {
            font-size: 12px;
            line-height: 20px;

            width: 216px;
            height: 43px;
        }

        &.small {

            font-size: 12px;
            line-height: 20px;

            width: 216px;
            height: 43px;

        }
    }



    .loader {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    .alert {
        background: rgba(0,0,0,0.3);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .alert_content {
            background: #FFFFFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 400px;
            height: 300px;
            border-radius: 22px;
        }
    }

    .payModal {
        position: fixed;
        background: #FFFFFF;
        z-index: 100;
        top: 100px;
        left: 0;
        width: 100%;
        height: calc(100% - 100px);

        .mobile & {
            overflow: auto;
        }
    }

    .modalContent {
        background: linear-gradient(88.7deg, #C55E46 2.22%, #333333 79.34%);
        border-radius: 12px;
        margin: 71px 212px 71px 212px;
        width: calc(100% - 424px - 194px);
        height: calc(100% - 142px - 154px);
        padding: 77px 97px;
        overflow: auto;

        .mobile & {
            width: calc(100% - 36px - 36px);
            height: auto;
            padding: 18px;
            margin: 18px;
            overflow: hidden;
        }
    }

    .modalDate {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;

    }

    .modalPrice {
        position: relative;
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        letter-spacing: 0.0976408px;

        color: #FFFFFF;
    }

    .modalDivider {
        border: 1px solid #FFFFFF;
    }

    .modalChoose {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.0976408px;
        margin-top: 24px;

        color: #FFFFFF;


    }

    .payVariant {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #979797;
        border-radius: 12px;
        width: 100%;
        height: 120px;

        .mobile & {
            display: flex;
            flex-direction: column;
            height: auto;
            padding: 18px;
            width: calc(100% - 36px);
            align-items: flex-start;
        }
    }

    .payButton {
        background: #D75B3F;
        border: 1px solid #979797;
        border-radius: 12px;
        width: 248px;
        height: 45px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        letter-spacing: 0.0976408px;

        color: #FFFFFF;

        .mobile & {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .payText {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;

        letter-spacing: 0.0976408px;

        color: #D75B3F;


        .mobile & {
            font-size: 14px;
            line-height: 32px;
        }
    }

    .modalClose {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }

</style>