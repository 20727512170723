<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.title">
            Контакты
        </div>
        <div :class="$style.contacts">
            <div :class="$style.email">
                support@mentalmarket.ru
            </div>
            <div :class="$style.info">
                Материал предназначен для широкой аудитории.<br v-if="!isMobile">
                Информация, представленная в данных лекциях,<br v-if="!isMobile">
                не представляет собой и не заменяет консультацию врача.
            </div>
            
            <a href="https://mentalmarket.ru/policy.pdf" :class="$style.policy">
                Политика конфидециальности
            </a>
            <a href="https://mentalmarket.ru/oferta.pdf" :class="$style.privacy">
                Договор оферта
            </a>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>

.main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &.mobile {
        flex-direction: column;
        justify-content: flex-start;
    }
}

.title {
    font-family: 'NoirPro-Medium';
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.0976408px;

    color: #FFFFFF;
}

.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
        font-family: 'NoirPro-Regular';
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0976408px;

        color: #1677F0;

    }

    .mobile & {
        align-items: flex-start;
    }
}

.info {
    font-family: 'NoirPro-Regular';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0976408px;
    text-align: right;

    color: #FFFFFF;


    .mobile & {
        text-align: left;
        color: #333333;
    }
}

.email {
    font-family: 'NoirPro-Regular';
    font-size: 18px;
    line-height: 24px;

    letter-spacing: 0.0976408px;

    color: #1677F0;

}


.policy {
    margin-top: 16px;
}
.privacy {
    margin-top: 12px;
}
</style>