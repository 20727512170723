<template>
  <div :class="[$style['item-card-wrapper'], isMobile ? $style.mobile : '']">
    <router-link :class="$style['item-card']" :to="'/items/'+item.id">
      <div :class="$style.content_wrapper">
        <div :class="$style['ic-content__heading']" :to="'/items/'+item.link">{{item.name}}</div>
        <p :class="$style['ic-content__price']">{{item.price / 100}} ₽</p>
        <a :class="$style['ic-content__button']" href="" v-show="!isInCart" @click.prevent="addToCartWrapper(item.id)">Купить</a>
        <a :class="[ $style['ic-content__button'], $style['ic-content__button--in-cart']]" href="" v-show="isInCart" @click.prevent="actionToggleCartVisibility(true);  actionToggleCartVisiblePage('cart')"><span>Товар в корзине</span>Перейти в корзину</a>

      </div>
      <div :class="$style.imageWrapper">
        <img :class="[$style['item-card__ic-image'], $style['ic-image']]" :src="item.imageWeb ? item.imageWeb : item.image" alf="Фото товара" >
      </div>
    </router-link>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex"
export default {
  name: "ItemCard",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      actionAddItemToCart: "localData/addItemToCart",
      actionToggleCartVisibility: "localData/toggleCartVisibility",
      actionToggleCartVisiblePage: "localData/toggleCartVisiblePage",
      addToCart: 'cart/addToCart'

    }),

    addToCartWrapper() {
      this.addToCart({ ...this.item, count: 1, size: 'm' })
    },
  },
  computed: {
    ...mapGetters({
      cart: "cart/list"
    }),
    isInCart() {
      return this.cart.find(el => el.id === this.item.id)
    },
    removeFromCartWrapper() {

    },
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    }
  },
}
</script>
<style lang="scss" module>
@import "@/styles/_variables";

.item-card-wrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 210px;
  width: 354px;
  overflow: hidden;

  &.mobile  {
    width: 299px;

   
  }
}
.item-card {
    width: 100%;
}
.content_wrapper {
  background: #F4F4F4;
  border-radius: 16px;
  padding: 20px 149px 20px 20px;
}
.ic-image {
  height: 202px;
  // position: absolute;
  // right: 0;
  // bottom: 0;
}

.ic-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 160px;
  padding: 20px;
  border-radius: 16px;
  background: $light-grey;
}

.ic-content__heading {
  margin: 0;
  color: $light-black;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ic-content__price {
  display: flex;
  margin: 8px 0 0;
  font-size: 16px;
  line-height: 19px;
  color: $dark-grey;
}

.ic-content__button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 22px 0 0;
  width: 100%;
  height: 44px;
  max-width: 185px;
  background: $blue;
  color: $white;
  border-radius: 12px;
  border: none;
}

.ic-content__button--in-cart {
  background: none;
  position: relative;
  margin: 42px 0 0;
  padding: 0;
  width: max-content;
  color: $blue;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  & span {
    position: absolute;
    top: -12px;
    left: 25px;
    width: inherit;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

.imageWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 129px;

}

</style>