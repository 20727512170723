<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Отзывы:
            </div>
            <div v-if="!isMobile" :class="$style.buttons">
                <div :class="$style.scrollButton" @click="scrollToLeft">
                    <img :src="require('./assets/arrow-left.png')"/>
                </div>
                <div :class="$style.scrollButton" @click="scrollRight">
                    <img :src="require('./assets/arrow-right.png')"/>
                </div>
            </div>
            <div :class="$style.reviews" ref="reviews">
                <div>
                    <div ref="slideWrapper">
                        <Review name="Сергей Королев" text="Потрясающий материал, искреннее и доступное изложение правды как она есть. Пора перестать бояться повсеместно насаждающей СМИ лжи, пора встать одним единым строем всем Миром и изменить сложившуюся ситуацию, оставив страх и невежество позади! Мы создаём свой Мир здесь и сейчас своими руками. Рекомендую книгу к срочному прочтению каждому!"/>
                    </div>
                    <div ref="slideWrapper">
                    <Review name="solwest" text="Автор изумительно предоставил информацию. Разложил всё по полочкам. Данную информацию посвятить бы всех граждан нашей необъятной страны. Здоровья всем!!! Советую прочитать."/>
                    </div>
                    <!-- <div ref="slideWrapper">
                        <Review name="solwest" text="Автор изумительно предоставил информацию. Разложил всё по полочкам. Данную информацию посвятить бы всех граждан нашей необъятной страны. Здоровья всем!!! Советую прочитать."/>
                    </div>
                    <div ref="slideWrapper">
                        <Review name="solwest" text="Автор изумительно предоставил информацию. Разложил всё по полочкам. Данную информацию посвятить бы всех граждан нашей необъятной страны. Здоровья всем!!! Советую прочитать."/>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Review from './Review'
import gsap from 'gsap'
export default {
    components: { Review },
    data() {
        return {
            scrollLeft: 0
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    },
    methods: {
        scrollToLeft() {
            let firstChildWidth = this.$refs['slideWrapper'].children[0].offsetWidth;
			if (this.$refs['reviews'].scrollLeft > firstChildWidth) {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: this.$refs['reviews'].scrollLeft - firstChildWidth, duration: 0.3})
			} else {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: 0, duration: 0.3})
				this.scrollLeft = 0
			}
        },
        scrollRight() {
            let firstChildWidth = this.$refs['slideWrapper'].children[0].offsetWidth;
			if (this.$refs['reviews'].scrollLeft < this.$refs['slideWrapper'].offsetWidth - firstChildWidth) {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: this.$refs['reviews'].scrollLeft + firstChildWidth, duration: 0.3})
				this.scrollLeft = this.$refs['reviews'].scrollLeft + firstChildWidth
			} else {
				gsap.fromTo(this.$refs['reviews'], {scrollLeft: this.$refs['reviews'].scrollLeft}, {scrollLeft: this.$refs['reviews'].offsetWidth, duration: 0.3})
				this.scrollLeft = 'max';
			}
        }
    }
}
</script>
<style lang="scss" module>
    
    .main {
        background: #EEF6FE;
        width: 100%;

    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;
        
        .mobile & {
            width: calc(100% - 16px);
            padding: 40px 0 40px 16px;
        }
    }

    .reviews {
        display: flex;
        overflow-x: scroll;
        margin-top: 32px;
        & > div {
            display: flex;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }
    .scrollButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: linear-gradient(91.12deg, #0065E3 0%, #5ECFFF 100%);
        border-radius: 25px;
        margin-left: 8px;
        cursor: pointer;
    }

    .title {
        font-size: 34px;
        line-height: 84px;
        font-weight: bold;
        letter-spacing: 0.278974px;

        color: #333333;

        .mobile & {
            font-size: 24px;
            line-height: 28px;
        }
    }

</style>