var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.tile,  _vm.isMobile ? _vm.$style.mobile : '']},[_c('img',{attrs:{"src":[
            require('./assets/microbe.png'),
            require('./assets/face-with-thermometer.png'),
            require('./assets/pill.png'),
            require('./assets/person-in-lotus-position.png'),
            require('./assets/face-with-monocle.png'),
            require('./assets/face-with-medical-mask.png')
        ][_vm.img]}}),_c('div',{class:_vm.$style.tileText},[_vm._v(" "+_vm._s(_vm.text)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }