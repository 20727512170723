<template>
	<ul class="breadcrumbs">
		<li class="breadcrumbs__item" v-for="(item, key) in breadcrumbs" :key="key">
			<router-link
				:to="breadcrumbs
					.filter((el, id) => id <= key)
					.map((el) => el.link)
					.join('/') || '/'"
				>
				{{ breadcrumbs.length - 1 > key ? decodeURI(item.name) + " >" : decodeURI(item.name) }}
			</router-link>
		</li>
	</ul>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
	name: "Breadcrumbs",
	props: {
		links: { type: Array, required: true },
		name: { type: String, required: false, default: "name" },
		link: { type: String, required: false, default: "link" },
	},
	data() {
		return {
		tempPath: "",
		breadcrumbs: [],
		linkList: [],
		};
	},
	methods: {
		generateBreadcrumbs() {
		this.linkList = this.defaultLinks.concat(
			this.links.map((el) => ({ name: el.name, link: el.id }))
		);
		this.breadcrumbs = this.route.fullPath.split("/").map(function (el) {
			return {
			//name = label of linkList elem with current link / current link if no elem found
			name: this.linkList.find((item) => item[this.link] === el)
				? this.linkList.find((item) => item[this.link] === el)[this.name]
				: el,
			link: el,
			};
		}, this);
		},
	},
	computed: {
		...mapState({
		router: function () {
			return this.$router;
		},
		...mapGetters({
			defaultLinks: "serverData/links",
		}),
		route: function () {
			return this.$route;
		},
		}),
	},
	created() {
		this.generateBreadcrumbs();
	},
	watch: {
		links() {
			this.generateBreadcrumbs();
		}
	}
};
</script>
