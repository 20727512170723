<template>
<div class="course-bought">
  <h2 class="course-bought__cb-heading">Курс "{{item.label}}"</h2>
  <div class="course-bought__cb-content cb-content">
    <img :src="require('@/assets/img/order-check.svg')" class="cb-content__check" />
    <h3 class="cb-content__heading">Подписка оформлена</h3>
    <p class="cb-content__caption">Подписка действительна до 21.10.2021</p>
    <router-link class="cb-content__button" :to="item.link">Перейти к курсу</router-link>
  </div>
</div>
</template>

<script>
export default {
  name: 'CourseBought',
  props: {
    item: {
      type: Object,
      required: true
    },
  }
}
</script>
