<template>
    <div :class="$style.main">
        <MainSection id="part1" @buy="$emit('buy')"/>
        <Header />
        <WhatYouKnow id="part2"/>
        <ProgrammSection id="part3"/>
        <ProgramsSection id="part4" />
        <AboutAuthor id="part5"/>
        <WhySections id="part6"/>
        <ReviewSection id="part7"/>
        <RegistrationSection id="part8" :priceProp="price" @update="$emit('update')"/>
        <Footer />
    </div>
</template>
<script>

import MainSection from './MainSection.vue'
import WhatYouKnow from './WhatYouKnow.vue'
import ProgrammSection from './ProgrammSection.vue'
import ProgramsSection from './ProgramsSection.vue'
import AboutAuthor from './AboutAuthor.vue'
import ReviewSection from './ReviewSection'
import WhySections from './WhySections.vue'
import RegistrationSection from './RegistrationSection.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import Header from './header.vue'
import Footer from './Footer.vue'

export default {
    props: {
        price: {
            type:Number
        }
    },
    components: {
        MainLayout,
        MainSection,
        WhatYouKnow,
        ProgrammSection,
        AboutAuthor,
        ReviewSection,
        WhySections,
        RegistrationSection,
        Header,
        Footer,
        ProgramsSection
    }
}
</script>

<style lang="scss" module>

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat;
}

</style>