export default {
	namespaced: true,
	state: {
		orderData: {},
		promocode: {},
		userData: {},
		userBuyings: [],
		help: [{
			name: "Что такое Мон Тирей",
			text: "1"
		},
		{
			name: "Зачем нужна подписка",
			text: "1"
		},
		{
			name: "Забыл пароль",
			text: "Если вы забыли пароль попробуйте восстановить его через электронную почту или обратитесть в службу технической поддержки"
		},
		{
			name: "Что такое Мон Тирей",
			text: "1"
		},
		{
			name: "Зачем нужна подписка",
			text: "1"
		}
		],
		courseContent: {
			1: [{
				id: 0,
				name: 'Предисловие',
				content: [{
					id: 1,
					name: 'Название и предисловие',
					length: "32:19",
					text: ''
				},
				{
					id: 2,
					name: 'Пролог',
					length: "32:19",
					text: ''
				}
				]
			},
			{
				id: 1,
				name: 'Глава 1. Вселенная. История вглядов.',
				content: [{
					id: 1,
					name: 'Взгляды мыслителей Древнего мира на устройство Вселенной. (IV тысячелетие до н.э. – I век н.э.)',
					length: "32:19",
					text: '1'
				},
				{
					id: 2,
					name: 'Представления философов и астрономов средних веков о Вселенной. (II век – XVII век)',
					length: "32:19",
					text: '2'
				},
				{
					id: 3,
					name: 'Развитие взглядов на Вселенную в ХIХ - ХХ вв.',
					length: "32:19",
					text: '3'
				},
				{
					id: 4,
					name: 'Космические «жители» Вселенной',
					length: "32:19",
					text: '4'
				},
				{
					id: 5,
					name: 'Звезды, планеты и их спутники',
					length: "32:19",
					text: '5'
				}
				]
			}
			],
			2: [{
				id: 0,
				name: 'Предисловие',
				content: [{
					id: 1,
					name: 'Название и предисловие',
					length: "32:19",
					text: ''
				},
				{
					id: 2,
					name: 'Пролог',
					length: "32:19",
					text: ''
				}
				]
			},
			{
				id: 1,
				name: 'Глава 1. Вселенная. История вглядов.',
				content: [{
					id: 1,
					name: 'Взгляды мыслителей Древнего мира на устройство Вселенной. (IV тысячелетие до н.э. – I век н.э.)',
					length: "32:19",
					text: '1'
				},
				{
					id: 2,
					name: 'Представления философов и астрономов средних веков о Вселенной. (II век – XVII век)',
					length: "32:19",
					text: '2'
				},
				{
					id: 3,
					name: 'Развитие взглядов на Вселенную в ХIХ - ХХ вв.',
					length: "32:19",
					text: '3'
				},
				{
					id: 4,
					name: 'Космические «жители» Вселенной',
					length: "32:19",
					text: '4'
				},
				{
					id: 5,
					name: 'Звезды, планеты и их спутники',
					length: "32:19",
					text: '5'
				}
				]
			}
			]
		},
		countries: [{
			label: "Россия",
			id: 1
		}, {
			label: "Украина",
			id: 2
		}, {
			label: "Беларусь",
			id: 3
		}],
		cities: [{
			label: "Москва",
			id: 1,
			countryId: 1
		},
		{
			label: "Казань",
			id: 2,
			countryId: 1
		},
		{
			label: "Киев",
			id: 1,
			countryId: 2
		},
		{
			label: "Одесса",
			id: 2,
			countryId: 2
		},
		{
			label: "Минск",
			id: 1,
			countryId: 3
		},
		{
			label: "Витебск",
			id: 2,
			countryId: 3
		}

		],
		delivery: [{
			id: 1,
			label: "Курьерская доставка",
			price: 29900
		},
		{
			id: 2,
			label: "Доставка первым классом",
			price: 59900
		}
		],
		links: [{
			name: "Главная",
			link: ""
		},
		{
			name: "Категории",
			link: "categories"
		},
		{
			name: "Вебинары",
			link: "webinars"
		},
		{
			name: "Курсы ",
			link: "courses"
		},
		{
			name: "Новости",
			link: "news"
		},
		{
			name: "Товары",
			link: "items"
		}
		],
		languages: [{
			id: 1,
			label: "Русский",
			image: "lang-ru.svg"
		},
		{
			id: 2,
			label: "English",
			image: "lang-en.svg"
		}
		],
		categories: [{
			id: 1,
			name: "Мироздание",
			image: "categoryMirozdanie.png",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "mirozdanie"
		},
		{
			id: 2,
			name: "Духовные практики",
			image: "categoryDuhovniyePraktiki.png",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "duhovniye-praktiki"
		},
		{
			id: 3,
			name: "Физические практики",
			image: "categoryFizichiskiyePraktiki.png",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "fizichiskiye-praktiki"
		},
		{
			id: 4,
			name: "Искусство",
			image: "categoryIskusstvo.png",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "iskusstvo"
		},
		{
			id: 5,
			name: "Самореализация",
			image: "categorySamorealizaciya.png",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "samorealizaciya"
		},
		{
			id: 6,
			name: "Психология",
			image: "categoryPsychologiya.png",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "psychologiya"
		},
		{
			id: 7,
			name: "Дети",
			image: "",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "deti"
		},
		{
			id: 8,
			name: "Питание",
			image: "",
			caption: "Как создавалась Вселенная и какое место в ней занимает человек",
			link: "pitaniye"
		}
		],
		news: [{
			name: "Название в одну строку",
			fullName: "Полное название в одну строку / Полное название в одну строку",
			views: 3895,
			time: "14:36",
			image: "NewsCardPhoto.png",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			link: "1"
		},
		{
			name: "Название в одну строку",
			fullName: "Полное название в одну строку / Полное название в одну строку",
			views: 3895,
			time: "14:36",
			image: "NewsCardPhoto.png",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			link: "2"
		},
		{
			name: "Название в одну строку",
			fullName: "Полное название в одну строку / Полное название в одну строку",
			views: 3895,
			time: "14:36",
			image: "NewsCardPhoto.png",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			link: "3"
		},
		{
			name: "Название в одну строку",
			fullName: "Полное название в одну строку / Полное название в одну строку",
			views: 3895,
			time: "14:36",
			image: "NewsCardPhoto.png",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания",
			link: "4"
		},
		{
			name: "Название в одну строку",
			fullName: "Полное название в одну строку / Полное название в одну строку",
			views: 3895,
			time: "14:36",
			image: "NewsCardPhoto.png",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания        ",
			link: "5"
		},
		{
			name: "Название в одну строку",
			fullName: "Полное название в одну строку / Полное название в одну строку",
			views: 3895,
			time: "14:36",
			image: "NewsCardPhoto.png",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания",
			link: "6"
		}
		],
		webinars: [{
			categoryId: 1,
			name: "1 Название вебинара в одну строку",
			fullName: "Полное название вебинара",

			price: 149,
			image: "WebinarCardPhoto.png",
			isBought: false,
			id: 1,
			date: "25 октября в 19:00",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			online: false,
			author: {
				name: "Иванов Иван Иванович",
				description: "Описание",
				image: "author-avatar.png"
			},
			link: "1"
		},
		{
			categoryId: 1,
			name: "2 Название вебинара в одну строку",
			fullName: "Полное название вебинара",
			price: 149,


			image: "WebinarCardPhoto.png",
			isBought: false,
			id: 2,
			date: "25 октября в 19:00",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",

			online: true,
			author: {
				name: "Иванов Иван Иванович",
				description: "Описание",
				image: "author-avatar.png"
			},
			link: "2"
		},
		{
			categoryId: 2,
			name: "3 Название вебинара в одну строку",
			fullName: "Полное название вебинара",
			price: 149,


			image: "WebinarCardPhoto.png",
			isBought: false,
			id: 3,
			date: "25 октября в 19:00",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",

			online: false,
			author: {
				name: "Иванов Иван Иванович",
				description: "Описание",
				image: "author-avatar.png"
			},
			link: "3"
		},
		{
			categoryId: 2,
			name: "4 Название вебинара в одну строку",
			fullName: "Полное название вебинара",
			price: 149,


			image: "WebinarCardPhoto.png",
			isBought: false,
			id: 4,
			date: "25 октября в 19:00",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",

			online: false,
			author: {
				name: "Иванов Иван Иванович",
				description: "Описание",
				image: "author-avatar.png"
			},
			link: "4"
		},
		{
			categoryId: 1,
			name: "5 Название вебинара в одну строку",
			fullName: "Полное название вебинара",
			price: 149,


			image: "WebinarCardPhoto.png",
			isBought: false,
			id: 5,
			date: "25 октября в 19:00",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",

			online: false,
			author: {
				name: "Иванов Иван Иванович",
				description: "Описание",
				image: "author-avatar.png"
			},
			link: "5"
		},
		{
			categoryId: 3,
			name: "6 Название вебинара в одну строку",
			fullName: "Полное название вебинара",
			price: 149,


			image: "WebinarCardPhoto.png",
			isBought: false,
			id: 6,
			date: "25 октября в 19:00",

			text: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			online: false,
			author: {
				name: "Иванов Иван Иванович",
				description: "Описание",
				image: "author-avatar.png"
			},
			link: "6"
		}
		],
		courses: [{
			id: 1,
			categoryId: 1,
			name: "Сотворение мира",
			image: "courseSotvoreniye.png",
			price: 149,
			isBought: false,
			description: "Как была создана Вселенная и по каким законам она существует",
			link: "sotvoreniye"
		},
		{
			id: 2,
			categoryId: 1,
			name: "Вселенная",
			image: "courseVselennaya.png",
			isBought: false,
			description: "Как была создана Вселенная и по каким законам она существует",
			price: 149,
			link: "vselennaya"
		}, {
			id: 3,
			name: "Человек",
			categoryId: 1,
			image: "courseChelovek.png",
			isBought: false,
			description: "Как была создана Вселенная и по каким законам она существует",
			price: 149,
			link: "chelovek"
		}, {
			id: 4,
			categoryId: 2,
			name: "Человек",
			image: "courseChelovek.png",
			isBought: false,
			description: "Как была создана Вселенная и по каким законам она существует",
			price: 149,
			link: "4"
		}, {
			id: 5,
			categoryId: 2,
			name: "Человек",
			image: "courseChelovek.png",
			isBought: false,
			description: "Как была создана Вселенная и по каким законам она существует",
			price: 149,
			link: "5"
		}
		],
		items: [{
			id: 1,
			name: "Сотворение мира",
			image: "itemSotvoreniye.png",
			price: 999,
			description: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			link: "sotvoreniye"
		},
		{
			id: 2,
			name: "Вселенная",
			image: "itemVselennaya.png",
			description: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			price: 999,
			link: "vselennaya"
		}, {
			id: 3,
			name: "Человек",
			image: "itemChelovek.png",
			description: "🧬 CRISPR CAS9 -- инновационная технология в генной инженерии, с помощью которой редактирование генома человека становится подобным сборке конструктора LEGO. В будущем генная терапия на основе CRISPR CAS9 позволит победить наследственные заболевания.",
			price: 999,
			link: "chelovek"
		}
		]
	},
	mutations: {
		SET_ORDER_DATA(state, value) {
			return state.orderData = value;
		},
		SET_USER_BUYINGS(state, value) {
			return state.userBuyings = value;
		},
		ADD_USER_BUYINGS(state, value) {
			return state.userBuyings.push(value);
		},
		SET_USER_DATA(state, value) {
			return state.userData = value;
		},
		SET_LANGUAGES(state, value) {
			return state.languages = value;
		},
		TOGGLE_WEBINAR_IS_BOUGHT(state, {
			categoryId,
			webinarId
		}) {
			return state.webinars.find(el => (el.categoryId === categoryId && el.id === webinarId)).isBought = true;
		},
		TOGGLE_COURSE_IS_BOUGHT(state, {
			categoryId,
			courseId
		}) {
			return state.courses.find(el => (el.categoryId === categoryId && el.id === courseId)).isBought = true;
		},
		SET_PROMOCODE(state, value) {
			let promocodes = [{
				price: 500,
				value: "pt"
			}, {
				price: 800,
				value: "vt"
			}]
			return state.promocode = promocodes.find(el => el.value === value);
		}
	},
	actions: {
		toggleWebinarIsBought({commit}, {categoryId, webinarId}) {
			commit("TOGGLE_WEBINAR_IS_BOUGHT", { categoryId, webinarId })
		},
		toggleCourseIsBought({commit}, {categoryId,courseId}) {
			commit("TOGGLE_COURSE_IS_BOUGHT", { categoryId, courseId })
		},
		setUserBuyings({commit}, value) {
			commit("SET_USER_BUYINGS", value)
		},
		addUserBuying({commit}, value) {
			commit("ADD_USER_BUYINGS", value)
		},
		setOrderData({commit}, value) {
			commit("SET_ORDER_DATA", value)
		},
		setUserData({commit}, value) {
			commit("SET_USER_DATA", value)
		},
		setLanguages({commit}, value) {
			commit("SET_LANGUAGES", value)
		},
		usePromocode({commit}, value) {
			//axios use promocode
			//200 => object {price: x}
			//404 => axios.catch
			commit("SET_PROMOCODE", value)
		},
		

	},
	getters: {
		links: state => state.links,
		userData: state => state.userData,
		userBuyings: state => state.userBuyings,
		orderData: state => state.orderData,
		promocode: state => state.promocode,
		countries: state => state.countries,
		courseContent: state => state.courseContent,
		cities: state => state.cities,
		delivery: state => state.delivery,
		languages: state => state.languages,
		categories: state => state.categories,
		courses: state => state.courses,
		webinars: state => state.webinars,
		news: state => state.news,
		items: state => state.items,
		help: state => state.help,
	}
}
