<template>
<div class="item-slider">
  <h2 class="item-slider__heading">Покупки</h2>
  <router-link class="item-slider__all" to="/items">Смотреть все</router-link>
    <Slider>
      <ItemCard v-for="(item, key) in list" :key="key" class="is-slides__item" :item="item" />
    </Slider>
</div>
</template>

<script>
import Slider from  '@/components/Slider'
import { vfirestore } from '@/db.js'
import {
  mapGetters
} from 'vuex'
import ItemCard from '@/components/ItemCard.vue'
export default {
  name: "ItemSlider",
  components: {
    ItemCard,
    Slider
  },
  computed: {
    ...mapGetters({
      list: 'products/list'
    }),
  },
}
</script>
