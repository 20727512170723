<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.title">
            Что вы узнаете на вебинаре?
        </div>
        <div :class="$style.content">
            <Tile text="Что такое вирусы и как с ними бороться" :img="0"/>
            <Tile text="Как укрепить иммунитет и избежать осложнений от вирусных инфекций" :img="4"/>
            <Tile text="Что делать если Вы заболели COVID-19?" :img="1"/>
            <Tile text="Какими препаратами эффективно лечить вирусные заболевания" :img="2"/>
            <Tile text="Эффективные методы реабилитации после COVID-19" :img="3"/>
            <Tile text="Как жить полноценной жизнью в период пандемии" :img="5"/>
        </div>
    </div>
</template>
<script>
import Tile from './Tile.vue'
export default {
    components: { Tile },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>

    .main {
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        &.mobile {
            width: calc(100% - 32px);
            padding: 40px 16px;
        }
    }
    .title {
        font-family: NoirPro-Bold;
        font-size: 34px;
        line-height: 84px;
        letter-spacing: 0.278974px;
        font-weight: bold;
        color: #333333;
        
        .mobile & {
            font-size: 24px;
            line-height: 28px;
        }

    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .mobile & {
            flex-direction: column; 
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }


</style>