export default {
  namespaced: true,
  state: {
    languageSelectVisibility: false,
    currentLanguageId: 1,
    cart: [],
    cartVisibility: false,
    cartVisiblePage: 'cart',
    personalDataVisibility: false,
    personalDataVisiblePage: 'login',
    personalDataPreviousPage: '',
    isLoggedIn: false,
    submitFormVisibility: false,
    buyingDigitalItem: {},
    player: {
      length: 25,
      volume: 70
    },
    isPlaying: false,
    languages: [{
        id: 1,
        label: "Русский",
        image: require('@/assets/img/lang-ru.svg')
      },
      {
        id: 2,
        label: "English",
        image: require("@/assets/img/lang-en.svg")
      }
    ]
  },
  mutations: {
    CHANGE_PLAYER_TIME(state, value) {
      return state.player.length = value
    },
    CHANGE_PLAYER_VOLUME(state, value) {
      return state.player.volume = value
    },
    SET_CURRENT_LANGUAGE_ID(state, id) {
      return state.currentLanguageId = id
    },
    TOGGLE_LANGUAGE_SELECT_VISIBILITY(state, value) {
      return state.languageSelectVisibility = value ? value : !state.languageSelectVisibility
    },
    TOGGLE_CART_VISIBILITY(state, value) {
      return state.cartVisibility = value
    },
    TOGGLE_CART_PAGE(state, value) {
      return state.cartVisiblePage = value
    },
    TOGGLE_IS_PLAYING(state, value) {
      return state.isPlaying = value
    },
    SET_BUYING_DIGITAL_ITEM(state, value) {
      return state.buyingDigitalItem = value
    },
    TOGGLE_SUBMIT_FORM_VISIBILITY(state, value) {
      return state.submitFormVisibility = value
    },
    ADD_ITEM_TO_CART(state, id) {
      let itemIndex = state.cart.indexOf(state.cart.find(el => el.id === id))
      if (itemIndex < 0) return state.cart.push({
        id: id,
        amount: 1
      });
      state.cart[itemIndex].amount++;
    },
    REMOVE_ITEM_FROM_CART(state, id) {
      let itemIndex = state.cart.indexOf(state.cart.find(el => el.id === id))
      if (itemIndex < 0 || state.cart[itemIndex].amount == 1) return state.cart.splice(state.cart.indexOf(state.cart.find(el => el.id === id)), 1);
      state.cart[itemIndex].amount--;
    },
    TOGGLE_IS_LOGGED_IN(state, value) {
      return state.isLoggedIn = value
    },
    TOGGLE_PERSONAL_DATA_PAGE(state, value) {
      console.log(value)
      if (!value) value = state.personalDataPreviousPage
      state.personalDataPreviousPage = state.personalDataVisiblePage
      return state.personalDataVisiblePage = value
    },
    TOGGLE_PERSONAL_DATA_VISIBILITY(state, value) {
      console.log('value1', value)
      return state.personalDataVisibility = value
    }
  },
  actions: {
    changePlayerTime({
      commit
    }, value) {
      commit("CHANGE_PLAYER_TIME", value)
    },
    changePlayerVolume({
      commit
    }, value) {
      commit("CHANGE_PLAYER_VOLUME", value)
    },
    setCurrentLanguageId({
      commit
    }, id) {
      commit("SET_CURRENT_LANGUAGE_ID", id)
    },
    toggleCartVisibility({
      commit
    }, value) {
      commit("TOGGLE_CART_VISIBILITY", value)
    },
    toggleCartVisiblePage({
      commit
    }, value) {
      commit("TOGGLE_CART_PAGE", value)
    },
    toggleIsPlaying({
      commit
    }, value) {
      commit("TOGGLE_IS_PLAYING", value)
    },
    setBuyingDigitalItem({
      commit
    }, value) {
      commit("SET_BUYING_DIGITAL_ITEM", value)
    },
    toggleSubmitFormVisibility({
      commit
    }, value) {
      commit("TOGGLE_SUBMIT_FORM_VISIBILITY", value)
    },
    addItemToCart({
      commit
    }, id) {
      commit("ADD_ITEM_TO_CART", id)
    },
    removeItemFromCart({
      commit
    }, id) {
      commit("REMOVE_ITEM_FROM_CART", id)
    },
    toggleIsLoggedIn({
      commit
    }, value) {
      commit("TOGGLE_IS_LOGGED_IN", value)
    },
    togglePersonalDataVisiblePage({
      commit
    }, value) {
      commit("TOGGLE_PERSONAL_DATA_PAGE", value)
    },
    togglePersonalDataVisibility({
      commit
    }, value) {
      commit("TOGGLE_PERSONAL_DATA_VISIBILITY", value)
    },
    toggleLanguageSelectVisibility({
      commit
    }, value) {
      commit("TOGGLE_LANGUAGE_SELECT_VISIBILITY", value)
    },
  },
  getters: {
    player: state => state.player,
    languages: state => state.languages,
    isLoggedIn: state => state.isLoggedIn,
    personalDataVisibility: state => state.personalDataVisibility,
    personalDataVisiblePage: state => state.personalDataVisiblePage,
    personalDataPreviousPage: state => state.personalDataPreviousPage,
    languageSelectVisibility: state => state.languageSelectVisibility,
    currentLanguageId: state => state.currentLanguageId,
    cartVisibility: state => state.cartVisibility,
    cartVisiblePage: state => state.cartVisiblePage,
    isPlaying: state => state.isPlaying,
    buyingDigitalItem: state => state.buyingDigitalItem,
    cart: state => state.cart,
    amountInCart: state => {
      let amount = 0;
      state.cart.forEach(el => amount += el.amount)
      return amount;
    }
  }
}
