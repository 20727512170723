<template>
	<div :class="$style.main">
		<h2 :class="$style.heading">Выберите категорию</h2>
		<router-link class="category-slider__all" to="/categories">Смотреть все</router-link>
		
		<Slider>
			<CategoryCard  v-for="(item, key) in list"  :key="key" :class="$style.item" :category="item ? item : {}" />
		</Slider>
	</div>
</template>

<script>
import { api } from '@/api/index.js'
import CategoryCard from '@/components/CategoryCard.vue'
import Slider from '@/components/Slider'
import { mapGetters } from 'vuex'
export default {
	name: "CategorySlider",
	components: {
		CategoryCard,
		Slider
	},
	computed: {
		...mapGetters({
			list: 'categories/list'
		}),
		canSlideToPrev: function() {
			return this.scrollLeft != 0
		},
		canSlideToNext: function() {
			return this.scrollLeft != 'max'
		},
	},
}
</script>

<style lang="scss" module>

@import "@/styles/_variables";

.main {	
	position: relative;
	display: flex;
	flex-flow: row wrap;
	width: 100%;
}

.heading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: min-content;
	margin-bottom: 15px;
	font-weight: 500;
	font-size: 28px;
	line-height: 33px;
	text-align: center;

	&::before {
		content: "";
		position: absolute;
		display: flex;
		width: 303px;
		height: 38px;
		background: #FFE175;
		transform: rotate(-2deg);
		z-index: -1;
	}
}
</style>