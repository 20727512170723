<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <a href="#part8">
                <button :class="$style.button">
                    Зарегистрироваться <br v-if="isMobile"> на вебинар
                </button>
            </a>
            <div :class="$style.title">
                ПРОГРАММА ВЕБИНАРА
            </div>
            <div :class="$style.programWrapper">
                <div :class="$style.programm">
                    <div :class="$style.programmTitle">
                        Психосоматика и Стресс
                    </div>
                    <ul :class="$style.list">
                        <li>
                            Разберем что же это такое, и каковы причины возникновения стресса в современном мире.
                        </li>
                        <br>
                        <li>
                            Как стресс и психосоматика проявляются в организме
                        </li>
                    </ul>
                </div>
                <div :class="$style.programm">
                    <div :class="$style.programmTitle">
                        Симптомы расстройств, вызванных стрессом
                    </div>
                    <ul :class="$style.list">
                        <li>
                            Расскажу какие физиологические нарушения возникают при стрессе, какие боли идут от состояния психики, а какие являются реальными патологиями.
                        </li>
                        <br>
                        <li>
                            Психологические расстройства, где они лечаться?
                        </li>
                    </ul>
                </div>
                 <div :class="$style.programm">
                    <div :class="$style.programmTitle">
                        Предупреждать или лечить
                    </div>
                    <ul :class="$style.list">
                        <li>
                            Раcсмотрим какие варианты лечения существуют, как их применять в современном мире. 
                        </li>
                        <br>
                        <li>
                            Что такое немедикаментозное и медикаментозное лечение. 
                        </li>
                        <br>
                        <li>
                            Когда идти в психотерапию?
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Programm from './Programm.vue'
import ReasonTile from './ReasonTile.vue'
export default {
    components: { Programm, ReasonTile },
    data() {
        return {
            programmText: [
                'Начнем со знакомства с вирусами, вы узнаете какие их виды существуют, какие симптомы вызывает каждый из них. Как они появляются и как проникают в наш организм.',
                'Посвящен иммунитету и способам его укрепления. Действительно, ли лук и чеснок помогают укреплять здоровье. Как кишечник и то, что мы едим связаны с иммунной системой.',
                'Расскажу какие лекарства стоит принимать, а какие лишь трата денег. Что и когда делать с температурой. Почему важно разжижать кровь и когда начинать пить антибиотики.'
            ],
            programmItems: [
                ['Виды вирусов', 'Эпидемиология', 'Пути проникновения в организм'],
                ['Что такое иммунитет', '8 факторов, которые влияют на его укрепление', 'Способы профилактики'],
                ['Способы облегчения симптомов', 'Температура. Когда сбивать', 'Рекомендации по лекарствам и их применению', 'Антибиотики']
            ]
        }
    },

    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>
    .main {
        background: linear-gradient(0deg, #9FD7DE 0%, #ffffff 16.04%);
        width: calc( 100% - (100% - 1099px) );
        padding-left: calc((100% - 1099px) / 2);
        padding-right: calc((100% - 1099px) / 2);

        &.mobile {
            width: calc(100% - 32px);
            padding: 0 16px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            padding: 40px 0;
        }
    }
    .programWrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;

        .mobile & {
            margin-top: 13px;
        }
    }
    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 64px;
        text-align: center;
        color: #41757C;

        .mobile & {
            font-size: 24px;
            line-height: 28px;
        }
    }
    .programm {
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        flex-direction: column;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 12px;
        margin-top: 16px;
        width: 799px;
        padding: 28px;

         .mobile & {
            width: 100%;
        }
    }
    .programmTitle {
        font-family: Montserrat-Bold;
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        text-decoration-line: underline;
        color: #E76F51;
        
        .mobile & {
            font-size: 14px;
            line-height: 16px;
        }
    }
    .list {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #41757C;

        .mobile & {
            font-size: 14px;
            line-height: 16px;
        }
    }
    .button {
        width: 729px;
        height: 112px;
        background: #2A9D8F;
        border-radius: 10px;
        margin-top: 46px;

        margin-bottom: 174px;

        font-family: Montserrat-Bold;
        font-weight: bold;
        font-size: 30px;
        line-height: 64px;
        
        color: #FFFFFF;

        .mobile & {
            width: 260px;
            margin: auto;
            height: 60px;
            font-family: Montserrat-Bold;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 47px;
        }
    }
</style>