<template>
	<router-link
		class="course-card"
		:style="backgroundStyle"
		:to="`/categories/${category.id}/courses/${course.id}`"
	>
		<h3 class="course-card__heading">{{course.title}}</h3>
	</router-link>
</template>

<script>
export default {
	name: "CourseCard",
	props: {
		category: { type: Object, required: true },
		course: { type: Object, required: true },
	},
	computed: {
		backgroundStyle() {
			return {
				'background': `url(${this.course.topBg.replaceAll(' ', '%20')}) no-repeat`,
				'background-size': 'cover',
				'background-position-y': 'center',
				'border-radius': '8px'
			};
		}
	},
}
</script>
