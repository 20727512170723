<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Купите вебинар прямо сейчас!
            </div>
            <div v-if="ref == 'zdkdh13mxa3'" :class="$style.price">
                <div :class="$style.oldPrice"> 1990 ₽ </div>
                <span :class="$style.newPrice">1490 ₽</span>
            </div>
            <div v-if="ref != 'zdkdh13mxa3'" :class="$style.price">
                <!-- <div :class="$style.oldPrice"> 1990 ₽ </div> -->
                <span :class="$style.newPrice">{{price}} ₽ </span>
            </div>
            <div :class="$style.info">
            <div :class="$style.inputContainer">
                <input type="text" v-model="name" placeholder="Имя">
            </div>
                <div :class="$style.spacer"></div>
                <div :class="$style.inputContainer">
                <input v-mask="{mask: '9(999) 999-99-99'}" type="text" v-model="phone" placeholder="Телефон">
                </div>
                <div :class="$style.spacer"></div>
                <div :class="$style.inputContainer">
                <input v-mask="emailMask" type="text" v-model="email" placeholder="Электронная почта">
                </div>
            </div>
            <button :class="$style.button" @click="buyWebinar">
                Купить
            </button>
        </div>
        <template v-if="isLoading">
            <div :class=    "$style.loader">
            <Loading />
            </div>
        </template>
        <div :class="$style.alert" v-if="isModalOpen">
            <div :class="$style.alert_content">
                <span>
                    Вам на почту было отправлено письмо <br><br>
                        со ссылкой на вебинар! <br><br><br>

                    Спасибо за покупку!

                </span>
                <button :class="$style.button" @click="isModalOpen = false">
                Ок
            </button>
            </div>
        </div>
    </div>
    
</template>
<script>
import { vFunctions,vfirestore, vAuth } from '@/db.js'
import {pay} from '@/commons/pay.js'
import Loading from '@/components/Loading.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        Loading
    },

    props: {
        price: {
            type:Number
        }
    },
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            isLoading: false,
            isModalOpen: false,
            emailMask: {
                mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
                greedy: false,
                onBeforePaste: function (pastedValue, opts) {
                pastedValue = pastedValue.toLowerCase();
                return pastedValue.replace("mailto:", "");
                },
                definitions: {
                '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
                    casing: "lower"
                }
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            isAuth: 'user/isAuth',
        }),
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
        ref() {
            return this.$route.query ? this.$route.query.ref : '';
        }
    },
    methods: {
        ...mapActions({
            logout: 'user/logout'
        }),
        async buyWebinar() {
            let ref = this.$route.query ? this.$route.query.ref : '';
            
                if (this.isAuth) {
                    this.$emit('buy')
                    return;
                }

                if (this.name && this.phone && this.email) {
                    this.isLoading = true;
                    
                    await vFunctions.httpsCallable('startBuying')({
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        productId: 'ivanovCovid',
                        ref: this.ref ? this.ref : ''
                    });
                    
                    try {
                        let res = await vAuth.createUserWithEmailAndPassword(this.email, '111111')
                        let user = await vAuth
                            .signInWithEmailAndPassword(this.email, '111111')
                            await pay(this.price, user.user.uid, 'ivanovCovid', this.email, "webinar", this.ref, user.user.displayName);
                            this.$emit('update')
                            this.isModalOpen = true;
                    }
                    catch(e) {
                        if (e && e.code == 'auth/email-already-in-use') {
                           try {
                                let user = await vAuth
                                    .signInWithEmailAndPassword(this.email, '111111')
                                    await pay(this.price, user.user.uid, 'ivanovCovid', this.email, "webinar", this.ref, user.user.displayName);
                                    this.$emit('update')
                           } catch(error) {
                               if (error && error.code == 'auth/wrong-password') {
                                alert('Пользователь уже зарегистрирован, авторизуйтесь пожалуйста')
                               } else {
                                    this.logout();
                               }
                           }
                        } else {
                            this.logout();
                            this.$emit('update')
                        }
                    }

                    
                } else {
                    this.isLoading = false;
                    alert('Не все поля заполнены.')
                    
                }
           

            this.isLoading = false;
        },
    },
}
</script>
<style lang="scss" module>
    .main {
        background: linear-gradient(91.12deg, #0065E3 0%, #5ECFFF 100%);
        width: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            width: calc(100% - 32px);
            padding: 40px 16px;
        }
    }

    .title {
        font-family: NoirPro-Bold;
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        letter-spacing: 0.0976408px;
        color: #FFFFFF;

        .mobile & {
            text-align: center;
            font-size: 24px;
            line-height: 28px;
        }
    }

    .subtitle {
        font-family: NoirPro-Regular;
        font-size: 18px;
        line-height: 24px;
        margin-top: 12px;
        text-align: center;
        letter-spacing: 0.0976408px;

        color: #FFFFFF;
    }
    .price {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-top: 17px;

        letter-spacing: 0.0976408px;
        color: #FFFFFF;

        .oldPrice {
            text-decoration-line: line-through;
            margin-right: 4px;
        }
        .newPrice {
            text-decoration-line: none;
            background: #F0163D;
            border-radius: 9px;
            padding: 1px 6px 1px 6px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.0976408px;

            color: #FFFFFF;
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        width: 100%;
        
        .mobile & {
            margin-top: 26px;
            flex-direction: column;
            justify-content: flex-start;
        }

    }

    .inputContainer {
        display: flex;
        width: 100%;
        .mobile & {
            margin-top: 16px;
        }
        input {
            width: 100%;
            border: none;

            background: rgba(255, 255, 255, 0.3);
            border-radius: 12px;

            padding: 14px 16px 14px 16px;
            
            font-size: 18px;
            line-height: 24px;

            letter-spacing: 0.0976408px;

            color: #FFFFFF;

            &::placeholder {
                font-size: 18px;
                line-height: 24px;

                letter-spacing: 0.0976408px;

                color: #FFFFFF;
            }

            &:focus {
                outline: none;
            }
        }
    }

    .spacer {
        width: 20px;
    }
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        width: 359px;
        height: 54px;
        box-shadow: none;
        border: none;
        border-radius: 12px;
        margin-top: 32px;

        font-family: NoirPro-Medium;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.0557948px;
        text-transform: uppercase;

        color: #0065E3;

        .mobile & {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .loader {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .alert {
        background: rgba(0,0,0,0.3);
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .alert_content {
            background: #FFFFFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 400px;
            height: 300px;
            border-radius: 22px;
        }
    }
</style>