<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div>
                <div :class="$style.title">
                    Об авторе:
                </div>
                <div :class="$style.avatar">
                    <img :src="require('./assets/avatar.png')">
                    Доктор Денис Иванов
                </div>
                <div :class="$style.list">
                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Доктор медицинских наук
                    </div>


                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Профессор кафедры “Общая патология”
                    </div>


                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Член-корреспондент Российской Академии 
                        Естествознания (РАЕ)
                    </div>


                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Член-корреспондент Академии Медико-Технических 
                        Наук (АМТН)
                    </div>


                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Академик Европейской Академии Естественных Наук (ЕАЕН)
                    </div>


                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Заслуженный деятель науки и образования
                    </div>
                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Почетный изобретатель Академии Медико-Технических 
                        Наук (АМТН)
                    </div>
                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Специалист категории “А” в КНР
                    </div>
                    <div :class="$style.listItem">
                        <div :class="$style.circle">
                            <img :src="require('./assets/arrow.png')">
                        </div>
                        Автор книги «ОчеCOVIDный обман. Афера на здоровье»
                    </div>
                </div>
            </div>
            <div :class="$style.videoWrapper">
                <div v-if="!isPlay" :class="[$style.video, $style.videoPreview]">
                    <img :src="require('./assets/play.png')" @click="isPlay = true">
                </div>
                <div v-if="isPlay" :class="$style.video">
                    <video :width="screenWidth" :height="screenWidth / 1.23" controls autoplay> 
                        <source src="https://firebasestorage.googleapis.com/v0/b/montyrey-7ce68.appspot.com/o/promo%2F%D0%9F%D1%80%D0%B8%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%B2%D0%B5%D0%B1%D0%B8%D0%BD%D0%B0%D1%80.mp4?alt=media&token=577a6026-8ba2-48d3-a5ab-1502ff41e849" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isPlay: false
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
        screenWidth() {
            return this.isMobile ? (window.screen.width - 32) : 549 
        }
    }
}
</script>
<style lang="scss" module>

    .main {
        width: 100%;
        
        &.mobile {
            width: calc(100% - 32px);
            padding: 0 16px;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            flex-wrap: wrap;
            padding: 40px 0;
        }
    }

    .title {
        font-size: 34px;
        line-height: 84px;

        letter-spacing: 0.278974px;
        
        font-weight: bold   ;
        color: #333333;
    }

    .avatar {
        display: flex;
        align-items: center;
        font-style: normal;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.0976408px;
        color: #333333;

        img {
            width: 80;
            height: 80;
            border-radius: 40px;
            margin-right: 20px;
        }
    }

.listItem {
    display: flex;
    margin-top: 12px;
    font-family: NoirPro-Regular;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #333333;


}
.circle {
    margin-right: 12px; 
}
.video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 549px;
    height: 446px;

    background: #F1F5F9;
    border-radius: 24px;

    .mobile & {
        width: 100%;
        height: 266px;
    }

    &.videoPreview {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/montyrey-7ce68.appspot.com/o/webinars%2Fimages%2F%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202021-12-01%20%D0%B2%2007.47.34.png?alt=media&token=abf63ad1-d3bd-4b9c-9157-8d2063c7cca2");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.videoWrapper { 
    .mobile & {
        width: 100%;
        margin-top: 32px;
    }
}
</style>