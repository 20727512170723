<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        
        <div :class="$style.title">
            Для сравнения:
        </div>
        <div v-if="!isMobile" style="height: 45px;"></div>
        <div v-if="isMobile" style="height: 16px;"></div>
        <div :class="$style.reason">
            Консультация сексолога 1 час  стоит <br>
            от 5 000 до 15 000 рублей
        </div>

        <div v-if="!isMobile" style="height: 75px;"></div>
        <div v-if="isMobile" style="height: 16px;"></div>
        <div :class="$style.reason">
            Моя личная консультация 1 час стоит <br>
            10 000 рублей
        </div>
        <div v-if="!isMobile" style="height: 45px;"></div>
        <div v-if="isMobile" style="height: 20px;"></div>
        <div :class="$style.price">
            Решить проблемы с сексом в<br>
            отношениях <br v-if="isMobile"> за трёхчасовой эфир<br>
            <span :class="$style.oldPrice">15 000 руб.</span>
        </div>
        <div style="height: 45px;"></div>
        <button :class="$style.button">
            2 490 рублей
        </button>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

 
    .title {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 76px;
        letter-spacing: 0.278974px;

        color: #D75B3F;

        .mobile & {
            font-size: 20px;
            line-height: 40px;
        }
    }

    .reason {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 54px;
        /* or 135% */

        text-align: center;

        color: #FFFFFF;

        .mobile & {
            font-size: 14px;
            line-height: 26px;
        }

    }

    .price {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 62px;
        /* or 141% */

        text-align: center;

        color: #D75B3F;

        .mobile & {
            font-size: 16px;
            line-height: 26px;
        }

    }
    .button {
        background: transparent;
        width: 540px;
        height: 96px;
        border: 1px solid #FFFFFF;
        border-radius: 24px;


        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 62px;
        /* or 141% */

        text-align: center;

        color: #D75B3F;

        .mobile & {
            font-size: 16px;
            line-height: 26px;
            width: 200px;
            height: 47px;
        }
    }

    .oldPrice {
        color: #DBDBDB;
        text-decoration: line-through;
    }

</style>