export default {
    addToCart({ commit }, payload) {
        commit('addToCart', payload)
    },
    plusItemToCart({commit} ,id) {
        commit('plusItemToCart', id)
    },
    minusItemFromCart({commit} ,id) {
        commit('minusItemFromCart', id)
    },
    removefromCart({ commit, state }, id) {
        commit('updateCart', state.cart.filter(item => item.id !== id))
        commit('updateCart', id)
    },
    deleteFromCart({ commit, state }, id) {
        commit('updateCart', state.cart.filter(item => item.id !== id))
    }
}