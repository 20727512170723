<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']" :style="{width: screenWidth}">
        <div :class="$style.row"> 
            <div :class="$style.name">
                {{ name }}
            </div>
            <div :class="$style.start">
                <img :src="require('./assets/star.svg')"/>
                <img :src="require('./assets/star.svg')"/>
                <img :src="require('./assets/star.svg')"/>
                <img :src="require('./assets/star.svg')"/>
                <img :src="require('./assets/star.svg')"/>
            </div>
        </div>
        <div :class="$style.text">
            {{text}}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String
        },
        text: {
            type: String
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
        screenWidth() {
            return this.isMobile ? (window.screen.width - 36) + 'px' : '492px' 
        }
    },
    
}
</script>
<style lang="scss" module>

    .main {
        display: flex;
        flex-direction: column;
        width: 492px;
        padding: 32px 24px;
        background: #FFFFFF;
        border: 1px solid #ACC7E2;
        box-sizing: border-box;
        border-radius: 24px;
        margin-right: 20px;

        &.mobile {
            margin-right: 8px;
        }
    }
    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
.name {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #333333;
}
.start {
    display: flex;
}
.text {
    margin-top: 20px;
    font-family: NoirPro-Light;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #333333;
}
</style>
