<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']" :style="{'background': getColor}">
        <div :class="$style.name">
            {{ name }}
        </div>
        <div :class="$style.label">
            {{label}}
        </div>
        <div :class="$style.text">
            {{text}}
        </div>
        <div :class="$style.list">
            <div v-for="item in items" :key="item" :class="$style.listItem">
                <div :class="$style.circle">
                </div>
                <div :class="$style.listItemText">{{ item }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String
        },
        label: {
            type: String
        },
        color: {
            type: Number
        },
        items: {
            type: Array,
            default: () => []
        },
        text: {
            type: String
        }
    },
    computed: {
        getColor() {
            return [
                'linear-gradient(152.76deg, #E35200 0%, #FFEB80 100%)',
                'linear-gradient(152.76deg, #009320 0%, #00F9CC 100%)',
                'linear-gradient(152.76deg, #0065E3 0%, #5EE2FF 100%)'
            ][this.color]
        },
        isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>

.main {
    position: relative;
    width: 307px;
    height: 446px;

    border-radius: 24px;

    padding: 24px;

    &.mobile {
        margin-top: 36px;
    }
}
.name {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 24px;
    top: -17px;
    padding: 10px;

    background: #F0163D;
    border-radius: 12px;

    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #FFFFFF;
}
.label {
    font-size: 28px;
    line-height: 84px;
    letter-spacing: 0.278974px;
    font-weight: bold;
    color: #FFFFFF;
}

.text {
    font-family: NoirPro-Light;
    margin-top: 12px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #FFFFFF;
}

.listItem {
    margin-top: 12px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.0976408px;
    color: #FFFFFF;
}
.listItemText {
 display: flex;
}

.circle {
    width: 16px;
    height: 16px;

    border: 4px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;

    margin-right: 12px; 
    float: left;
    margin-top: 4px;
}
</style>