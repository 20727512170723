<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.left">
                <div :class="$style.title">
                    Контакты
                </div>
                <div :class="$style.name">
                    Доктор Денис Иванов
                </div>
                <div :class="$style.email">
                    support@mentalmarket.ru
                </div>
                <div :class="$style.social">
                    <div :class="$style.spacer"></div>
                    <a href="https://zen.yandex.ru/id/6127bfcb4deaaa546fa332d3" target="_blank"><img src="~@/assets/img/zen.svg" alt="Zen"></a>
                    <!-- <div :class="$style.spacer"></div>
                    <a href=""><img src="~@/assets/img/yt.svg" alt="YouTube"></a> -->
                </div>
            </div>
            <div :class="$style.right">
                <div :class="$style.info">
                    Материал предназначен для широкой аудитории. Информация, представленная в данных лекциях, не является  консультацией врача и не заменяет её.
                </div>
                <a href="https://mentalmarket.ru/policy.pdf" :class="$style.policy">
                    Политика конфидециальности
                </a>
                <a href="https://mentalmarket.ru/oferta.pdf" :class="$style.privacy">
                    Договор оферта
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
}
</script>
<style lang="scss" module>
    .main {
        background: #EEF6FE;
        width: 100%;
    }

    .content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;
        
        .mobile & {
            flex-direction: column;
            justify-content: flex-start;
            width: calc(100% - 32px);
            padding: 40px 16px;
        }
    }
    .title {
        font-family: NoirPro-Medium;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.0976408px;
        font-weight: 500;

        color: #333333;
    }
    .left {
        display: flex;
        flex-direction: column;
        width: 50%;
        .mobile & {
            width: 100%;
            align-items: flex-start;
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 50%;
        
        .mobile & {
            width: 100%;
            margin-top: 32px;
            align-items: flex-start;
        }
    }
    .name {
        margin-top: 16px;
        font-size: 18px;
        line-height: 24px;

        letter-spacing: 0.0976408px;

        color: #333333;
    }
    .email {
        margin-top: 16px;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        letter-spacing: 0.0976408px;

        color: #1677F0;
    }
    .social {
        display: flex;
        margin-top: 16px;
    }

    .spacer {
        width: 20px;
    }

    .info {
        font-size: 16px;
        line-height: 20px;

        letter-spacing: 0.0976408px;

        color: #333333;

    }
    .policy {
        margin-top: 16px;
        font-size: 18px;
        line-height: 24px;

        text-align: right;
        letter-spacing: 0.0976408px;

        color: #1677F0;
    }
    .privacy {
        margin-top: 16px;
        font-size: 18px;
        line-height: 24px;

        text-align: right;
        letter-spacing: 0.0976408px;

        color: #1677F0;
    }
</style>