<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.title">
            ДЛЯ КОГО ЭТОТ ВЕБИНАР
        </div>
        <div :class="$style.content">
            <Tile text="Для тех, кто много работает" :img="0"/>
            <Tile text="У кого 1000 и 1 забота в голове" :img="1"/>
            <Tile text="Для тех, кто мало отдыхает и плохо спит" :img="2"/>
        </div>
    </div>
</template>
<script>
import Tile from './Tile.vue'
export default {
    components: { Tile },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>

    .main {
        background: linear-gradient(0deg, #9FD7DE 0%, rgba(232, 232, 232, 0) 76.04%);
        width: calc( 100% - (100% - 1099px) );
        padding-left: calc((100% - 1099px) / 2);
        padding-right: calc((100% - 1099px) / 2);
        margin: auto;

        &.mobile {
            width: calc(100% - 32px);
            padding: 0 16px;
        }
    }
    .title {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
        font-family: NoirPro-Bold;
        font-weight: 500;
        font-size: 36px;
        line-height: 64px;
        color: #41757C;
        margin-top: 91px;

        .mobile & {
            font-size: 24px;
            line-height: 28px;
            margin-top: 13px;
        }

    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .mobile & {
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
        }
    }


</style>