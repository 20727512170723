<template>
<div class="download-app-wrapper">
  <div class="download-app">
    <h2 class="download-app__heading">Доступно на мобильных устройствах</h2>
    <p class="download-app__caption">Всегда при себе в мобильном приложении</p>
    <div class="download-app__d-store d-store">
      <a class="d-store__link d-store__link--appStore" href="https://apps.apple.com/ru/app/mental-market-курсы-вебинары/id1501300053"><img src="@/assets/img/AppStore.svg" alt="AppStore"></a>
      <a class="d-store__link d-store__link--googlePlay" href="https://play.google.com/store/apps/details?id=org.montirey.android&hl=ru&gl=US"><img src="@/assets/img/GooglePlay.svg" alt="Google Play"></a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "DownloadApp"
}
</script>
