var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.main, _vm.isMobile ? _vm.$style.mobile : '']},[_c('div',{class:_vm.$style.image},[_c('img',{attrs:{"src":[
            require('./assets/1.png'),
            require('./assets/2.png'),
            require('./assets/3.png'),
            require('./assets/4.png'),
            require('./assets/5.png'),
            require('./assets/6.png')
        ][_vm.index]}})]),_c('div',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.text))])])}
var staticRenderFns = []

export { render, staticRenderFns }