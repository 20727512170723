<template>
<div :class="$style['order-item']">
  <img :class="$style['order-item__image']" :src="cartItem.image"  height="120">
  <div :class="[$style['order-item__oi-content'], $style['oi-content']]">
    <h2 :class="$style['oi-content__heading']">{{cartItem.name}}</h2>
    <div :class="$style['oi-content__info']">
      <p :class="$style['oi-content__amount']">{{cartItem.count}} шт</p>
      <p :class="[$style['oi-content__price'], (promocode && promocode.price) ? $style['oi-content__price--decreased'] : '']">
        {{(cartItem.count * cartItem.price) | price}}
        <!-- {{(promocode && promocode.price) ? cartItem.price - promocode.price : cartItem.price }} ₽ -->
      </p>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex"
export default {
  name: "OrderItem",
  props: {
    cartItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      actionAddItemToCart: "localData/addItemToCart",
      actionRemoveItemFromCart: "localData/removeItemFromCart"
    }),

  },
  computed: {
    ...mapGetters({
      cart: "localData/cart",
      items: "serverData/items",
      promocode: "serverData/promocode",
    }),
    item: function() {
      return this.items.find(el => el.id === this.cartItem.id)
    },
  }
}
</script>


<style lang="scss" module>

  @import "@/styles/_variables";

.order-item {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 24px;
}

.order-item__image {
  margin: 0 16px 0 0;
}

.oi-content {
  display: flex;
  flex-flow: row wrap;
  width: 220px;
}

.oi-content__info {
  display: flex;
  flex-flow: column;
  width: max-content;
}

.oi-content__heading {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0592819px;
  font-weight: normal;
  color: #333333;

}

.oi-content {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: avaliable;
}

.oi-content__amount {
  box-sizing: content-box;
  min-width: 40px;
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $dark-grey;
}

.oi-content__price {
  position: relative;
  display: flex;
  margin: 0 0 0 auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.oi-content__price--decreased {
  color: $green;
}

</style>