import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import cart from './cart'
import serverData from './serverData.js'
import localData from './localData.js'
import categories from './categories'
import news from './news'
import products from './products'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    cart,
    serverData,
    localData,
    categories,
    news,
    products
  }
})


store.subscribe((mutation, state) => {
  if (mutation.type.includes('cart')) {
    localStorage.setItem('cart', JSON.stringify(state.cart))
  }
});
export default store; 
