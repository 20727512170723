<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">

        <div :class="$style.picture">
            <img src="./assets/person4.png" />
        </div>
        <div :class="$style.content">
           <div :class="$style.title">После эфира:</div>
           <div :class="$style.list">
                <div :class="$style.listItemWrapper" v-for="item in listItems" :key="item.id">
                   <img v-if="!isMobile" src="./assets/check.png" />
                    <div :class="$style.listItem">
                        <div :class="$style.number">
                            <span>{{item.id}}</span>
                        </div>
                        <div :class="$style.text"> {{item.title}} </div>
                    </div>
               </div>
           </div>
           <a href="#registration" :class="$style.buttonWrapper">
                <button :class="$style.button">
                    Хочу вернуть секс!
                </button>
           </a>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listItems: [
                {
                    id: '1',
                    title: 'Вы узнаете психологию секса и научитесь её использовать, чтобы наладить отношения '
                },
                {
                    id: '2',
                    title: 'Вернёте собственную сексуальность, которую ощущали в прежней свободной жизни'
                },
                {
                    id: '3',
                    title: 'Прокачаете сексуальный интеллект: способность понимать, распознавать и контролировать свои сексуальные желания и желания партнёра'
                },
                {
                    id: '4',
                    title: 'Улучшите самочувствие, настроение и гормональный фон, которые напрямую зависят от качества секса'
                },
                {
                    id: '5',
                    title: 'Секс с постоянным партнёром станет регулярным, разнообразным и ярким. Таким, как вы захотите!'
                }
            ]
        }
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>
    .main {
        display: flex;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        z-index: 1;
    }

    .title {
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 84px;
        display: flex;
        align-items: center;
        letter-spacing: 0.278974px;

        color: #FFFFFF;

        .mobile & {
            font-size: 20px;
            line-height: 84px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
    }

    .listItem {
        display: flex;
        width: 612px;
        padding: 26px;
        margin-top: 21px;
        

        border: 1px solid #ACC7E2;
        border-radius: 24px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        /* or 140% */

        letter-spacing: 0.0976408px;

        color: #FFFFFF;

        .mobile & {
            font-size: 12px;
            line-height: 16px;
            width: calc(100% - 48px);
            padding: 0 24px;
            border: none
        }

    }

    .number {
        display: inline;
        margin-right: 24px;
        width: 40px;
        font-family: 'Codec Pro';
        font-style: normal;
        font-weight: 850;
        font-size: 68px;
        line-height: 84px;
        /* or 124% */

        text-align: center;
        letter-spacing: 0.278974px;

        background: linear-gradient(91.12deg, #D75B3F 21.52%, #DBDBDB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        .mobile & {
            font-size: 40px;
            line-height: 40px;
        }

    }

    .text {
         width: 543px;

         .mobile & {
             width: auto;
         }
    }

    .listItemWrapper {
        display: flex;
        align-items: center;
        img {
            margin-right: 26px;
            width: 76px;
            height: 87px;
        }
    }

     .button {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 20px;
        letter-spacing: 0.0557948px;
        text-transform: capitalize;

        margin-left: 60px;

        color: #FFFFFF;

        background: #D75B3F;
        border: 1px solid #979797;
        border-radius: 12px;

        align-self: center;

        width: 546px;
        height: 87px;

        margin-top: 38px;

        .mobile & {
            
            width: 216px;
            height: 43px;

            font-size: 12px;
            line-height: 20px;

            margin-left: 0px;
            margin-top: 0px;
        }
    }

    .buttonWrapper {

        width: 546px;
        height: 87px;

        margin-top: 38px;
        padding-bottom: 40px;

        .mobile & {
            
            width: 216px;
            height: 43px;

            font-size: 12px;
            line-height: 20px;
        }
    }

    .picture {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 51px;
    }

</style>