<template>

  <MainLayout>
  <div :class="[ $style.courses, isMobile ? $style.mobile : '']">
    <Breadcrumbs v-if="category" :links="[category, courses]"></Breadcrumbs>
    <h1 v-if="category" :class="$style.heading">Все курсы категории "{{category.name}}"</h1>
    <div v-if="courses.length" class="category-page__courses">
      <CourseCard class="category-page__item" v-for="(item, key) in courses" :key="key" :category="category" :course="item"></CourseCard>
    </div>
</div>
  </MainLayout>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import CourseCard from '@/components/CourseCard.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import {
  mapState,
  mapGetters
} from 'vuex'
import { vfirestore } from '@/db'
export default {
  name: "Courses",
  components: {
    CourseCard,
    Breadcrumbs,
    MainLayout
  },
  props: {
    itemId: {
      type: String,
      require
    }
  },
  data() {
    return {
      courses: [],
    }
  },
  computed: {
		...mapGetters({
			categoryList: 'categories/list'
		}),
    ...mapState({
      router: function() {
        return this.$router;
      },
      route: function() {
        return this.$route;
      }
    }),
    category() {
      return this.categoryList.find( i => i.id === this.itemId);
    },
		isMobile() {
			// return true;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
		}
  },
  async mounted() {
    let courseRes = await vfirestore.collection('v').doc('1').collection('courses').where('category', '==', this.itemId).where('hide', '==', false).get()
    this.courses = courseRes.docs.length ? courseRes.docs.map( item => ({ ...item.data(), id: item.id }) ) : []
  }
}

</script>
<style lang="scss" module>
.courses {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;

  &.mobile  {
    width: auto;
    padding: 0 16px;
  }
}

.heading {
  display: flex;
  margin: 10px 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;

  .mobile & {
    font-size: 28px;
    line-height: 33px;
  }
}

.list {
  display: flex;
  flex-flow: row wrap;
}

.item {
  display: flex;
  overflow: hidden;
  margin: 0 20px 10px 0;

  &:last-child {
    margin-bottom: 70px;
  }
}

</style>