<template>
<div :class="$style.main">

    <h2 :class="$style.heading">Новости</h2>
    <router-link class="news-slider__all" to="/news">Смотреть все</router-link>
    <Slider>
          <NewsCard name="newsSlide" v-for="(item, key) in list" :key="key" class="ns-slides__item" :news="item" />
    </Slider>
</div>
</template>

<script>
import NewsCard from '@/components/NewsCard.vue'
import Slider from '@/components/Slider'
import { vfirestore } from '@/db.js'
import { mapGetters } from 'vuex'
export default {
  name: "NewsSlider",
  components: {
    NewsCard,
    Slider
  },
  computed: {
    ...mapGetters({
      list: 'news/list'
    })
  },
}
</script>

<style lang="scss" module>

@import "@/styles/_variables";

.main {	
	position: relative;
	display: flex;
	flex-flow: row wrap;
	width: 100%;
}
.heading {
   display: flex;
  align-items: center;
  justify-content: center;
  height: min-content;
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    display: flex;
    width: 303px;
    height: 38px;
    z-index: -1;
  }
}
</style>