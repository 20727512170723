<template>
  <MainLayout>

<div :class="[ $style.webinars, isMobile ? $style.mobile : '']">
  <template v-if="isLoading">
    <div :class="$style.loading">
      <Loading />
    </div>
  </template>
  <template v-else>
    <Breadcrumbs v-if="category && list" :links="[category, list]"></Breadcrumbs>
    <h1 v-if="category" :class="$style['webinars__heading']">Все вебинары категории "{{category.name}}"</h1>
    <div :class="[$style['webinars__webinar-list'], $style['webinar-list']]">
      <WebinarCard :class="$style['webinar-list__item']" v-for="item in list" :key="item.id" :webinar="item" :category="category" />
    </div>
  </template>
</div>
  </MainLayout>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import WebinarCard from '@/components/WebinarCard.vue'
import { vfirestore } from '@/db.js'
import Loading from '@/components/Loading.vue'
import { mapGetters } from 'vuex'
import MainLayout from '@/components/layouts/MainLayout.vue'

export default {
  name: "Webinars",
  components: {
    WebinarCard,
    Breadcrumbs,
    Loading,
    MainLayout
  },
  props: {
    categoryId: {
      type: String,
      default: ''
    },
    itemId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  computed: {
		...mapGetters({
			categoryList: 'categories/list'
		}),
    category() {
      return this.categoryList.find( i => i.id === this.itemId);
    },
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    }
  },
  methods: {
    async initWebinars() {
      this.isLoading = true;
      let webinarsRes = await vfirestore.collection('v').doc('1').collection('webinars').where('collection', '==', this.itemId).where('hide', '==', false).get()
      this.list = webinarsRes.docs.length ? webinarsRes.docs.map( item => ({...item.data(), id: item.id}) ) : []
      
      this.list.sort((a,b) => {
				 if ( a.number < b.number ){
					return -1;
				}
				if ( a.number > b.number ){
					return 1;
				}
				return 0;
			})
      
      this.isLoading = false;

    }
  },
	async mounted() {
    this.initWebinars()
	},
}
</script>
<style lang="scss" module>
@import "@/styles/_variables";

.loading {
  display: flex;
  justify-content: center;
  width: 100%;
}

.webinars {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;

  &.mobile {
    width: auto;
    padding: 0 16px;
  }
}

.webinars__heading {
  display: flex;
  margin: 10px 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;

  .mobile & {
    font-size: 28px;
    line-height: 33px;
  }
}

.webinar-list {
  display: flex;
  flex-flow: row wrap;
}

.webinar-list__item {
  display: flex;
  margin: 0 20px 10px 0;

  &:last-child {
    margin-bottom: 70px;
  }

  .mobile & {
    margin: 0 8px 8px 0;

    &:nth-child(2n) {
      margin: 0 0px 8px 0;
    }
  }
}

</style>