import { vfirestore, vFunctions } from '@/db'
import { collection, addDoc } from "firebase/firestore"; 

export async function pay1(price, uid, productId, email, productType, ref, name, category, productName) {
    
    return new Promise(async (resolve, reject) => {

        var widget = new cp.CloudPayments();
        widget.pay('charge', // или 'charge'
            {
                publicId: 'pk_e11c989a4bd29065a664375527a62', //id из личного кабинета
                description: 'Оплата товаров на MentalMarket.ru ', //назначение
                amount: parseInt(price), //сумма
                currency: 'RUB', //валюта
                accountId: email, //идентификатор плательщика (необязательно)
                skin: "mini", //дизайн виджета (необязательно)
                data: {
                    "name": name,
                    "amount": price,
                    "email": email,
                    "status": 0,
                    "type": productType,
                    "productId": productId,
                    "category" : category,
                    "productName": productName,
                    "uid": uid,
                    "price": price,
                    "ref": ref ? ref : '',
                }
            },
            {
                onSuccess: async (options) => {
                    resolve();
                },
                onFail: function (reason, options) { // fail
                    reject();
                },
                onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    //например вызов вашей аналитики Facebook Pixel
                }
            }
        )
    })
}


export async function pay(price, uid, productId, email, productType, ref, name, category, productName) {
    
    return new Promise(async (resolve, reject) => {

        var widget = new pw.PayWidget();
        
        let res = await vFunctions.httpsCallable('onCreateOrder')({price, uid, productId, email, type: productType, ref, name, category, productName});
        
        widget.pay(
        {
            serviceId: "20387",
            key: "043063aecb31da418b5ffe1620e69710b45aefeb2e10c62e358b9a639f957c8e2cbc82b972976fe5811346e0d2e15bfcea11834b05df5b507cc73f07714bc72945"
        },
            // Запрос с минимальным количеством параметров
            {
            MetaData: {
                PaymentType: "Pay",
            },
            PaymentRequest: {
                OrderId: res.data.orderId.toString(),
                Amount: price.toString(),
                Currency: "RUB",
                Description: "Оплата вебинара на mentalmarket",
            }, 
            },
            {
                onSuccess: function(res) {
                    resolve();
                },
                onError: function(res) {
                    reject();
                },
                onClose: function(res) {
                    reject();
                },
            },
        );

    })
}