<template>
  <MainLayout>

	<div :class="isMobile ? $style.m_categories : $style.categories">
		<template v-if="isLoading">
			<div :class="$style.loading">
				<Loading />
			</div>
		</template>
		<template v-else>
			<Breadcrumbs :links="[list]"></Breadcrumbs>
			<h1 :class="$style.heading">Все категории</h1>
			<div :class="isMobile ?  $style.m_list : $style.list">
				<CategoryCard :class="[$style.item, isMobile ? $style.mobile : '' ]" v-for="(item, key) in list" :key="key" :category="item" />
			</div>
		</template>
	</div>
  </MainLayout>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import CategoryCard from '@/components/CategoryCard.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import { api } from '@/api/index.js'
import Loading from '@/components/Loading.vue'
import { mapGetters } from 'vuex'

export default {
	name: "Categories",
	components: {
		CategoryCard,
		Breadcrumbs,
		Loading,
		MainLayout
	},
	data: () => ({
		isLoading: false
	}),
	computed: {
		...mapGetters({
			list: 'categories/list'
		}),
		isMobile() {
			// return true;
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
		}
	},
}
</script>
<style lang="scss" module>
.categories {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;
}

.m_categories {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 0 16px;
}

.heading {
  display: flex;
  margin: 10px 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
}

.list {
  display: flex;
  flex-flow: row wrap;
}

.m_list {
  display: flex;
  flex-flow: row wrap;
}

.item {
  display: flex;
  margin: 0 20px 20px 0;

  &:last-child {
    margin-bottom: 110px;
  }
  &.mobile {
  	margin: 0 8px 8px 0;
	
	&:nth-child(2n) {
      margin: 0 0px 8px 0;
    }
  }
}
.loading {
	display: flex;
	justify-content: center;
	width: 100%;
}
</style>