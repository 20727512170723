<template>
<div class="cart" @ShowCart="actionToggleCartVisibility(true)" v-show="cartVisiblePage === 'cart'">
  <div class="pd-menu__heading">
    <a v-if="isMobile" style="width: 24px"></a>
    <span>Корзина</span>
    <a v-if="isMobile" class="personal-data__close" href="" @click.prevent="actionToggleCartVisibility(false);"></a>
  </div>
  <div class="cart__c-content c-content" v-show="cart.length">
    <CartItem class="c-content__cart-item" v-for="(cartItem, key) in cart" :itemId="cartItem.id" :key="key" />
    <form class="c-content__c-promocode c-promocode">
      <p class="c-promocode__caption">Промокод</p>
      <input class="c-promocode__input" type="text" v-model="promocodeValue">
      <button class="c-promocode__button" type="submit" @click.prevent="actionUsePromocode(promocodeValue); promocodeUsed=true;">Применить</button>

      <transition name="fade">
        <p class="c-promocode__status c-promocode__status--failure" v-show="promocodeUsed && !promocodeSuccess">Данный промокод не существует</p>
      </transition>

      <transition name="fade">
        <p class="c-promocode__status c-promocode__status--success" v-show="promocodeSuccess">Промокод активирован!</p>
      </transition>

    </form>
    <p class="c-content__summary-price">Итого: <span class="c-content__summary-price--number">{{itemsReducedPrice | price}}</span></p>
    <button class="c-content__button" type="submit" @click.prevent="actionToCheckout">Оформить заказ</button>
  </div>
  <div class="cart__c-empty c-empty" v-show="!cart.length">
    <h2 class="c-empty__heading">Корзина пуста</h2>
    <p class="c-empty__text">Добавляйте покупки в корзину и они появятся здесь</p>
    <router-link class="c-empty__button" to="/items" v-on:click="actionToggleCartVisibility(false)">Перейти к покупкам</router-link>
  </div>
</div>
</template>

<script>
import CartItem from "@/components/ModalComponent/CartItem.vue"
import {
  mapActions,
  mapGetters
} from 'vuex'
export default {
  name: 'CartList',
  components: {
    CartItem
  },
  data() {
    return {
      isCanceled: false,
      promocodeValue: '',
      promocodeUsed: false,
      promocodeSuccess: false
    }
  },
  methods: {
    ...mapActions({
      actionAddItemToCart: 'localData/addItemToCart',
      actionToggleCartVisibility: 'localData/toggleCartVisibility',
      actionToggleCartVisiblePage: 'localData/toggleCartVisiblePage',
      actionUsePromocode: 'serverData/usePromocode',
      actionTogglePersonalDataVisibility: "localData/togglePersonalDataVisibility",
    }),
    actionToCheckout() {
      if (!this.isAuth) {
        this.actionTogglePersonalDataVisibility(true);
      } else {
        this.actionToggleCartVisiblePage('delivery');
        this.isCanceled = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      cart: 'cart/list',
      cartVisibility: 'localData/cartVisibility',
      cartVisiblePage: 'localData/cartVisiblePage',
      promocode: 'serverData/promocode',
      items: 'serverData/items',
      isAuth: 'user/isAuth',
      personalDataVisiblePage: 'localData/personalDataVisiblePage',
      personalDataPreviousPage: 'localData/personalDataPreviousPage',
    }),
    promocodePrice: function() {
      return this.promocode && this.promocode.price ? this.promocode.price : 0
    },
    deliveryPrice: function() {
      return this.deliveryForm.delivery ? this.delivery.find(el => el.id === this.deliveryForm.delivery).price : 0
    },
    itemsPrice: function() {
      let ip = 0
      this.cart.forEach(el => (ip += el.amount * this.items.find(item => item.id === el.id).price))
      return ip
    },
    itemsReducedPrice() {
      return this.cart.reduce((prev, current) => (prev + (current.count * current.price)), 0)
    },
    summaryPrice: function() {
      return this.itemsReducedPrice + this.deliveryPrice
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  },
  watch: {
    promocode: function(value) {
      setTimeout(() => {
        if (!value || !value.price) this.promocodeUsed = false
      }, 3000)
      if (value && value.price) return (this.promocodeSuccess = true)
      return (this.promocodeSuccess = false)
    },
    personalDataVisiblePage: function(value) {
      // if (value === 'menu') {
      //   this.actionTogglePersonalDataVisibility(false);
      //   this.actionToggleCartVisiblePage('delivery');
      //   this.isCanceled = false;
      // }
    }
  }
}
</script>
