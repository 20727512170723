<template>
    <div :class="[$style.main, isMobile ? $style.mobile : '']">
        <div :class="$style.content">
            <div :class="$style.title">
                Как будет проходить вебинар
            </div>
            <div :class="$style.reasons">
                <ul>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Дата:</span> 16 февраля 2022 года
                    </li>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Время:</span> 18:30
                    </li>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Где:</span> На специальной онлайн-платформе, вам не нужно скачитвать какие-то приложения
                    </li>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Как смотреть:</span> Смотреть можно с любого устройства, главное чтобы был доступ к стабильному интернету
                    </li>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Можно ли задать вопрос на вебинаре:</span> В конце вебинара Денис Викторович ответит на ваши вопросы в прямом эфире
                    </li>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Будет ли запись вебинара:</span> Запись вебинара придет через несколько дней после трансляции останется у вас НАВСЕГДА
                    </li>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Как я получу доступ:</span> После оплаты вебинара вам на почту придет письмо с инструкцией, а за день до вебинара письмо со ссылкой на вебинарную комнату
                    </li>
                    <li :class="$style.listItem">
                        <span :class="$style.listItemLabel">Что сделать, чтобы получить доступ:</span> Зарегистрироваться на вебинар 
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import ReasonTile from './ReasonTile'
export default {
    components: {
        ReasonTile
    },
    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}
    }
    
}
</script>
<style lang="scss" module>
    .main {
        background: linear-gradient(0deg, #9FD7DE 0%, rgba(232, 232, 232, 0) 76.04%);
        width: calc( 100% - (100% - 1099px) );
        padding-left: calc((100% - 1099px) / 2);
        padding-right: calc((100% - 1099px) / 2);
        &.mobile {
            width: auto;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1099px;
        padding: 80px 0;
        margin: auto;

        .mobile & {
            width: calc(100% - 32px);
            padding: 40px 16px;
        }
    }
    .listItem {
        margin-top: 40px;
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -0.01em;
        color: #41757C;

         .mobile & {
            margin-top: 14px;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .listItemLabel {
        font-family: Montserrat-Bold;
        font-weight: 800;
        margin-right: 8px;
    }

    .reasons {
        display: flex;
        flex-direction: column;
        .mobile & {
            width: 100%;
        }
    }

    .title {
            font-family: Montserrat-Bold;
            font-size: 50px;
            line-height: 64px;
            text-align: center;
            color: #E76F51;
        .mobile & {
            font-size: 14px;
            line-height: 12px;
        }
    }
</style>