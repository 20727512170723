<template>
    <div>
        <h3 :class="$style['pdbl-item__heading']">{{"Заказ № "+item.orderNumber}}</h3>
        <h3 :class="$style['pdbl-item__heading']" v-show="item.type === 'webinar'">{{"Покупка вебинара \""+item.label+"\""}}</h3>
        <h3 :class="$style['pdbl-item__heading']" v-show="item.type === 'course'">{{"Подписка на курс  \""+item.label+"\""}}</h3>
        <p :class="$style['pdbl-item__date']">{{ date }}</p>
    </div>
</template> 
<script>
import moment from 'moment';
export default {
    props: {
        item: {
            type: Object,
            default: () =>({})
        }
    },
    computed: {
        date() {
            return moment.utc(this.item.date.seconds).local().format("DD.MM.YYYY HH:mm");
        }
    }
    
}
</script>
<style lang="scss" module>

@import "@/styles/_variables";

.pdbl-item__heading {
  margin: 0 0 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.pdbl-item__date {
  margin: 0;
  color: $dark-grey;
  font-size: 12px;
  line-height: 14px;
}



</style>