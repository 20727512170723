import { vfirestore } from '@/db'

export default {
    namespaced: true,
    state: {
        list: []
    },
    getters: {
        list: (state) => state.list
    },
    mutations: {
        setNews(state, news) {
            state.list = news;
        }
    },
    actions: {
        async fetchNews({ commit }) {
            let res = await vfirestore.collection('v').doc('1').collection('news').get();
            if (res.docs.length) {
                let docs = res.docs.map( item => ({ ...item.data(), id: item.id }))
                docs.sort((a, b) => b.number - a.number)
                commit('setNews', docs);
            } else {
                commit('setNews', []);
            }

        }
    },
}