<template>
    <div :class="[$style.main, isMobile ? $style.mobile: '']">
        <Header />
        <div :class="$style.content">
            <div style="height: 100px"></div>
            <MainSection />
            {{success}}
            <div v-if="!isMobile" style="height: 149px"></div>
            <SecondSection />
            <div v-if="!isMobile" style="height: 193px"></div>
            <ThirdSection />
            <div v-if="!isMobile" style="height: 120px"></div>
            <FourthSection />
            <div v-if="!isMobile" style="height: 128px"></div>
            <FifthSection />
            <div v-if="!isMobile" style="height: 189px"></div>
            <div v-if="isMobile" style="height: 45px"></div>
            <SixSection />
            <div v-if="!isMobile" style="height: 75px"></div>
            <div v-if="isMobile" style="height: 115px"></div>
            <SevenSection />
            <div v-if="!isMobile" style="height: 139px"></div>
            <Registration :price="2490" />
            <div v-if="!isMobile" style="height: 139px"></div>
            <div v-if="isMobile" style="height: 69px"></div>
            <Footer />
            <Success v-if="isSuccesOpen" @close="isSuccesOpen = false"/>
            {{success}}
        </div>
    </div>
</template>
<script>


import Header from './Header.vue'
import MainSection from './MainSection.vue'
import SecondSection from './SecondSection.vue'
import ThirdSection from './ThirdSection.vue'
import FourthSection from './FourthSection.vue'
import FifthSection from './FifthSection.vue'
import SixSection from './SixSection.vue'
import SevenSection from './SevenSection.vue'
import Registration from './Registration.vue'
import Footer from './Footer.vue'
import Success from './Success.vue'

export default {
    props: {
        price: {
            type:Number
        }
    },
    data() {
        return {
            isSuccesOpen: false
        }
    },
    components: { Header, MainSection, SecondSection, ThirdSection, FourthSection, FifthSection, SixSection, SevenSection, Registration, Footer, Success },

    computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},
        success() {
            return this.$route.success ? this.$route.query.success : '';
        }
    },
    mounted() {
        this.isSuccesOpen = this.$route.query.success
    },
    
    
}
</script>

<style lang="scss" module>

.main {
    background: #000;
    background-image: url('./assets/blicks.png');
    background-repeat: no-repeat;
    background-position-x: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100wh;
}

.content {
    width: 1100px;
    & > div {
        width: 100%;
    }

    .mobile & {
        width: calc(100% - 64px);
        padding: 0 32px;
    }
}

</style>