<template>
  <MainLayout>

<div :class="[ $style.news, isMobile ? $style.mobile : '']">
  <Breadcrumbs :links="[list]"></Breadcrumbs>
  <h1 :class="$style.news__heading">Новости</h1>
  <div :class="[$style['news__news-list'], $style['news-list']]">
    <NewsCard :class="$style['news-list__item']" v-for="(item, key) in list" :key="key" :news="item"  />
  </div>
</div>
  </MainLayout>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import NewsCard from '@/components/NewsCard.vue'
import MainLayout from '@/components/layouts/MainLayout.vue'
import { vfirestore } from '@/db.js'
import { mapGetters } from 'vuex'
export default {
  name: "Webinars",
  components: {
    NewsCard, Breadcrumbs, MainLayout
  },
  computed: {
    ...mapGetters({
      list: 'news/list'
    }),
    isMobile() {
      // return true;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
    }
  },
}
</script>
<style lang="scss" module>

.news {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 170px;

  &.mobile {
    width: auto;
    padding: 0 16px;
  }
}

.news__heading {
  display: flex;
  margin: 10px 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
}

.news-list {
  display: flex;
  flex-flow: row wrap;
}

.news-list__item {
  display: flex;
  margin: 0 20px 10px 0;

  &:last-child {
    margin-bottom: 70px;
  }

  .mobile & {
    width: 100%;
  }
}


</style>