<template>
<router-link :class="$style.card" :to="'/categories/'+category.id" :style="backgroundStyle">
  <h3 :class="$style.heading">{{category.name}}</h3>
</router-link>
</template>

<script>
export default {
  name: "CategoryCard",
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundStyle: function() {
      return {
        background: (this.category && this.category.thumb) ? `url(${this.category.thumb})  no-repeat` : "#999"
      };
    }
  },
}
</script>
<style lang="scss" module>
.card {
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  width: 168px;
  height: 154px;
  margin: 0;
  padding: 16px;
  border-radius: 16px;
  background: #999;
}

.heading {
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  word-break: break-all;
}

</style>